.loaderConatiner {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.18);
  z-index: 99;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  /* height: 100vh; */
  position: fixed;
  display: grid;
  text-align: center;
}
.loadertext{
  margin-left: 5px;
}
.spinnerLarge {
  width: 36px;
  height: 36px;
}
.spinnerMedium {
  width: 24px;
  height: 24px;
 

}
.spinnerSmall {
  width: 20px;
  height: 20px;
}

.spinner svg {
  width: 100%;
  height: 100%;
  overflow: visible;
  animation: rotation 1455ms infinite linear;
}

.spinner circle {
  stroke: currentColor;
  stroke-dasharray: 44px;
  stroke-dashoffset: 11px;
  transform-origin: center;
  transform: rotatey(180deg) rotate(90deg);
  animation: spinner 3850ms infinite ease;
  
}

@keyframes spinner {
  0% {
    stroke-dashoffset: 33px;
    transform: rotatey(0deg) rotate(0deg);
  }
  24.9999% {
    stroke-dashoffset: 11px;
    transform: rotatey(0deg) rotate(0deg);
  }
  25% {
    stroke-dashoffset: 11px;
    transform: rotatey(180deg) rotate(270deg);
  }
  49.9999% {
    stroke-dashoffset: 33px;
    transform: rotatey(180deg) rotate(270deg);
  }
  50% {
    stroke-dashoffset: 33px;
    transform: rotatey(0deg) rotate(180deg);
  }
  74.9999% {
    stroke-dashoffset: 11px;
    transform: rotatey(0deg) rotate(180deg);
  }
  75% {
    stroke-dashoffset: 11px;
    transform: rotatey(180deg) rotate(90deg);
  }
  100% {
    stroke-dashoffset: 33px;
    transform: rotatey(180deg) rotate(90deg);
  }
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}

.content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #581845;
}
.contentOne{
  height: fit-content;
  color: #581845;
  text-align: center;
}
.contentOne1{
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.18);
  z-index: 99;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 102%;
  height: 100%;
  position: absolute;
  display: grid;
  text-align: center;
}
