.dataAnaLysFlex {
  padding: 16px 0px;
}
.chartStyle {
  min-height: 400px;
  margin-right: 8px;
  color:#333333;
  font-size: 16px;
}
.chartStyle:hover{
  box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  transition: all 0.2s ease-out;  
}
.cardappquest:hover{
  box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  transition: all 0.2s ease-out;  

}
.cardOverAll {
  padding: 5px 10px 5px 5px;
  /* margin: 24px 70px; */
  overflow: scroll; 
}
.cardappquest {
  padding: 5px 10px 5px 5px;
  /* margin: 24px 70px; */
  overflow: scroll; 
  padding: 0 16px 10px 0;
  margin: 10px 0 10px 2px;
}
.cardOverAll1 {
  padding: 5px 10px 5px 12px;
  /* margin: 24px 70px; */
 max-width: 1300px;
 margin: 10px 60px 50px 60px;
  overflow: scroll;
  background-color: var(--color-white, #ffffff);
    border-radius: 4px;
    box-sizing: border-box;
    /* -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36); */
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
    -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);

}
.postion{
  position: relative;
    top: 9px;
}
.jdStatus {
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 14px;
  color: #581845;
  border-bottom: 1px solid #581845;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 10px;
  margin-right: 10px;
}
.modalOverAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 20px;
}
.cardOne {
  padding: 16px;
  padding-top: 0px;
  margin-bottom: 16px;
}
.jobMetricsStyle {
  padding: 10px 0;
}
.cardTwo {
  padding: 16px;
  padding-top: 0px;
  margin-bottom: 16px;
}
.skillFlex {
  margin-top: 20px;
}
.sillListStyle {
  margin-right: 8px;
  margin-bottom: 8px;
}
.requiredSkillText {
  margin-bottom: 8px;
}.margin10 {
  margin-right: 10px;
}
.font20{
  font-size: 20px;
   cursor: pointer;
}.curser{
 cursor: pointer;
}.padding2{
 padding: 2px;
}.center{
  margin: auto;
}
.font20px{
  font-size: 20px;
}
.popTitle{
  margin-left: 8px;
}
.titlejobdetails{
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 16px;
  color: #333333;
  border-bottom: 1px solid #581845;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 10px;
  margin-right: 10px;
}
.boards{
  padding-bottom: 5px;
  padding-top: 10px;
  font-size: 16px;
  color: #581845;
  border-bottom: 1px solid #581845;

}
.ribbon{
  background-color: #EEE8EC;
  height: 40px;
}
/* .triangle{
  display:flex;
  justify-self: flex-end;

} */
.triangle {

width: 0px;
  
height: 0px;
  
border-top: 22px solid transparent;
  
border-right: 30px solid white;
  
border-bottom: 22px solid transparent;
  
position: relative;

top: -1px; 
right: 0px; 

  }
  .padding{
    padding:0px ;
    margin-top: 10px;
  }
  .mainpadding{

    padding: 8px;
  }
  .cardOverAll {
    padding: 0 16px 10px 0;
    margin: 20px 0px 0px 2px;
  }
  .tableDiv {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .svgAngle{
    margin-left: 8px;
  }
  .fontweight{
    font-weight: 100 !important;
  }

  .card1 {
    background-color: var(--color-white, #ffffff);
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
    -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
    padding: 16px;
    margin: 16px 0 20px;
}

.questionary{
  display: flex;
  justify-content: center;
  text-align: center;
}