.messagesContainer {
  height: 100%;
  width: 100%;
}
.newMessage {
  display: flex;
  padding: 12px;
  max-width: 90%;
  flex-direction: row;
}
.newMessageContainer {
  display: flex; 
  flex: 0;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
}
.receivedMessageContainer {
  display: flex;
  flex: 0;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 5px;
}
/* .nameContainer {
  display: flex;
  align-items:center;
  margin-bottom: 8px;
  justify-content: flex-end;
} */
.otherContainer {
  display: flex;
  align-items: flex-end;
    margin-bottom: 15px;
}
.userNameText {
  margin: 0 8px;
}
.senderStyleProfile {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative; 
  background-color: var(--color-success, #34cc65);
}
.otherStyleProfile {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  background-color: var(--color-secondary, #ffc203);
}
.senderTextBox {
  border-radius: 5px;
  background-color: #D7C7D2;
  border-top-right-radius: 4px;
  white-space: wrap; 
  overflow-wrap: break-word;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: clip;
}
.otherTextBox { 
  border-radius: 5px;
  white-space: wrap; 
  overflow-wrap: break-word;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: clip;
  background-color:#FEE183;
}
.overall {
  overflow: scroll;
  padding-bottom: 10px;
  margin-right: 2px;
}
.inputDateStyle {
  background-color: #fff;
  color: #979797;
  font-size: 12px;
  border: none;
  font-family: "Roboto", sans-serif;
  width: 130px;
  caret-color: transparent;
}
