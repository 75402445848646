.jobDesFlex {
  padding: 0px 0px 20px 0px;
}
.viewText {
  margin-right: 6px;
  color:white;
  font-size: 16px;
  font-weight: 200;
}
.svgMargin {
  margin-right:  15px;
  cursor: pointer;
}
.ribbon{
  background-color: #EEE8EC;
  height: 45px;
}
.triangle {
width: 0px; 
height: 0px; 
border-top: 22px solid transparent; 
border-right: 30px solid white; 
border-bottom: 22px solid transparent;
position: relative;
top: 71; 
right: 43; 
  }
.viewin{
  color:#581845;
  font-weight: 600;
}