[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 0.8em;
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
  text-align: center;
  /* border-radius:50%; */
  /* background:plum; */
  vertical-align: middle;
  color: white;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  /* background-color: white; */
  /* height: 22px; */
  width: 100%;
  position: relative;
  margin-bottom: 5px;
  padding: 0px 5px;
  /* border-radius: 100px; */
  /* box-shadow: 0px 0px 4px #00000059; */
}
