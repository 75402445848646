.headerStyle {
  height: 52px;
  background-color: var(--color-primary, #581845);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: 16px;
}
.overAll {
  width: 285px;
  position: relative;
  height: 549px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.priceList {
  align-self: center;
}
.decrementStyles {
  border: 1px solid var(--color-gary-5, #cccccc);
  width: 36px;
  box-sizing: border-box;
  height: 30px;
  border-right: none !important;
  justify-content: center;
  display: flex;
}
.incrementStyles {
  border: 1px solid var(--color-gary-5, #cccccc);
  width: 36px;
  box-sizing: border-box;
  height: 30px;
  border-left: none !important;
  justify-content: center;
  display: flex;
}
.inputStyle {
  width: 36px;
  border-radius: 0px !important;
  padding-left: 0;
  padding-right: 0;
}

.disabled {
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.18); */
  z-index: 11;
  height: 99%;
  width: 100%;
  position: absolute;
  border-radius: 4px;
  opacity: 0.75;
  /* -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px); */
  /* background-color: #B3B3B3; */
}
.pointerNone{
  pointer-events: none;
}
.bgcolor{
  /* width: 306px;
  position: relative; */
  /* background-color: #D7C7D24D; */
  /* transform: scale(1.06);
  z-index: 5;
  border: 3px solid #dee2e6;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
  -webkit-box-shadow: 0px 1px 7px 0 rgb(0 0 0 / 47%); */
  width: 285px;
  height: 549px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  transform: scale(1.06);
  z-index: 5;
  /* border: 3px solid #dee2e6; */
  box-shadow: 0 1px 4px 0 #00be4bb0;
  /* box-shadow: 0px 0px 10px 3px #00BE4B; */
  -webkit-box-shadow: 0px 0px 10px 3px #00be4bb0;
  scale: 1;
}
.heading{
  font-size: 18px;
  /* font-family: 'Open Sans'; */
  color: #424242;
}
.subheading{
  font-size: 14px;
  /* font-family: 'Open Sans'; */
}
.subheading1{
  font-size: 14px;
  /* font-family: 'Open Sans'; */
  color: #424242;
}
.dataarray{
  /* font-family: 'Open Sans'; */
  color: #424242;
  font-size: 13px;

}
/* 
.fontfamilybutton{
  font-family: 'Open Sans';
} */
.heightsub{
  height: 505px;
  display: flex;
  max-width: 310px;
  justify-content: space-between;
}
.heightsubmobile{
  height: 505px;
  display: flex;
  width: 310px;
  justify-content: space-between;
}

.priceListwebsite {
  align-self: center;
  margin:20px 20px 0 20px;
   @media (max-width: 1400px) {
    margin:10px 16px 0 16px;
  }
}
.marginbottom{
  margin-bottom: 12px;
  @media (max-width: 1400px) {
  margin-bottom: 6px;}
}
.ribbon {
  background: #e31b1b;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  width: 100px;
  -webkit-clip-path: polygon(0 0, 100% 0, 85% 50%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 85% 50%, 100% 100%, 0 100%);
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px 0 0;
}
.marginborder{
  margin-bottom: 8px;
}
.marginborder1{
  margin-bottom:27px;
}
