.checkBox {
  padding: 12px 0px;
}
.flexOverAll {
  background-color: var(--color-gary-8, #fafafa);
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--color-gary-7, #dfdfdf);
  background-color: #F0EBED;
}
.radioTitle {
  margin-top: 16px;
  margin-bottom: 4px;
}
.radioDiv {
  margin-bottom: 4px;
  padding-left: 4px;
}
.raceTitle {
  margin-top: 16px;
}
.hrLine {
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
  padding-top: 16px;
  margin-bottom: 16px;
}
.ulTag {
  padding-left: 30px;
  margin-bottom: 4px;
}
.checkBoxDisable{
  pointer-events: none;
}
.selectTagStyle{
  width: 40%;
  margin-bottom: 16px;
}
