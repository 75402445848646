.aboutFlex {
  padding-top: 14px;
  padding-bottom: 14px;

  margin-top: 40px;
}
.divStyle {
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
  border-top: 1px solid var(--color-gary-7, #dfdfdf);
  background-color: var(--color-gary-8, #fafafa);
}
.titleFlex {
  margin-bottom: 8px;
}
.aboutText {
  max-width: 75%;
}
.footerStyle {
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.aboutFlex {
  margin: auto;
}

@media screen and (min-width: 2000px) {
  .aboutFlex {
    max-width: 1500px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 2000px) {
  .aboutFlex {
    max-width: 1400px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .aboutFlex {
    max-width: 1300px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .aboutFlex {
    max-width: 1000px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .aboutFlex {
    width: 950px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
  .aboutFlex {
    width: 650px;
  }
}

@media screen and (max-width: 700px) {
  .aboutFlex {
    padding: 0 20px;
  }
}

@media screen and (max-width: 550px) {
  .aboutText {
    max-width: 100% !important;
  }
  .contactFlex{
    width: 100% !important;
  }
}

.contactFlex {
  width: 40%;
}
.footerstyle{
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1); /* Add box shadow to top and bottom with reduced spread radius */
  background-color: white;
  position: sticky;
  bottom: 0;
  display: flex;
  width: 100%;
  /* background-color: yellow; */
  padding: 5px;
  font-size: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.pipestyles{
  height: 20px;
  border-left: 1px solid #581845;
  margin-left: 10px;
}
