.modalwidth {
  background-color: #fff;
  padding: 25px 5px 25px 0px;
  max-height: 680px;
  border-radius: 4px;
  overflow: hidden;
}
.container{
  overflow: auto;
  padding: 0px 25px 25px;
  max-height: 680px;
}

.cardStruture {
  min-width: 500px;
  padding: 15px;
  box-shadow: none;
  border: 1px solid #a5889c;
  margin-top: 15px;
}
.cardStruture1{
  min-width: 500px;
  padding: 15px;
  box-shadow: none;
  border: 1px solid #a5889c;
  margin-top: 15px;
  margin-bottom: 25px;
}
.btncheck {
  margin: 5px 0px 5px 0;
}

.cancel {
  margin-right: 8px;
  background-color: #888888;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}
.cancel:active,
.cancel:focus {
  background-color: #888888;
  border: none;
}
.share {
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}
.share:active,
.share:focus {
  border: none;
}
.borderLine {
  border-top: 1px solid #c3c3c3;
}

.fontsize{
  font-size: 13px;
}
