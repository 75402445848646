.bannerStyle {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}
.imgFlex {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}
.innerText {
  position: absolute;
  width: 56%;
  /* padding-bottom: 70px; */
}
.bannerTextStyle {
  margin-top: 10px;
  /* line-break: anywhere; */
  overflow-wrap: break-word;
}
.jobTitleInput {
  padding-left: 30px;
}
.locationInput {
  padding-left: 26px;
}
.searchCard {
  padding: 10px 10px;
  position: relative;
  width: 100%;
  /* background-color: #58184550; */
  /* margin-top: -35px; */
  /* align-items: center; */
  justify-self: center;
}
.svgBagStyle {
  position: absolute;
  z-index: 11;
  top: 3px;
  left: 8px;
}
.svgLocation {
  position: absolute;
  z-index: 11;
  top: 3px;
  left: 8px;
}

.marginAuto {
  margin: auto;
}
.disPlayStyle {
  display: contents;
}

@media screen and (min-width: 2000px) {
  .paddingStyle {
    width: 1500px;
  }
  .searchCard {
    width: 1500px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 2000px) {
  .paddingStyle {
    width: 1400px;
  }
  .searchCard {
    width: 1350px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .paddingStyle {
    /* max-width: 1300px; */
  }
  .searchCard {
    width: 900px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .paddingStyle {
    width: 1000px;
  }
  .searchCard {
    width: 950px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .paddingStyle {
    width: 1000px;
  }
  .searchCard {
    width: 950px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
  .paddingStyle {
    width: 700px;
  }
  .searchCard {
    width: 660px;
  }
}

@media screen and (max-width: 850px) {
  .locationDiv {
    margin-top: 16px;
    position: relative;
    /* left: -16px; */
    margin-bottom: 16px;
  }
  .locationInput {
    width: 100% !important;
  }
  .bannerTextStyle {
    margin-top: 16px !important;
  }
}

@media screen and (max-width: 500px) {
  .searchCard {
    padding: 16px 20px !important;
  }
  .bannerTextStyle {
    margin-top: 8px !important;
  }
  .innerText {
    padding-bottom: 120px !important;
  }
}

.overLay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* .crossIcon {
  all: unset;
  cursor: pointer;
  padding-top: 5px;
  bottom: 38px;
  right: 28px;
  position: relative;
} */

.jobtitleCloseIcon {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
  padding-top: 7px;
}
.locationCloseIcon {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
  padding-top: 7px;
}