.progressVertical {
  border-radius: 4px;
  position: relative;
  /* border: 1px solid var(--color-gary-6, #b3b3b3);
  box-sizing: border-box; */
  background-color: rgb(233, 233, 233);
}
.progressVertical_hide {
  border-radius: 4px;
  position: relative;
  /* border: 1px solid var(--color-gary-6, #b3b3b3);
  box-sizing: border-box; */
  background-color: rgb(233, 233, 233);
  filter: blur(5px);
}
.progressmatch {
  background-color: var(--color-secondary, #ffc203);
  height: 15px !important;
  border-radius: 4px;
  transition: 1s ease;
  transition-delay: 0.5s;
}
.progress {
  background-color: var(--color-secondary, #ffc203);
  height: 20px;
  border-radius: 4px;
  transition: 1s ease;
  transition-delay: 0.5s;
}
.CircularProgressbar {
  background-color: var(--color-white, #ffffff);
  border-radius: 100%;
  box-sizing: border-box;
}
.percentText {
  position: absolute;
  top: 0px;
  left: 12px;
}
.percentTextmatch {
  position: absolute;
  top: -0.5px;
  left: 12px;
  font-size: 12px;
}
.countStyle1 {
  stroke: #ed4857;
}
.countStyle2 {
  stroke: #eeac17;
}
.countStyle3 {
  stroke: #00be4b;
}
