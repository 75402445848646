/* .profiledev {
  position: relative;
  margin-left: 4px;
  margin-right: 8px;
}
.profile {
  height: 56px;

  width: 56px;

  border-radius: 100%;

  display: flex;

  align-items: center;

  justify-content: center;
  background-color: green;
} */
.iconContainer {
  padding: 5px;
  /* box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%) ; */
  /* border-radius: 5px; */
  /* margin-right: 5px; */
  /* margin-top: 5px; */
  border-bottom: 1px solid #c3c3c3;
}
.cardStyles {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 5px;
  box-shadow: unset !important;
  cursor: 'default';
}
.seletmsg {
  background-color: #eee8ec;
  border-radius: 5px;
  box-shadow: unset !important;
  cursor: 'default';
}
.cardStyles:hover {
  background-color: #eee8ec;
  cursor: 'default';
}
.mailCard {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  padding: 5px;
  height: 70px;
  border-bottom: 0.5px solid #c3c3c3;
}
.icons {
  cursor: pointer;
  padding: 5px;
}
.icons:hover {
  background-color: #eee8ec;
  cursor: pointer;
  border-radius: 5px;
}
.maillist {
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  position: relative;
  /* height: -webkit-fill-available; */
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 0px 2px 0px;
  border-radius: 4px 4px 0 0;
  /* margin-top: 2px; */
}
.inputSearch {
  margin-bottom: 20px;
}
.checkBox {
  margin-top: 3px !important;
}
.textHeadingStyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 310px;
  color: #333333;
}
.textStyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  color: #666666;
}
.scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.notification_dot {
  /* margin-right: 10px; */
  border-radius: 50%;
  background-color: #4f153e;
  height: 10px;
  width: 10px;
  margin-top: 5px;
}
.noEmail {
  /* align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
