
  .overAll {
    padding: 7px;
    overflow: scroll;
    /* width: 700px; */
  }
  
  .resumeStyleOne {
    margin-bottom: 16px;
  }
  