.jobTitle {
  margin-right: 24px;
}
.jobTitleFlex {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
  justify-content: space-between;
}
.richTextContainer {
  /* margin-top: 20px; */
  position: relative;
}
.displayNone {
  width: 0;
  height: 0;
  background-color: transparent;
  border: none;
}

.reactquillchange {
  height: 500;
}
.notesTextStyle ul {
  padding-left: 25px !important;
  list-style: disc;
}
.notesTextStyle u {
  color: #333333;
}
.reactquillchange p u {
  color: #333333;
}
.textArea .ql-editor p {
  max-height: 20000px; /* Adjust the max height as needed */
  overflow-y: auto; /* Add vertical scroll if content exceeds max height */
}
.textArea .ql-editor {
  max-height: 20000px; /* Adjust the max height as needed */
  overflow-y: auto; /* Add vertical scroll if content exceeds max height */
}
.textArea ql-container ql-snow {
  height: 30px;
}
/* .reactquillchange p {
  max-height: 700px;
}   */
.notesTextStyle ol {
  padding-left: 25px !important;
}
.notesTextStyle a {
  color: #581845 !important;
  font-weight: 600 !important;
}
.notesTextStyle p {
  margin-bottom: 0px;
  font-size: 13px !important;
  color: #333333 !important;
}
