.overAll {
  cursor: pointer !important;
}
.svgRadio:hover path {
  fill: var(--color-gary-6, #b3b3b3);
}
.labelStyle {
  margin-left: 6px;
  font-size: 14px;
  color: #581845
}
.pointer {
  cursor: pointer !important;
}
.disabled{
  pointer-events: none !important;
  opacity: 0.7;
}
.disabletooltip{
  fill:  #b3b3b3 !important;
  color: #b3b3b3;
}
