/* .overAll {
  /* overflow: scroll; */
/* padding: 16px; */
/* }  */
.addText {
  margin-top: 4px;
  margin-bottom: 1px;
}

.rateText {
  margin-top: 4px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0;
}

.inputStyle {
  height: 120px;
}

.inputContainer {
  margin-top: 12px;
}

.addBtn {
  margin-top: 8px;
  margin-left: 16px;
}

.btnConatiner {
  align-items: flex-end;
  margin-top: 15px;
}

.commentStyles {
  max-height: 120px;
  padding: 8px;
  border: 1px solid var(--color-gary-6, #b3b3b3);
  border-radius: 4px;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: clip;
  white-space: wrap;
  overflow-wrap: break-word;
}

.dateStyle {
  /* border-bottom: 1px solid var(--color-gary-6, #b3b3b3); */
  /* padding: 4px 8px; */
  box-sizing: border-box;
  height: 35px;
}

.dateStyle ul li {
  margin-right: -1px;
}

.borderStyle {
  /* border: 1px solid var(--color-gary-6, #b3b3b3); */
  box-sizing: border-box;
  border-radius: 4px;
  /* margin-bottom: 16px; */
}

.commentTextStyle {
  padding: 0px 8px 0px 0px;
  text-align: justify;
  font-size: 12px;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: clip;
  white-space: wrap;
  overflow-wrap: break-word;
}

.commentTextStyle p {
  margin: 0;
  font-size: 12px;
}

.commentTextStyle ul li {
  margin-left: 20px;
  font-size: 12px;
  list-style: disc;
}

.commentTextStyle ol li {
  margin-left: 20px;
  font-size: 12px;
}

.svgEdit {
  cursor: pointer;
  margin-top: 3px;
}

.rateFlex {
  margin-left: 16px;
}

.starstyle i {
  font-size: 30px;
}

.starsize i {
  font-size: 18px;
}

.cardstructure {
  width: 150px;
  padding: 5px;
}

.cardStyle {
  border-left: 6px solid #a5889c;
  margin-left: 2px;
  margin-top: 10px;
  margin-right: 4px;
  margin-bottom: 5px;
  padding: 5px 10px 0px 10px;
}

.initialbuttons {
  border: 1px solid #d7c7d2;
  border-radius: 4px;
  padding: 8px;
}

.profile {
  width: 35px;
  height: 35px;
  font-weight: bold;
  border-radius: 100%;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
}

.starsizes ul {
  height: 30px;
}

.overallmodal {
  padding: 25px;
  width: 700px;
  border-radius: 4px;
}

.textArea {
  margin-top: 10px;
}

.starsize ul li {
  font-size: 22px;
}

.starsize ul {
  margin-top: -13px;
}

.starsizes ul li {
  font-size: 26px;
  cursor: default;
}

.starstylehead ul li {
  font-size: 36px;
  cursor: default;
  /* margin-top: -11px; */
}

.starstylehead ul {
  height: 30px;
}

.ratingStar ul li {
  font-size: 16px;
  cursor: default;
  margin-right: 7px;
}

.ratingStar ul {
  font-size: 16px;
}

.starnotes ul li {
  font-size: 18px;
  cursor: default;
}

.starnotes ul {
  margin-bottom: none !important;
  height: 20px;
  margin-top: -46px;
  margin-left: 10px;
}

.OverallScore {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.36);
  margin: 0 auto;
}

.recommended {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
}

.changingtexts {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 125px;
}

.textArea ql-editor {
  max-height: 100px;
}

.textalign {
  text-wrap: nowrap;
}

.notesTextStyle {
  font-size: 14px;
  color: #333333;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: clip;
  white-space: wrap;
  overflow-wrap: break-word;
  text-align: justify;
}

.notesTextStyle ul {
  padding-left: 25px !important;
  list-style: disc;
}

.notesTextStyle u {
  color: #333333;
}

.showmore {
  font-size: 12px;
}
.deleteWarningPopUp {
  /* padding: 25px; */
  background-color: var(--color-white, #ffffff);
  width: 390px;
  padding: 25px;
  border-radius: 4px;
}
.insertStyles {
  margin-bottom: 5px;
}
.actionButtonWrapper {
  display: flex;
  flex-direction: row;
  /* margin-top: 15px; */
  justify-content: flex-end;
}

.actionButtonWrapper button {
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 0.6px;
  outline: none;
  border: none;
  padding: 5px 12px;
}

.actionButtonWrapper button.deleteButton {
  background-color: var(--color-primary);
  color: white;
}

.actionButtonWrapper button.deleteButton:hover {
  background-color: var(--color-primary-hover);
}
.cancel {
  background-color: #888888;
  font-size: 13px !important;
  font-weight: bold;
  color: #ffffff;
}
.actionButtonWrapper button.deleteButton {
  background-color: var(--color-primary);
  color: white;
}

.actionButtonWrapper button.deleteButton:hover {
  background-color: var(--color-primary-hover);
}