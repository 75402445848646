.overAll {
  background-color: var(--color-white, #ffffff);
  width: 1300px;
  height: 650px;
  padding: 25px;
  border-radius: 4px;
}

.overallmodal {
  padding: 25px;
  height: auto;
  border-radius: 4px;
}

.cardheader {
  background: #581845;
  height: 40px;
  border-radius: 4px 4px 0px 0px;
}

.part1 {
  min-width: 130px;
  margin-left: 10px;
}

.container {
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.profile {
  object-fit: contain;
  background-color: #eee8ec;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.profilehead {
  object-fit: contain;
  background-color: #eee8ec;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.countStyle1 {
  position: RELATIVE;
  top: -70px;
  left: 38px;
  background-color: #ed4857;
  /* border: 0.1px solid #a5889c; */
  border-radius: 100%;
  z-index: 11;
  display: flex;
  height: 27px;
  width: 27px;
  justify-content: center;
  align-items: center;
}

.countStyle2 {
  position: RELATIVE;
  top: -70px;
  left: 38px;
  background-color: #eeac17;
  /* border: 0.1px solid #a5889c; */
  border-radius: 100%;
  z-index: 11;
  display: flex;
  height: 27px;
  width: 27px;
  justify-content: center;
  align-items: center;
}

.countStyle3 {
  position: RELATIVE;
  top: -70px;
  left: 38px;
  background-color: #00be4b;
  border-radius: 100%;
  z-index: 11;
  display: flex;
  height: 27px;
  width: 27px;
  justify-content: center;
  align-items: center;
}

.triangle {
  width: 0;
  height: 0;
  rotate: 90deg;
  border-top: 10px solid transparent;
  border-right: 15px solid white;
  border-bottom: 10px solid transparent;
  position: relative;
  top: -1px;
  right: -2.5px;
}

.cardstructureforprofileother {
  width: 200px;
}

.cardstructureforprofile {
  /* height: 300px; */
  width: 200px;
  z-index: 5;
  /* border: 3px solid #dee2e6; */
  box-shadow: 0 1px 4px 0 #00be4bb0;
  /* box-shadow: 0px 0px 10px 3px #00BE4B; */
  -webkit-box-shadow: 0px 0px 10px 3px #00be4bb0;
}
.part3 {
  text-align: justify;
}

.starratingoverall ul li {
  font-size: 16px !important;
  cursor: default !important;
}

.starratingoverall ul {
  margin-top: -63px;
  /* margin-bottom: 20px; */
  height: 10px;
}

.changingtexts {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 125px;
}

.fixingcontent {
  overflow-y: scroll;
  display: flex;
  align-content: flex-start;
  padding: 5px 25px 25px 25px;
}

.tableboarder {
  border: 1px solid #a5889c;
}

.tableboarders {
  border-left: 1px solid #a5889c;
  border-right: 1px solid #a5889c;
  border-bottom: 1px solid #a5889c;
  min-height: 140px;
  padding: 5px;
}

.prosdata {
  border-right: 0.5px solid #a5889c;
  padding: 5px;
  vertical-align: baseline;
}

.prosdata ul {
  list-style-position: outside;
  margin-left: 15px;
  margin-bottom: 2px !important;
}

.prosdata ul li {
  list-style: disc;
}

.Consdata {
  border-left: 1px solid #a5889c;
  padding: 5px;
  vertical-align: baseline;
}

.Consdata ul {
  list-style-position: outside;
  margin-left: 15px;
  margin-bottom: 2px !important;
}

.Consdata ul li {
  list-style: disc;
}

.Consdatano {
  border-left: 1px solid #a5889c;
  padding: 5px;
}

.prosdatano {
  border-right: 0.5px solid #a5889c;
  padding: 5px;
}

.textelipssis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
}

.button_group {
  display: flex;
  flex-direction: row;
  background-color: #a5889c;
  border-radius: 5px;
}
.button_group button {
  padding: 3px 12px 3px 12px;
  width: fit-content;
  height: 30px;
  margin: 0;
  border: none;
  background-color: #a5889c !important;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
/* .button_group button:not(:last-child) {
  border-right: 1px solid #ccc;
} */


.Recommendedchanges {
  padding: 3px;
  background-color: rgb(0 190 75/80%);
  z-index: 1000;
  color: #ffffff;
  position: relative;
  bottom: -8px;
  margin-left: 7px;
}
.textelipssisforname{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 110px; 
  padding: 5px;
}