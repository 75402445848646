.subscriptionmodule {
  width: 770px;
  background-color: #ffffff;
  padding: 25px;
  border-radius: 5px;
}

.bottomborder {
  margin-top: 3px;
  border-bottom: 0.5px solid #000000;
}

.container {
  /* display: flex;
  flex-direction: row; */
  margin-top: 15px;
}

.line {
  width: 0.3px;
  height: 205px;
  color: #333333;
  background-color: #333333;
}

.creditcontainer {
  display: flex;
  flex-direction: row;
  color: #333333;
  padding: 15px;
}

.cards {
  border: 1px solid #d7c7d2;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0px 4px 4px 4px;
}

.addon {
  background-color: #D7C7D2;
  padding: 5px;
  width: max-content;
  border-radius: 5px 5px 0 0;
}

.overAll {
  padding: 25px;
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
}

.btnContainer {
  margin-top: 16px;
}

.titleText {
  padding-left: 8px;
}

.warningFlex {
  padding: 6px 8px;
  border: 1px solid #2E6ADD;
  background-color: #2E6ADD20;
  border-left: 8px solid #2E6ADD !important;
  border-radius: 8px;
  width: fit-content;
  margin-top: 15px;
  font-size: 13px;
}
.warningText {
  margin-left: 8px;
  font-size: 13px;
}
.line {
  height: 1px;
  width: 100%;
  background-color: #666666;
}
.line_OR {
  padding: 10px;
}