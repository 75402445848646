.sharelink {
  background-color: #fff;
  padding: 25px;
  width: 680px;
  border-radius: 4px;
}
.close {
  border: 'none';
  background: 'none';
  margin-right: 10px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Three equal columns */
  grid-template-rows: auto; /* Automatically sized rows */
  gap: 10px;
  max-height: 170px;
  width: 600px;
  overflow: scroll;
}
.line {
  margin: 15px 0px;
  border-top: 1px solid #c3c3c3;
}
.inputContainer{
  width: 100%;
}
.container {
  display: grid;
  justify-content: space-between;
  align-items: center;
}
.borderLine {
  margin-top: 10px;
  border-top: 1px solid #c3c3c3;
  /* padding-bottom: 20px; */
}
.share {
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  justify-content: flex-end;
}
.share:active,
.share:focus {
  border: none;
}
.cancel {
  margin-right: 8px;
  background-color: #888888;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  justify-content: flex-end;
}

.cancel:active,
.cancel:focus {
  background-color: #888888;
  border: none;
}


.nodata{
  padding: 10px;
  font-size: 13px;
}

.back {
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  justify-content: flex-start;
}

.back:active,
.back:focus {
  border: none;
}
.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px; /* Adjust this value as needed */
}

.headertext{
  font-size: 13px;
}

.skillContainer{
  min-width: 18rem;
  max-width: 19rem;
  margin-right: 15px;
  /* margin-left: 15px; */
}


.selected1{
  margin-top: 5px;
}
