.overAll {
  background-color: var(--color-white, #ffffff);
  padding: 20px 30px;
  border-radius: 4px;
  position: relative;
  min-width: 800px;
}
.svgClose {
  position: absolute;
  top: 16px;
  right: 16px;
}
.title {
  margin-bottom: 40px;
}
.locationFlex {
  padding-top: 20px;
  padding-bottom: 20px;
}
.datePicker {
  border: 1px solid var(--color-gary-5, #cccccc) !important;
  border-radius: 4px;
  height: 30px !important;
  box-sizing: border-box;
  padding-left: 12px;
  width: 100%;
}
.datePicker:hover {
  border: 1px solid var(--color-gary-6, #b3b3b3) !important;
}
.datePicker:focus {
  border: 1px solid var(--color-primary, #581845) !important;
  box-shadow: none !important;
}
.tillDateCheckBox {
  position: absolute;
  right: -93px;
  top: 28px;
}
.toFlex {
  position: relative;
}
.zindexStyleDate {
  z-index: 11;
}
.jobDesStyle{
  height: 150px;
}
.btnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 68px;
  height: 30px;
}
.btnLoader1{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 50px;
  height: 30px;
}