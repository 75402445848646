/* .icon {
  margin-left: 20px !important;
  margin-top: 20px;
} */

.model {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  height: 200px !important;
  width: 400px !important;
}
.cardStruture {
  position: relative;
  height: 157px;
  width: 270px;
  margin-right: 20px;
  /* width: 100%; */
  padding: 15px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* height: 100%; */
}
.cardStrutureborder{
  position: relative;
  height: 157px;
  width: 270px;
  margin-right: 20px;
  /* width: 100%; */
  border:1px solid #00be4b;
  padding: 15px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* height: 100%; */
}
.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.selectCard {
  position: relative;
  max-height: 200px;
  /* margin-right: 20px; */
  padding: 15px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #00be4b;
  box-shadow: 0px 0px 2px 2px #edf4f0;
  border-radius: 5px;
}
.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.modelheadder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.btn1 {
  background-color: transparent;
  color: #581845;
  border: none;
  width: 100%;
  /* margin-top: 20px; */
  /* border-top: 1px solid #c3c3c3; */
  border-radius: unset;
  /* padding: 18px 0; */
}

.btn {
background-color: transparent;
color: #581845;
border: none;
width: 100%;
border-radius: unset;
/* padding: 18px 0; */
display: flex;
justify-content: center;
align-items: center;
}




.btn:active,
.btn:focus {
  background-color: unset;
  transform: unset;
  border: unset;
  border-radius: unset;
}
.editmodal {
  background-color: var(--color-white, #ffffff);
  width: 500px;
  padding: 25px;
  border-radius: 4px;
}

.outlookEmailcard {
  margin-left: 20px;
}

.disconnectbtn {
  margin-left: 10px;
  border: 2px solid #ed4857;
}
.disconnectbtn:focus {
  border: 2px solid #ed4857 !important;
}

/* .disconnectbtn .button_buttonTypes-secondary__3QSSJ:hover {
  background-color: white !important;
} */
.disconnectbtn,
.buttonTypes-secondary:hover {
  background-color: white !important;
}

.disconnectbtn > span {
  color: #ed4857;
}

/* .lineVertical{
  border-bottom: 1px solid rgb(195, 195, 195, 1);
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
} */
.borderbottom1{
  border-bottom: 1px solid #c3c3c3;
  width: 100%;
  margin-top: 5px;
  /* margin-bottom: 5px; */
}
.borderbottom{
  border-bottom: 0.5px solid #581845;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}


/* 
.cardStruture:active{
  outline: 0!important;
  border: 1px solid #00be4b;
} */