.overAll {
  padding: 25px;
  position: relative;
  height: 90%;
}
.insideFlex {
  margin-bottom: 16px;
}
.svgEdit {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.titleStyle {
  margin-bottom: 8px;
  display: flex;
}
.phoneInput {
  border: none !important;
  padding: 0 !important;
  height: fit-content !important;
  color: #333333;
  background-color: transparent !important;
  width: fit-content;
}
.phoneHide {
  pointer-events: none;
}
.phoneHide div div {
  display: none;
}

.valuetext{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 60%;
}
