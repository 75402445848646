.overAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 24px;
}

.textStyle {
  padding-left: 20px;
}

.textStyle1 {
  padding-left: 20px;
}

.overAll1{
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 24px;
  width: 70%;
}

.makemecenter{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}