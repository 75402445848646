.overAll {
  background-color: var(--color-white, #ffffff);
  padding: 25px;
  border-radius: 4px;
  position: relative;
  /* padding-bottom: 40px !important; */
}
.salaryFlex {
  padding-top: 20px;
  padding-bottom: 20px;
}
.stateFlex {
  padding-bottom: 20px;
}
.svgClose {
  position: absolute;
  top: 16px;
  right: 16px;
}
.title {
  margin-bottom: 10px;
}
.updateBtnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 68px;
  height: 30px;
}