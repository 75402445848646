.eventPopUp {
  padding: 25px;
  min-width: 450px;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.content > div {
  margin-bottom: 10px;
}
.insertStyles {
  margin-bottom: 5px;
}

.eventPopUp p {
  margin: 0;
}

.eventPopUp .info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 10px;
  align-items: flex-start;
}

.eventPopUp .infoText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: -2px 0px 0px 15px;
}

.eventPopUp .icon {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  /* padding: 5px; */
  margin-left: 5px;
  width: 30px;
  height: 30px;
}

.eventPopUp .icon:hover {
  background-color: #d7c7d2;
  border-radius: 50%;
}

.eventPopUp .icon:active {
  transform: scale(0.8);
  transform-origin: center;
  transition: 500ms ease-in-out;
}

/* .eventPopUp .email { */
/* background-color: rgba(0, 0, 0, 0.1); */
/* letter-spacing: 1.5px; */
/* padding: 2px 10px; */
/* border-radius: 100px; */
/* margin: 4px 6px; */
/* } */

.eventPopUp .emailContainer {
  width: 100%;
  max-width: 500px;
}

.eventPopUp .button {
  margin-left: 6px;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 1px;
  outline: none;
  border: none;
  padding: 5px 12px;
  background-color: var(--color-primary);
  color: white;
}

.eventPopUp .button:hover {
  background-color: var(--color-primary-hover);
}

.eventPopUp .title {
  font-size: 16px;
  margin-left: 10px;
  color: var(--color-primary);
  font-weight: 500;
  /* margin-bottom: 20px; */
  text-transform: capitalize;
}

.profile {
  padding: 5px 10px;
  display: flex;
  align-items: flex-start;
}

.profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.profile .title {
  margin-left: 10px;
  color: black;
}

.profile .title p:first-child {
  font-size: 20px;
  font-weight: 500;
}

.profile .title p:last-child {
  font-size: 16px;
}

.deleteWarningPopUp {
  background-color: var(--color-white, #ffffff);
  width: 390px;
  padding: 25px;
  border-radius: 4px;
}
.cancel {
  background-color: #888888;
  font-size: 13px !important;
  font-weight: bold;
  color: #ffffff;
}
.warningMessage {
  display: flex;
  align-items: center;
}

.warningMessage p {
  margin: 0px 0px 0px 10px;
}

.actionButtonWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: flex-end;
}

.actionButtonWrapper button {
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 0.6px;
  outline: none;
  border: none;
  padding: 5px 12px;
}

.actionButtonWrapper button.deleteButton {
  background-color: var(--color-primary);
  color: white;
}

.actionButtonWrapper button.deleteButton:hover {
  background-color: var(--color-primary-hover);
}

.eventTitle {
  display: inline-block;
  width: 300px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
