.tableDiv {
  margin-top: 20px;
  margin-bottom: 20px;
}
.addBtn {
  align-self: flex-end;
}
.updateBtnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  height: 30px;
}