.overAll{
  display: flex;
}
.progressStyle {
  margin-top: 8px;
  margin-bottom: 20px;
}

.dataListStyleai {
  padding: 1px 4px 1px 4px;
  box-sizing: border-box;
  display: flex;
  /* border-bottom: 1px solid var(--color-gary-7, #dfdfdf); */
  align-items: center;
}

.dataListStyle {
  padding: 8px 0px;
  box-sizing: border-box;
  display: flex;
  /* border-bottom: 1px solid var(--color-gary-7, #dfdfdf); */
  align-items: flex-start;
}

.titleStyle { 
  COLOR:#581845;
  text-transform: capitalize;
}

.cardchanging{
  margin-bottom: 10px;
  margin-top: 2px;
  margin-left: 1px;
  margin-right: 2px;
  padding: 5px;
}

.valueStyle {
  margin-right: 8px;
}

/* .mapListContainer {
  /* border: 1px; */
/*} */

.mapListContainer {
  box-sizing: border-box;
  /* margin-top: 20px; */
  /* -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36); */
}

.valueListStyle {
  padding: 0px 0px 8px 0px;
  font-size: 13px;
}

.svgStyle {
  margin-right: 12px;
}

.textwrap{
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: clip;
  white-space: wrap;
  overflow-wrap: break-word;
  font-size: 12;
}

.btnwithContent{
  padding-left: 11px;
  padding-right: 16px;
}


.sliderstyle{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  flex-wrap: wrap;
}

.innerstyle{
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center 
}

.customrange {
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  border-radius: 5px;
  margin: 10px 0;
}

/* Style for the movable part (thumb) */
.customrange::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #ffffff; /* White color */
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #cccccc;
}

.customrange:focus {
  outline: none;
}

.parent{
display: flex;
flex-direction: row;
flex-wrap: wrap;
margin-top: 15px;
justify-content: space-between;
}

.techcardstyles{
  padding-left: 10px;
  padding-right: 10px;
}

.nontechcardstyles{
  padding-left: 10px;
  padding-right: 10px;
}

.sliderheaderstyle{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.sliderheadertext1{
  width:200px
}

.sliderheadertext2{
  width:200px
}

.innerSliderbarStyle{
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.chatgptoutput{
  width: 50%;
}

.chatgptoutput1{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.infohead1{
  width: 20%;
}

.infohead2{
  width: 10%;
}

.infohead3{
  width: 20%;
  display: flex;
  align-items: center;
}

.parent{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: space-between;
  margin-bottom: 15px;
}

  .progressbarstyle{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .btnContainer{
  justify-content: space-between;
  margin-top: 15px;
  }
  
  .splitline{
    border-left :0.5px solid #c3c3c3;
    height: 450px;
  }

  .spacepadding{
    padding-top: 15px;
  }
  
  .weightagepopup{
    background-color: white;
    padding: 25px;
    width: 1000px;
    border-radius: 4px;
  }
  
  .popbtnContainer{
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid #c3c3c3;
  }
  
  .popupheading{
    border-bottom: 1px solid #581845
  }
  .cancelBtn{
    margin-right: 8px;
  }

  .customrangeoutput {
    width: 100%;
    height: 10px;
    background: #d3d3d3;
    outline: none;
    border-radius: 5px;
    margin: 10px 0;
    pointer-events: none; 
  }
  
  /* Style for the movable part (thumb) */
  .customrangeoutput::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* width: 20px;
    height: 20px;
    background: #ffffff; 
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #cccccc; */
  }
  
  .customrangeoutput:focus {
    outline: none;
  }

  .progressbarlabel{
    padding: 4px 5px 0px 5px;
    font-weight: bold;
    display: none;
  }
  .InfoiconchangeStyle{
    margin-bottom: 0px;
    cursor: pointer;
  }

  .infoOuterCard{
    position: absolute;
    left: 658px;
    top: 135px;
  }

  .cardfront1 {
    z-index: 10000;
    padding: 5px 10px 5px;
    margin-left: 9px;
    font-size: 13px;
    margin-top: 1vh;
    max-width: 425px;
    position: absolute;
  }

  .labelpadding{
    padding: 0px 0px 0px 11px;
    font-size: 13px;
  }

  .labelpadding2{
    margin: 0px -9px 0px 0px;
    padding: 0px 0px 0px 5px;
    font-size: 13px;
  }

  .labelpadding3{
    padding: 0px 0px 0px 5px;
    font-size: 13px;
  }

  .threediv{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .centervalue{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-container {
    overflow: hidden;
    position: relative;
    max-height: calc(2 * 1.2em); /* 1.2em is the line height */
    margin-bottom: 10px;
  }
  
  .text {
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .text-container.expanded .text {
    -webkit-line-clamp: initial;
  }

  .textellipces{
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .nodata{
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #c3c3c3;
    margin-top: 10px;
  }

  .rarecase{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .moreinformation {
    color: #fcc203;
    font-size: 12px;
    margin-left: 8px;
    border-bottom: 1px solid #fcc203;
  }

  .infotitle{
    padding-bottom:2px;
  }

  .updateBtnLoader{
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .aligncenter{
    justify-content: center;
    align-items: center;
  }