.displayNone {
  display: none;
}
.border {
  border: 1px solid var(--color-gary-4, #979797);
  border-style: dashed;
  border-radius: 4px;
  padding: 16px 40px;
  align-items: center;
  min-width: 370px;
  display: flex;
  justify-content: center;
  min-height: 80px;
  height: 135px;
}
.uploadpopup {
  width: 450px;
  /* height: 262px; */
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
}
.uploadpopup1 {
  width: 1200px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}
.labelStyle {
  margin: 0;
  margin-left: 4px;
}
.cardOverAll {
  /* height: 150px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.uploadText {
  margin-top: 8px;
}
.btnStyle {
  margin-left: 16px;
}
.textArea {
  height: 120px;
}
.svgClose {
  cursor: pointer;
  margin-left: 8px;
  position: relative;
  bottom: 2px;
}
/* .title {
  margin-bottom: 4px;
} */
/* .desText {
  margin-bottom: 24px;
  font-weight: bold;
} */
.innerFlex {
  position: relative;
}
.uploadjdfilebox {
  margin-right: 8px;
  border: 1px solid #333333;
  padding: 5px;
  margin-bottom: 8px;
  border-radius: 3px;
}

.uploadjdfiletext {
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.section1 {
  flex-basis: 35%;
}
.section2 {
  flex-basis: 5%;
}

.section3 {
  flex-basis: 60%;
}

.row_bottom {
  padding-bottom: 15px;
}
.cards {
  position: relative;
  height: 575px;
}
.cardsonly {
  position: relative;
  height: 320px;
}
.line {
  width: 1px; /* Adjust the width of the vertical line */
  height: 205px; /* Adjust the height of the vertical line */
  background-color: #666666; /* Adjust the color of the vertical line */
}

.buttongenerate {
  position: absolute; /* Position the button relative to the parent */
  bottom: 10px; /* Align the button at the bottom */
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%);
}
.line_OR {
  padding: 10px;
}

.profile_image {
  border-radius: 50%;
}
