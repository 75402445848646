.dropDownListStyle {
  cursor: pointer;
}
.popUpFlex {
  margin-left: 8px;
}
.loaderIndex {
  z-index: 100;
}

/* product route*/

.model{
width:180px;
z-index: 1;
}

.model1 {
  width: 37px;
}

.tobbar {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}


.titleuser{
  padding: 5px 0;
  border-right: 0.5px solid #555555;
  display: flex;
}

.titleuserA{
  padding: 5px 0;
  border: 0;
  display: flex;
}

.btnColor{
    /* border-color: unset; */
    background-color: unset;
    /* box-shadow: none; */
}

.overlaybackground{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.34);
  z-index: 9999999;
  pointer-events: auto;
}