.overAll {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  height: 100%;
}
.dndBoardContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: -webkit-fill-available;
}
.btnStyle {
  margin-right: 10px;
}

.titleContainer {
  padding: 10px;
  background-color: #eee8ec;
  font-size: 12px;
  margin-bottom: 10px;
  display: flex;
  color: #581845;
  justify-content: space-between;
  position: relative;
  padding-right: 40px;
}
.triangle {
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-right: 30px solid white;
  border-bottom: 22px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
}
.jobstext {
  height: 30px;
  padding: 10px;
  background-color: #eee8ec;
  display: flex;
  align-items: center;
  color: #581845;
}
.locationboxstyle {
  text-transform: none !important;
  background-color: #f7f7f7;
  height: 30px;
  border: none !important;
  border-radius: 0 !important;
  scroll-behavior: smooth;
  width: 170px;
  font-size: 13px !important;
  text-overflow: ellipsis;
}
.applicantboxstyle {
  text-transform: none !important;
  background-color: #f7f7f7;
  height: 30px;
  border: none !important;
  border-radius: 0 !important;
  scroll-behavior: smooth;
  width: 250px;
  font-size: 13px !important;
  text-overflow: ellipsis;
}
.boxstylename{
  text-transform: none !important;
  background-color: #f7f7f7;
  height: 30px;
  border: none !important;
  border-radius: 0 !important;
  scroll-behavior: smooth;
  width: 250px;
  font-size: 13px !important;
  text-overflow: ellipsis;
}
.searchstyle {
  /* width: 412.27px; */
  display: flex;
  align-items: center;
}
.searchboxoverall {
  border-radius: 0px 20px 20px 0px;
  background-color: #f7f7f7;
  height: 30px !important;
}
.middleline {
  margin-right: 10px;
  height: 25px;
  border: 1px solid #e4dae1;
  margin-top: 2px;
}
.locationicon {
  margin: 5px 0 5px 0;
}
.crossIcon {
  all: unset;
  cursor: pointer;
  padding-top: 5px;
  padding-right: 5px;
}
.searchicons {
  height: 30px;
  padding: 10px;
  background-color: #581845;
  border-radius: 0px 20px 20px 0px;
  cursor: default;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {

.scrollStyle  {
    outline: none;
    scrollbar-width: thin;
    scrollbar-color: rgba(26, 26, 26, 0.3) white;
  }
}

.col {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  z-index: 99;
}
.filterFlex {
  /* margin-right: 5px; */
  margin-left: 10px;
}
.parent{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: space-between;

}
.sliderstyle{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  flex-wrap: wrap;
}
.innerstyle{
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; 
  width:56%
}


.customrange {
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  border-radius: 5px;
  margin: 10px 0;
}

/* Style for the movable part (thumb) */
.customrange::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #ffffff; /* White color */
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #cccccc;
}

.customrange:focus {
  outline: none;
}
.parent{
display: flex;
flex-direction: row;
flex-wrap: wrap;
margin-top: 15px;
justify-content: space-between;
margin-bottom: 15px;

}
.progressbarstyle{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.btnContainer{
justify-content: space-between;
margin-top: 15px;
}

.splitline{
  border-left :0.5px solid #c3c3c3;
  height: 450px;
  
}
.spacepadding{
  padding-top: 15px;
}

.weightagepopup{
  background-color: white;
  padding: 25px;
  width: 1100px;
  border-radius: 4px;
}

.popbtnContainer{
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid #c3c3c3;
}

.popupheading{
  border-bottom: 1px solid #581845
}
.cancelBtn{
  margin-right: 8px;
}

.updateBtnLoader{
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scoreinputfield{
  width: 40px;
  padding: 3px 0px 0px 0px;
  text-align: center;
  border: none;
  outline: none;
  box-shadow: 1px 2px 2px 2px rgb(238, 232, 236);
  border-radius: 5px;
}

.techtitleblock{
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  justify-content: center;
}


.techresetbutton{
  padding-left:10px
}

.nontechtitleblock{
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 28px;
  justify-content: center;
}


.nontechresetbutton{
  padding-left:10px
}