/* .filterTextStyle {
  margin-right: 16px;
}
.cardConatiner {
  /* padding: 16px; */
/* min-width: 276px;
  margin-top: 24px;
  position: relative;
    z-index: -1;
} */
/* .qualificationConatiner {
  padding: 16px 16px;
  padding-bottom: 0
}
.checkBoxContainerOne {
  min-width: 116px;
}
.checkBoxContainer {
  padding-bottom: 16px;
  padding-top: 8px;
}
.checkBoxOne {
  padding-bottom: 16px;
}
.showCheckBox {
  margin-top: 24px;
}
.pointer {
  cursor: pointer;
}
.filterFlex {
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
  padding: 8px 16px;
}
.padding16{
  padding: 0 16px;
  padding-bottom: 16px;
} */
.filter_ali {
  float: right;
  position: relative;
}

.drop_down {
  position: relative;
  float: right;
  margin-bottom: 20px;
}

.drop_down_header {
  padding: 10px;
  background-color: #ffff !important;
  width: 230px;
  height: 30px;
  border: 1px solid #581845;
  border-radius: 11px;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
.quickfilters {
  width: 99%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
/* #typePrompt:hover  */
.hoverbo.hover {
  display: block;
}

.hoverbo {
  display: none;
}
.drop_down_menus {
  background-color: #fff;
  position: absolute;
  background-color: var(--color-white, #fff);
  border-radius: 12px;
  max-height: 450px;
  overflow-y: scroll;
  width: 230px;
  display: none;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47000000000000003);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47);
}

.show {
  display: block;
}

.filtername {
  display: flex;
  font-style: normal;
  color: #581845;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.mtstyle {
  color: #581845 !important;
  border-top: 1px solid #dee2e6;
  padding: 15px;
}

.linkedin_filter {
  color: #581845 !important;
  /* border-top: 1px solid #dee2e6; */
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
}

.alldefl {
  border-style: solid;
  border-color: #a5889c;
  padding: 3px 9px 3px 7px;
  margin-left: 12px;
  border-radius: 16px;
}
.quickfil {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px 2px 10px;
  width: fit-content;
  border: 1px solid #581845;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 13px !important;
}

.quickfil2 {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #581845;
}
.stylesvg {
  margin-left: 10px;
}
.checkbox {
  color: #581845;
}

.boxstyle {
  text-transform: none !important;
  height: 35px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectoptions {
  margin-top: 5px;
}
.filterstyle {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.filtersvg {
  transform: rotate(270deg);
}
