.filterOverAll {
  /* padding-top: 20px; */
  /* padding-right: 16px; */
  position: relative;
  
}
.drop_down {
  position: relative;
  float: right;
  margin-bottom: 20px;
}

.drop_down_header {
  padding: 10px;
  background-color: #ffff !important;
  width: 230px;
  height: 30px;
  border: 1px solid #581845;
  border-radius: 11px;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
.tabsContainer {
  padding-top: 10px;
  width: 100%;
  /* overflow-y: scroll; */
}

.tabsStyle {
  /* margin-top: 50px; */
  position: relative;
  /* bottom: 50px; */
}
.infiStyle {
  position: absolute;
  right: 0;
  top: 9px;
}
.svgInfy {
  margin-left: 4px;
}

/* .overAll {
  margin: auto;
  max-width: 1300px;
} */

.ribbon{
  background-color: #EEE8EC;
  height: 40px;
}
.triangle {
  width: 0px;
  height: 0px;
  border-top: 22px solid transparent;
  border-right: 30px solid white;
  border-bottom: 22px solid transparent;
  position: relative;
  top: -1px;
  right: 0px;
}

.titleContainer {
  padding: 8px;
  background-color: #eee8ec;
  font-size: 12px;
  margin-bottom: 10px;
  display: flex;
  color: #581845;
  justify-content: space-between;
  position: relative;
  padding-right: 40px;
}