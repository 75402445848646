.overAll {
  background-color: #fff;
  /* width: 80%; */
  position: absolute;
  top: 0;
  right: 0;
  /* min-width: 400px; */
  width: 80%;
}
.modal {
  background-color: red !important;
}
.openStyle {
  margin-right: 8px;
  margin-top: 3px;
}
.border {
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-gary-4, #979797);
}
.button {
  padding: 16;
  display: grid;
  margin: 16;
}

/* .overAll::-webkit-scrollbar {
  width: 8px;
  margin-left: 16px;
  height: 8px;
  overflow: overlay;
}

.overAll::-webkit-scrollbar-track {
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: rgba(26, 26, 26, 0.3);
  border-radius: 2px;
} */