.cardOverAll {
  padding: 8px 0px 8px 8px;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-top: 8px;
  margin-right: 8px;
  height: 110px;
  width: 100%;
}
.cardwrap{
  display: flex;
  flex-wrap: wrap;
  /* width: 32%; */
  margin-left: -5px;
  margin-right: 19px;
}

.cardHeight {
  height: 68px;
}
.height{
  min-height: 75px;
}
.profile {
  height: 56px;
  width: 56px;
  border-radius: 100%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.percentageStyle {
  background-color: var(--color-secondary, #ffc203);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 1px;
  width: 45px;
  position: absolute;
  bottom: -10px;
  left: 5px;
}
.nameContainer {
  margin-left: 8px;
  position: relative;
  top: 2px;
}
.svgViewStyle {
  margin: 0 8px;
}
.skillStyle {
  line-clamp: 2;
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.skillFlex {
  width: 35%;
  position: relative;
  padding-right: 16px;
}
.notesFlex {
  cursor: pointer;
  width: fit-content;
  
  
}
.notesDisableFlex {
  
  width: fit-content;
  
  
}
.inviteContainer {
  align-self: center;
  width: 100%;
  min-height: 75px;
  padding-bottom: 18px;
}
.profileFlex {
  width: 35%;
}
.appliedText {
  margin: 0 4px;
}
.svgInviteNone {
  pointer-events: none;
  opacity: 0.3;
}
.svgInviteNoneOne {
  pointer-events: none;
  opacity: 0.3;
}
.svgInvitePointer {
  cursor: pointer;
}
.popTitle{
  margin-left: 8px;
}
.border{
  border-left: 1px solid #A5889C;
  height: 95px;
}
.fav{
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  margin-top: 2px;
}
.pointer{
  cursor: pointer;
}
.flexproperty{
  display: flex;
  align-items:center;
}
.inviteddate{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.makecenter{
  justify-content: center;
}