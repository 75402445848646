@import '../../../default.css';

.overAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px;
  position: relative;
  padding-right: 32px;
  width: 200%;
  margin-left: -50%;
  height: 110%;
  margin-top: -5%;
}
.Popup {
  background-color: var(--color-white, #ffffff);
  width: 680px;
  padding: 25px;
  border-radius: 4px;
}
.titelTextStyle {
  margin-left: 12px;
}
.btnContainer {
  margin-top: 16px;
}
.btnCancelStyle {
  margin-right: 8px;
}
.btnDeleteStyle {
  margin-left: 8px;
}
.svgClose {
  position: absolute;
  right: 4px;
  top: 4px;
}
.info {
  margin-top: 8px;
}
.flexMargin {
  margin-bottom: 2%;
}

.notes {
  color: rgba(26, 26, 26, 0.5);
  grid-column: 1 / span 2;
  white-space: nowrap;
}
.notes1 {
  color: rgba(26, 26, 26, 0.5);
  grid-column: 1 / span 2;
  white-space: nowrap;
}
.notes2 {
  /* color: rgba(26, 26, 26, 0.5); */
  color: #333333 !important;
  grid-column: 1 / span 2;
  white-space: nowrap;
  display: grid;
  grid-template-columns: [one] 1fr [two] 1fr [three];
  grid-template-rows: auto;
  grid-gap: 20px 20px;
  /* padding: 0 0 10px 0; */
}

.notes div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notes div:first-child p {
  margin-bottom: 2px;
}

.privateNote {
  height: 50px;
}

.card1 {
  padding: 10px;
}

.card2 {
  padding: 4px 0px 0px 4px;
}
.card3 {
  height: 50px;
}
.warn {
  color: red;
  margin-bottom: 0px;
  font-size: 12px;
}

.date {
  width: 100%;
}
.time {
  border: 1px solid var(--color-gary-5, #cccccc);
}
.to {
  font-size: 16px;
}

.meetingForm {
  /* background-color: white; */
  max-height: 520px;
  height: 100%;
  border-radius: 4px;
  /* width: 700px; */
  /* max-width: 650px; */
  display: grid;
  grid-template-columns: [one] 1fr [two] 1fr [three];
  grid-template-rows: auto;
  grid-gap: 20px 20px;
  padding: 0px 15px;
  margin: 10px;
  overflow: auto;
  /* margin-top: 15px; */
}

.timeInputWrapper {
  display: flex;
  align-items: flex-start;
  height: 20px !important;
}

.timeInputWrapper .to {
  display: flex;
  align-items: center;
  margin: 5px;
}

.location {
  grid-column: 1 / span 2;
}

.addInterviewer .icons {
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
}
.dateview.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 3px 10px !important;
  background-color: #581845;
  /* margin-left: 10px; */
}
.dateview.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 3px 10px !important;
  background-color: #581845;
}

.addInterviewer .icons p {
  margin-bottom: 0;
}

.dateview {
  display: flex;
  flex-direction: column;
  border-color: #58184570 !important;
}

.duration p {
  margin: 0;
  /* margin-bottom: 7px ; */
  /* margin-bottom: 7px ; */
}

.duration p:nth-of-type(even) {
  padding: 3.5px 10px;
  /* margin-top: 4.5px; */
  /* background-color: rgba(0, 0, 0, 0.07); */
  border-radius: 4px;
  border: 1px solid #cccccc;
  /* width: fit-content; */
  padding: 3.5px 10px;
  /* margin-top: 4.5px; */
  /* background-color: rgba(0, 0, 0, 0.07); */
  border-radius: 4px;
  border: 1px solid #cccccc;
  /* width: fit-content; */
}

.reminder > div {
  display: flex;
}

.formTitle {
  grid-column: 1 / span 2;
  margin-bottom: 0px;
  /* margin-left: 5px; */
}

.actionButtonWrapper {
  /* grid-column: 1 / span 2; */
  border-top: 1px solid #c3c3c3;
  display: flex;
  justify-content: flex-end;
}

.actionButtonWrapper button {
  /* margin-left: 20px; */
  /* margin-left: 20px; */
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 0.6px;
  outline: none;
  border: none;
  padding: 5px 12px;
  font-size: 13px !important;
}

.actionButtonWrapper button.continueButton {
  background-color: var(--color-primary);
  color: white;
}

.actionButtonWrapper button.continueButton:hover {
  background-color: var(--color-primary-hover);
}

.label {
  margin-bottom: 4px;
  color: #581845;
  font-size: 13px;
}

label > p {
  margin: 0 !important;
  padding: 0;
}
.cancelButton {
  margin-right: 8px;
  background-color: #888888;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
}

.cancelButton:active,
.cancelButton:focus {
  background-color: #888888;
  border: none;
}
.continueButton {
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
}
.continueButton:active,
.continueButton:focus {
  border: none;
}

.buttonContainer {
  display: flex;
  align-items: center;
}
.scrollfornav nav {
  display: flex;
  width: 650px;
  flex-wrap: nowrap;
  /* overflow-x: scroll; */
  margin: 0px 25px 0px 25px;
}
.scrollfornav a {
  text-wrap: nowrap;
}

.cardview {
  margin: 1px 10px 1px 1px;
  padding: 10px;
  margin-bottom: 10px;
}
.infocard {
  padding: 5px;
}
.scoreinputfield {
  text-align: center;
  text-align: center;
  box-shadow: 1px 2px 2px 2px rgb(238, 232, 236);
  border: 1px solid #a5889c !important;
  border-radius: 5px;
  height: 20px;
}
.popup {
  font-size: 13px;
}
.container {
  top: 0;
  left: 0;
  z-index: 99;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  display: grid;
}
.screen {
  background-color: white;
  width: 500px;
  height: 400px;
  display: flex;
  justify-content: space-around;
}
.border {
  border-color: #58184570 !important;
}
