.container {
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.18);
  z-index: 99;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  display: grid;
}
.screen {
  background-color: white;
  width: 500px;
  height: 400px;
  display: flex;
  justify-content: space-around;
}
.comparativeloader {
  width: 750px;
  height: 500px;
  background-color: white;
  padding: 25px;
  border-radius: 5px;
}
