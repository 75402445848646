.name{
    color:#333;
    font-size: 13px;
    font-weight: 500;
}
.count{
    color: #333;
    font-size: 13px;
    font-weight: 600;
    padding-left: 4.5px;
    /* padding-top: 0.5px; */
}
