.overAll {
  margin-top: 16px;
  overflow-y: scroll !important;
  display: flex !important;
}
.logList {
  padding-bottom: 8px;
}
.pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  /* padding: 16px 30px; */
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
}
 
