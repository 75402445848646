.iconColor {
  margin-right: 8px;
  color: var(--color-error, #f94949);
}

.iconTickColor {
  margin-right: 8px;
  color: var(--color-success, #34cc65);
}


.row {
  display: flex;
  flex-direction: row;
}

.splitrow_1 {

  width: 50%;
  height: 100vh;
  background-color: #F0EBED;
  border-top-right-radius: 25px;
  display: flex;
  Border-bottom-right-radius: 25px;
  -webkit-appearance: none;
  appearance: none;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.splitrow_2 {
  width: 50%;
  height: 100vh;

}

.center_aligh_slider {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.text {
  color: #581845;
}
.pass_content{
  margin-top: 4px;
}
.iconColor {
    margin-right: 2px;
  color: var(--color-error, #f94949);
}

.iconTickColor {
    margin-right: 2px;

  color: var(--color-success, #34cc65);
}
.input {
  width: 100%;
  padding: 8px;
  border-radius: 9px;
  margin-top: 20px;
  box-shadow: 0px 3px 1px #BEADB9;
  Border-bottom-left-radius: 10px;
  Border-bottom-right-radius: 10px;
  border: 1px solid #ffffff;
  text-indent: 30px;
  height: 35px;
}



.footer {
  position: fixed;
  bottom: 10px;
  left: 25%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #581845
}

.usericon {
  top: 22px;
  left: 0px;
  height: 25px;
  width: 30px;
}

.login_button {
  width: 100%;
  margin-top: 20px;
  padding: 3px;
  border-radius: 5px;
  background-color: #581845;
  border: 2px solid #581845;

}

.button{
margin-top: 20px;
text-align: center;
justify-content: center;
border-radius: 5px;
}


 .button:hover {
   box-shadow: none;
 }

.container {
  margin-top: 50px;
}

.logo{
  margin-top: 20px;
}
.passwordlocktick{
  margin-top: 20px;
  position: relative;
}
.loginbutton{
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.passwordicon {
  top: 23px;
  left: 0px;
}

   
