* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #212529;
  font-size: 14px;
  font-weight: 400;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
  font-weight: 600;
}

a {
  color: #581845;
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}

a:hover {
  text-decoration: none;
}

a:focus {
  color: #212529;
  text-decoration: none;
}

/* //Utility */

.deleteIcon {
  color: #2196f3;
  font-size: 28px;
  margin-top: 2px;
}

.loginLogo {
  height: 70px;
  width: auto;
}

.table_min_height {
  min-height: 200px;
}

.section-padding {
  padding: 40px 0;
}

.text-underline {
  text-decoration: underline;
}

.full_height {
  min-height: calc(100vh - 280px) !important;
  height: unset;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-500 {
  font-weight: 500;
}

.pointer {
  cursor: pointer;
}

.invite_user_title {
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.small,
small {
  font-size: 12px;
}

.col-auto.max-width {
  flex: 0 0 auto;
  width: auto;
  max-width: 80%;
}

.left-0 {
  left: 0px !important;
}

/* //Button */

.btn {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}

.btn.btn-primary {
  background-color: #581845;
  border-color: #581845;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle,
.btn.btn-primary:hover {
  background-color: #39102d;
  border-color: #39102d;
}

.btn.btn-primary:focus {
  background-color: #39102d;
  border-color: #39102d;
}

.btn-check:focus+.btn-primary,
.btn.btn-primary:focus {
  color: #fff;
  background-color: #39102d;
  border-color: rgb(57, 16, 45);
  box-shadow: 0 0 0 0.25rem rgb(57, 16, 45, 0.5);
}

.min-btn-width {
  min-width: 80px;
}

.btn .spinner-border-sm {
  height: 13px;
  width: 13px;
  margin-right: 5px;
  border-width: 2px;
}

.status-btn-success {
  background-color: #ffffff00;
  color: #34cc65;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  border: unset;
  height: 30px;
  min-width: 80px;
  line-height: 30px;
  border-radius: 5px;
  text-align: left;
}

.status-btn-inactive {
  background-color: #ffffff00;
  color: #fd1111;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  border: unset;
  height: 30px;
  min-width: 80px;
  line-height: 30px;
  border-radius: 5px;
  text-align: left;
}

.status-btn-admin {
  background-color: #fcc203;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  border: unset;
  height: 30px;
  min-width: 80px;
  line-height: 30px;
  border-radius: 5px;
}

.status-btn-warning {
  background-color: #ffffff00;
  color: #ffc203;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  border: unset;
  height: 30px;
  min-width: 80px;
  line-height: 30px;
  border-radius: 5px;
  text-align: left;
}

.status-btn-primary {
  background-color: #581845;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  border: unset;
  height: 30px;
  min-width: 80px;
  line-height: 30px;
  border-radius: 5px;
}

/* //Toaster */

.toast {
  flex-basis: 100%;
  min-width: 300px;
  position: fixed;
  top: 30px;
  right: -30px;
  z-index: 9999999999;
}

.toast.show {
  right: 30px;
}

.toast-header {
  padding: 10px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #212529;
}

.toast-header svg {
  width: 20px;
  height: 20px;
}

.toast.success {
  border: 1px solid #17692e;
}

.toast.success svg {
  color: #17692e;
}

.toast.success .toast-header {
  border-left: 8px solid #17692e !important;
}

.toast.error svg {
  color: #dc3545;
}

.toast.error .toast-header {
  border-left: 8px solid #dc3545 !important;
}

.toast.error {
  border: 1px solid #dc3545 !important;
}

/* //Container */

/* @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1440px;
        padding: 0px 50px;
    }
} */

@media (min-width: 512px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
    padding: 0px 15px;
  }
}

/* //Navbar Style */

.zitaNav {
  padding: 0px !important;
  color: #ffffff;
  background-color: #581845;
  box-shadow: 1px 2px 1px 1px #d7c7d2;
}

.zitaNav .navbar-brand img {
  height: 70px;
  width: auto;
}

/* //Title Style */

.pagename {
  font-family: 'Roboto', sans-serif;
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 600;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-left: 1rem;
  margin-right: 4%;
  /*margin-right: 1rem;*/
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.pagetitle {
  font-family: 'Roboto', sans-serif;
  color: #1f1f1f;
  font-size: 15px;
  font-weight: 600;
}

.pagesubtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}

.test {
  position: relative;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 13px;
  text-transform: none;
  border-radius: 3px;
  line-height: 20px;
  margin-left: 5px;
  color: inherit;
}

/* //Table Style */
.test:hover {
  position: relative;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 13px;
  text-transform: none;
  border-radius: 3px;
  line-height: 20px;
  margin-left: 5px;
  color: inherit;
}

.table thead th {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 600;
  border-top: 0px;
  padding-top: 0px;
  border-bottom: 0.5px solid #555555;
  padding-bottom: 15px;
}

.table tbody td {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 400;
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table .dropdownButton .dropdown-toggle {
  background: transparent;
  padding: 0;
  color: inherit;
  border-color: transparent;
}

.table .dropdownButton.show .dropdown-toggle {
  background: transparent;
  padding: 0;
  color: inherit;
  border-color: transparent;
}

.table .dropdownButton .dropdown-toggle:after {
  display: none;
}

.table .dropdownButton .dropdown-toggle:focus {
  box-shadow: unset !important;
}

.table .dropdownButton .dropdown-item {
  display: 'flex';
  padding: '7px 15px';
  align-items: 'center';
}

.table .dropdownButton .dropdown-item:active {
  background: 'red';
}

.table .dropdownButton .dropdown-toggle:active {
  background: '#581845 !important';
}

.table .dropleft .dropdown-toggle::before {
  display: none;
}

.table .dropleft .dropdown-menu {
  /* top: 0 !important; */
  right: 0 !important;
  left: auto !important;
  margin-top: 0;
  margin-right: 0.125rem;
  transform: translate(-40px, 20px) !important;
}

.table .dropdown-menu {
  border: 1px solid #d8dde4;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  background-color: #fff;
}

.table .dropdown-menu .dropdown-item {
  font-size: 14px;
  cursor: pointer;
  padding: 8px 15px;
  transition: all 0.3s;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.04); */
}

.dropdown-item:hover {
  background-color: rgb(88 24 69 / 15%) !important;
}

.table .dropdown-menu .dropdown-item:focus,
.table .dropdown-menu .dropdown-item:active {
  background-color: rgb(88 24 69 / 30%);
  color: #fff;
}

/* //Checkbox */
.custom-control-label {
  font-size: 13px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #581845 !important;
  border-color: #581845 !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  border-color: rgba(57, 16, 45, 0.25) !important;
}

.custom-checkbox .custom-control-input:checked:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(57, 16, 45, 0.25);
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-checkbox .custom-control-input:active~.custom-control-label::before {
  background-color: rgba(57, 16, 45, 0.25);
}

/* //Input */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b6b7b8;
  opacity: 1;
  /* Firefox */
  font-weight: 400;
  font-size: 13px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b6b7b8;
  opacity: 1;
  /* Firefox */
  font-weight: 400;
  font-size: 13px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b6b7b8;
  opacity: 1;
  /* Firefox */
  font-weight: 400;
  font-size: 13px;
}

input:disabled {
  color: hsl(0, 0%, 20%);
  opacity: 0.5;
  background-color: #e9e9e9;
  cursor: not-allowed
}

.label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  pointer-events: none;
  color: #111111;
  font-weight: 600;
}

.select label,
.form-group label {
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
  top: -25px;
  left: 5px;
  font-size: 13px;
  color: #111111;
  font-weight: 600;
}

.form-group {
  position: relative;
  margin-bottom: 45px;
}

.form-group input {
  font-size: 13px;
  padding: 4px 12px 3px 12px;
  display: block;
  width: 100%;
  min-width: 200px;
  border: none;
  /* border-bottom: 1px solid #757575; */
}

.form-group input:focus {
  outline: none;
  /* border-bottom: px solid #581845 !important; */
}

/* //Select */
.custom-control {
  z-index: 0;
}

.react-select__control {
  background-color: transparent !important;
  border-color: transparent !important;
}

.react-select__menu *,
.react-select__menu {
  z-index: 9999999;
}

.react-select__indicators {
  display: none !important;
}

.select {
  position: relative;
  width: 100%;
}

.select:after {
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.react-select__value-container {
  overflow: visible !important;
  min-height: 37px;
}

.react-select__control {
  position: relative !important;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  font-size: 13px;
  border-radius: 0 !important;
  border: none;
  border-bottom: 1px solid #757575 !important;
  box-shadow: unset !important;
}

.react-select__placeholder {
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  color: #b6b7b8;
  opacity: 1;
  font-weight: 400;
  font-size: 13px;
  left: 2px;
}

.react-select__single-value {
  left: 2px;
  font-size: 12px;
}

.react-tel-input .form-control {
  font-size: 13px !important;
  color: #333;
}

.react-autosuggest__input:focus,
.react-select__control--is-focused {
  border-bottom: 1px solid #581845 !important;
}

/* //Department */

.srchList {
  position: relative;
}

.srchList ul {
  z-index: 999999;
  border: 1px solid #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 5px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  list-style: none;
  position: absolute;
  width: 100%;
  background-color: #fff;
}

.srchList ul li {
  cursor: pointer;
  padding: 8px 15px;
  transition: all 0.3s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.srchList ul li:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-group .small.text-danger {
  position: relative;
  left: 5px;
}

/* Tab Style */
.nav-tabs {
  /* border-bottom: 1px solid #dfdfdf; */
  box-sizing: border-box;
  width: 100%;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #581845;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  /* box-sizing: border-box !important; */
  border-bottom: 3px solid #581845;
}

.nav-tabs .nav-link {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  margin-bottom: 0px;
  border: 0px solid transparent;
  /* font-weight: bold; */
  color: #000;
  padding: none !important;
}

/* REACT BIG CALENDAR OVERRIDE */
.rbc-event,
.rbc-selected,
.rbc-event-allday,
.rbc-background-event,
.rbc-event-continues-later {
  background-color: transparent !important;
  padding: 0 !important;
  border: none !important;
}

.rbc-event {
  border-radius: 2px !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  margin: 0 !important;
}

.rbc-time-header-cell {
  height: 100%;
}

.rbc-header .rbc-button-link {
  width: 100%;
  color: #333;
}

.rbc-event {
  border-radius: 2px !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  margin: 0 !important;
}

.rbc-time-header-cell {
  height: 100%;
}

.rbc-header .rbc-button-link {
  width: 100%;
}

.rbc-agenda-time-cell {
  border: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table,
.rbc-label,
.rbc-agenda-event-cell {
  color: #333 !important;
  font-size: 13px !important;
}

/* MUI OVERRIDE */
/* Input fields */

/* tab-content */

.tab-content {
  position: relative;
  display: flex;
  flex-direction: column;
  /* overflow: hidden !important; */
  flex: 1;
}

.ql-mention-list-container {
  width: 170px;
  border: 1px solid #f0f0f0;
}

.tab-content {
  position: relative;
  display: flex;
  flex-direction: column;
  /* overflow: hidden !important; */
  flex: 1;
}

.tab-pane {
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow: auto !important; */
  flex: 1;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #cccccc !important;
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
} */

ul {
  /* margin-bottom: 1rem !important;  // commented out it reflecting whole application*/
  list-style-type: disc !important;
}

.css-hdw1oc {
  display: none !important;
  /* height: 20px !important; */
}

.cardHo:hover {
  background-color: #ede6eb;
  box-shadow: 1px 1px 1px #ede6eb;
  border-left: 5px solid #581845;
}

.cardHo {
  border: 1px solid #ede6eb;
  width: 145px;
  box-shadow: 2px 2px 5px 1px #ede6eb;
}

.css-12jo7m5 {
  font-size: 13px;
}

*/ .dv-star-rating-empty-star {
  color: rgb(217, 217, 217) !important;
}

.bottomborder {
  border-bottom: 1px solid #ede6eb;
}

.dv-star-rating-empty-star {
  color: rgb(217, 217, 217) !important;
}

.ql-mention-list-container {
  width: 170px;

  border: 1px solid #f0f0f0;

  border-radius: 4px;

  background-color: #ffffff;

  z-index: 9001;

  overflow-y: scroll;

  max-height: 160px;
}

.ql-mention-loading {
  line-height: 44px;

  padding: 0 20px;

  vertical-align: middle;

  font-size: 16px;
}

.ql-mention-list {
  list-style: none;

  margin: 0;

  padding: 0;

  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;

  line-height: 44px;

  font-size: 13px;

  padding: 0 20px;

  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: #eee8ec;
  text-decoration: none;
  font-size: 13px;
}

.mention {
  height: 24px;

  width: 65px;

  border-radius: 10px;

  background-color: #eee8ec;

  padding: 3px 5px;

  margin-right: 2px;

  user-select: all;
}

.mention>span {
  margin: 0 3px;
}

.ql-editor {
  min-height: 70px !important;
  max-height: 300px !important;
  padding: 20px 16px !important;
  word-wrap: break-word !important;
  word-break: normal !important;
  white-space: break-spaces;
}

.ql-editor.ql-blank::before {
  font-family: 'Roboto', sans-serif !important;
}

.ql-container {
  box-sizing: border-box;

  font-family: 'Roboto', sans-serif;

  font-size: 13px;

  /* height: 100%; */

  margin: 0px;

  position: relative;

  word-wrap: break-word !important;

  word-break: normal !important;

  white-space: break-spaces;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked]>li::before {
  pointer-events: none;
}

.ql-clipboard {
  left: -100000px;

  height: 1px;

  overflow-y: hidden;

  position: absolute;

  top: 50%;

  word-wrap: break-word !important;

  word-break: normal !important;

  white-space: break-spaces;
}

.ql-clipboard p {
  margin: 0;

  padding: 0;

  word-wrap: break-word !important;

  word-break: normal !important;

  white-space: break-spaces;
}

.ql-editor {
  box-sizing: border-box;

  line-height: 1.42;

  height: 100%;

  outline: none;

  overflow-y: auto;

  padding: 12px 15px;

  tab-size: 4;

  -moz-tab-size: 4;

  text-align: left;

  white-space: pre-wrap;

  word-wrap: break-word;

  word-wrap: break-word !important;

  word-break: break-all !important;

  white-space: pre-wrap;
}

.ql-editor>* {
  cursor: text;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;

  padding: 0;

  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol>li,
.ql-editor ul>li {
  list-style-type: none;
}

.ql-editor ul>li::before {
  content: '\2022';
}

.ql-editor ul[data-checked='true'],
.ql-editor ul[data-checked='false'] {
  pointer-events: none;
}

.ql-editor ul[data-checked='true']>li *,
.ql-editor ul[data-checked='false']>li * {
  pointer-events: all;
}

.ql-editor ul[data-checked='true']>li::before,
.ql-editor ul[data-checked='false']>li::before {
  color: #777;

  cursor: pointer;

  pointer-events: all;
}

.ql-editor ul[data-checked='true']>li::before {
  content: '\2611';
}

.ql-editor ul[data-checked='false']>li::before {
  content: '\2610';
}

.ql-editor li::before {
  display: inline-block;

  white-space: nowrap;

  width: 1.2em;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;

  margin-right: 0.3em;

  text-align: right;
}

.ql-editor li.ql-direction-rtl::before {
  margin-left: 0.3em;

  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) '. ';
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  display: block;

  max-width: 100%;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: 0.75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;

  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank::before {
  color: #b6b7b8;

  opacity: 1;

  /* Firefox */

  font-weight: 400;

  font-size: 13px;

  content: attr(data-placeholder);

  font-family: 'Roboto', sans-serif !important;

  left: 15px;

  pointer-events: none;

  position: absolute;

  right: 15px;
}

.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  clear: both;

  content: '';

  display: table;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;

  border: none;

  cursor: pointer;

  display: inline-block;

  /* float: left; */

  height: 24px;

  padding: 3px 5px;

  width: 28px;

  margin: 0px 2px;
}

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  float: left;

  height: 100%;
}

.ql-snow.ql-toolbar button:active:hover,
.ql-snow .ql-toolbar button:active:hover {
  outline: none;
}

.ql-snow.ql-toolbar input.ql-image[type='file'],
.ql-snow .ql-toolbar input.ql-image[type='file'] {
  display: none;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #581845;
  background-color: gainsboro;
  margin: 0px 2px;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #581845;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #581845;
}

@media (pointer: coarse) {

  .ql-snow.ql-toolbar button:hover:not(.ql-active),
  .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #581845;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #581845;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #581845;
  }
}

.ql-snow {
  box-sizing: border-box;
}

.ql-snow * {
  box-sizing: border-box;
}

.ql-snow .ql-hidden {
  display: none;
}

.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
  visibility: hidden;
}

.ql-snow .ql-tooltip {
  position: absolute;

  transform: translateY(10px);
}

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
  text-decoration-color: #333333;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  display: inline-block;

  vertical-align: middle;
}

.ql-snow .ql-formats:after {
  clear: both;

  content: '';

  display: table;
}

.ql-snow .ql-stroke {
  fill: none;

  stroke: #444;

  stroke-linecap: round;

  stroke-linejoin: round;

  stroke-width: 2;
}

.ql-snow .ql-stroke-miter {
  fill: none;

  stroke: #444;

  stroke-miterlimit: 10;

  stroke-width: 2;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #444;
}

.ql-snow .ql-empty {
  fill: none;
}

.ql-snow .ql-even {
  fill-rule: evenodd;
}

.ql-snow .ql-thin,
.ql-snow .ql-stroke.ql-thin {
  stroke-width: 1;
}

.ql-snow .ql-transparent {
  opacity: 0.4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-snow .ql-editor p {
  font-size: 13px;
}

.ql-snow .ql-editor h1 {
  font-size: 38px;
  font-weight: 600;
}

.ql-snow .ql-editor h2 {
  font-size: 32px;
  font-weight: 600;
}

.ql-snow .ql-editor h3 {
  font-size: 26px;
  font-weight: 600;
}

.ql-snow .ql-editor h4 {
  font-size: 20px;
  font-weight: 600;
}

.ql-snow .ql-editor h5 {
  font-size: 16px;
  font-weight: 600;
}

.ql-snow .ql-editor h6 {
  font-size: 14px;
  font-weight: 600;
}

.ql-snow .ql-editor a {
  text-decoration-color: #000000;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;

  margin-bottom: 5px;

  margin-top: 5px;

  padding-left: 16px;
}

.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: #f0f0f0;

  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  white-space: pre-wrap;

  margin-bottom: 5px;

  margin-top: 5px;

  padding: 5px 10px;
}

.ql-snow .ql-editor code {
  font-size: 85%;

  padding: 2px 4px;
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f;

  color: #f8f8f2;

  overflow: visible;
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.ql-snow .ql-picker {
  color: #444;

  display: inline-block;

  /* float: left; */

  font-size: 14px;

  font-weight: 500;

  height: 24px;

  position: relative;

  vertical-align: middle;
}

.ql-snow .ql-picker-label {
  cursor: pointer;

  display: inline-block;

  height: 100%;

  padding-left: 8px;

  padding-right: 2px;

  position: relative;

  width: 100%;
}

.ql-snow .ql-picker-label::before {
  display: inline-block;

  line-height: 22px;
}

.ql-snow .ql-picker-options {
  background-color: #fff;

  display: none;

  min-width: 100%;

  padding: 4px 8px;

  position: absolute;

  white-space: nowrap;
}

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;

  display: block;

  padding-bottom: 5px;

  padding-top: 5px;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;

  z-index: 2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;

  margin-top: -1px;

  top: 100%;

  z-index: 1;
}

.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
  width: 28px;
}

.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0px;
}

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;

  width: 24px;

  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  padding: 3px 5px;

  width: 152px;
}

.ql-snow .ql-color-picker .ql-picker-item {
  border: 1px solid transparent;

  float: left;

  height: 16px;

  margin: 2px;

  padding: 0px;

  width: 16px;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: absolute;

  margin-top: -9px;

  right: 0;

  top: 50%;

  width: 18px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: 'Normal';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: 'Heading 1';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: 'Heading 2';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: 'Heading 3';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: 'Heading 4';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: 'Heading 5';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: 'Heading 6';
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  font-size: 2em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  font-size: 1.5em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  font-size: 1.17em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  font-size: 1em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  font-size: 0.83em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  font-size: 0.67em;
}

.ql-snow .ql-picker.ql-font {
  width: 108px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'Sans Serif';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: 'Serif';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: 'Monospace';
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  font-family: Monaco, Courier New, monospace;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: 'Normal';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  content: 'Small';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  content: 'Large';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  content: 'Huge';
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  font-size: 32px;
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent;
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid transparent;

  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #ccc;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-top: 0px;
}

.ql-snow .ql-tooltip {
  background-color: #fff;

  border: 1px solid #ccc;

  box-shadow: 0px 0px 5px #ddd;

  color: #444;

  padding: 5px 12px;

  white-space: nowrap;
  left: 50% !important;
  transform: translateX(-50%) translateY(10px);
}

.ql-snow .ql-tooltip::before {
  content: 'Visit URL:';

  line-height: 26px;

  margin-right: 8px;
}

.ql-snow .ql-tooltip input[type='text'] {
  display: none;

  border: 1px solid #ccc;

  font-size: 13px;

  height: 26px;

  margin: 0px;

  padding: 3px 5px;

  width: 170px;
}

.ql-snow .ql-tooltip a.ql-preview {
  display: inline-block;

  max-width: 200px;

  overflow-x: hidden;

  text-overflow: ellipsis;

  vertical-align: top;
}

.ql-snow .ql-tooltip a.ql-action::after {
  border-right: 1px solid #ccc;

  content: 'Edit';

  margin-left: 16px;

  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Remove';

  margin-left: 8px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview,
.ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type='text'] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;

  content: 'Save';

  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'Enter link:';
}

.ql-snow .ql-tooltip[data-mode='formula']::before {
  content: 'Enter formula:';
}

.ql-snow .ql-tooltip[data-mode='video']::before {
  content: 'Enter video:';
}

.ql-snow a {
  color: #581845;
  font-weight: 600;
}

.ql-snow u {
  color: initial;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
  border-radius: 0px 0px 4px 4px;
}

.rdp-nav_button {
  cursor: pointer !important;
}

.rdp-day {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  margin: 5px !important;
  border: 2px solid transparent;
  border-radius: 100%;
  /* cursor : default !important; */
}

.rdp-head_cell {
  vertical-align: middle;
  font-size: 0.75em;
  font-weight: 700;
  text-align: center;
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 20px;
  text-transform: uppercase;
}

.rdp-caption_label {
  font-size: 14px !important;
  padding: 0 !important;
  color: #333 !important;
  margin-bottom: 10px;
}

.rdp-cell {
  color: #333 !important;
}

.rdp {
  margin: 10px 0px 10px 0px !important;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: white !important;
  cursor: default;
}

/* .rdp-button.rdp-day_selected {
  background-color: #d7c7d2 !important;
   color: #581845 !important; 
   padding: 5px !important;
} */
/* .rdp-button.rdp-day_selected
.DayPicker-Day--lastSelected {
  background-color: black;
  color: white;
  color: #581845; 
  /* font-weight: 54px; */
/* This is not a valid font-weight value */
/* } */
*/ .rdp-button.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  color: white;
  opacity: 1;
  color: #581845 !important;
  padding: 5px !important;
  background-color: #d7c7d2 !important;
}

.rdp-button.rdp-day_selected:focus {
  background-color: #581858 !important;
  color: #fffcff !important;
  padding: 5px !important;
}

.rdp-button.rdp-day_highlighted {
  background-color: #17692e !important;
  color: #fffcff !important;
  padding: 5px !important;
}

.custom-highlighted {
  background-color: black;
  color: white;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 20px !important;
  color: #581845 !important;
}

.rdp-day_today:not(.rdp-day_outside) {
  color: black;
}

.rdp-caption {
  margin-left: 15px;
  margin-bottom: 10px;
}

.rbc-event-content {
  font-size: 13px !important;
  color: #333 !important;
}

.rbc-show-more {
  color: #581845 !important;
}

.daterangepicker td.in-range {
  background-color: #d7c7d2 !important;
}

.daterangepicker td.active {
  background-color: #581845 !important;
}

.cancelBtn.btn.btn-sm.btn-default {
  background-color: #888888;
  color: #fff;
  /* text-transform: capitalize; */
}

.daterangepicker .drp-buttons .btn {
  text-transform: capitalize;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td {
  cursor: pointer !important;
}

.daterangepicker td.disabled {
  cursor: not-allowed !important;
}

.daterangepicker th.month {
  cursor: default !important;
}

.legend {
  display: none;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px 10px !important;
  height: 1.7em !important;
  font-size: 13px !important;
  color: #333 !important;
  border-width: 1px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #d7c7d2 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #58184570 !important;
}

.css-9vzr9t-control {
  border-color: #d7c7d2 !important;
}

.css-9vzr9t-control:hover {
  border-color: #58184570 !important;
}

.createScheduleForm_duration__3Jplh p:nth-of-type(even) {
  border-color: #d7c7d2 !important;
}

.createScheduleForm_duration__3Jplh p:nth-of-type(even):hover {
  border-color: #58184570 !important;
}

.css-hkb84n-MenuList {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.css-92nr1f-MuiButtonBase-root-MuiIconButton-root,
.css-1s220mh-MuiButtonBase-root-MuiIconButton-root,
.css-12ha4i7,
.css-7lip4c,
.css-umzx0k-MuiClock-pin,
.css-rdq5h4-MuiClockPointer-root,
.css-eg3pzz-MuiClockPointer-thumb,
.css-kjd0cg-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton {
  background-color: #581845 !important;
}

.rbc-month-view {
  z-index: 0;
}

.css-2ujp1m,
.css-118whkv {
  border-color: #581845 !important;
  background-color: unset !important;
}

.css-a1rc6s {
  border-color: #581845 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #d7c7d2;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover {
  border: 1px solid #58184550;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus-visible,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus-within {
  border: 1px solid #581845;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display: none;
}

.createScheduleForm_duration__3Jplh p:nth-of-type(even) {
  height: 30px !important;
}

.rbc-agenda-empty {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
}

.css-1bn53lx {
  border: 1px solid #d7c7d2;
  font-size: 13px !important;
  color: #333 !important;
}

.css-1bn53lx:hover {
  border: 1px solid #58184550;
}

.css-1bn53lx:focus-visible,
.css-1bn53lx:focus-within {
  border: 1px solid #581845;
}

.css-1uvydh2 {
  padding: 5px 8px !important;
  /* padding: 5px 8px !important; */

  font-size: 13px !important;

  color: #333 !important;
}

.css-igs3ac {
  border: none !important;
}

.css-slyssw {
  color: #581845 !important;
}

.css-vubbuv {
  font-size: 16px !important;
}

.css-a1rc6s,
.css-cysikj,
.css-ag8lru,
.css-wed0tz.Mui-selected,
.css-118whkv,
.css-7lip4c,
.css-12ha4i7 {
  background-color: #581845 !important;
}

.css-ub1r1.Mui-selected,
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #581845 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #581845 !important;
}

/* .css-1in441m{
  display: none !important;
}
/* .daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
  cursor: not-allowed;
} */

/* .react-datepicker__day--selected{
  background-color: #581845 !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: transparent !important;
  color: black;
} */
/* legend {
  display: none !important;
}

fieldset {
  top: 0 !important;
} */
.pg-viewer-wrapper {
  width: fit-content;
}

.pg-viewer-wrapper .document-container {
  padding: 20px !important;
  /* max-width: 800px !important; Set your desired maximum width */
  width: 100% !important;
  /* This allows it to be responsive up to the max-width */
  background: #fff;
  margin: auto;
}

.loading-container .loading {
  display: none !important;
}

.bs-popover-bottom .arrow::after {
  display: none;
}

.bs-popover-bottom .arrow::before {
  display: none;
}

.bs-popover-bottom {
  max-width: 400px;
  text-align: justify;
}

/* .css-b62m3t-container{
  z-index: 3;
} */
/* search for jobworkflow and database search  */
.css-1wme7sg-menu {
  z-index: 98 !important;
}

.css-1elcgmh-menu {
  z-index: 98 !important;
}

.css-1gya76g-SingleValue {
  text-wrap: nowrap;
}

.react-tooltip-lite {
  /* Your custom styles here */
  background-color: #757274 !important;
  color: #fff;
  border-radius: 5px !important;
  padding: 4px 4px !important;
  font-size: 12px !important;
  max-width: 300px !important;
  word-wrap: break-word !important;
  /* This property ensures text wrapping */
}

/* _______________date picker customize_______________ */
.rmdp-input:focus {
  border: #481845 !important;
  width: 115px !important;
}

.rmdp-input {
  height: 30px !important;
  width: 115px !important;
}

.rmdp-ep-arrow {
  display: none;
}
.rmdp-day.rmdp-today span{
  background-color: WHITE !important;
  font-weight: bold;
  color: #000 !important;
  box-shadow: none !important;
}
.rmdp-day.rmdp-selected span:not(.highlight){
  background-color: #481845 !important;
  border-radius:50% !important;
  color: white !important; 
}
@media (hover: hover) {
  .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
      background-color: #EEE8EC !important;
      color: #000 !important;
      border-radius: 50% !important;
  }
}
.rmdp-week-day{
  color: #000 !important;
}
.rmdp-arrow {
  border: solid #666666 !important;
  border-width: 0 2px 2px 0 !important;
}
.rmdp-arrow-container:hover{
background-color: white !important; 
box-shadow: none !important;
}
.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid #666666 !important;
  border-width: 0 2px 2px 0 !important;
}
.rmdp-day span {
  display: flex;
  align-items: center;
}
/* _______________date picker customize end_______________ */