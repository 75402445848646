 
.search {
  border-radius: 4px; 
  border: 1px solid  #D7C7D2;
  outline: none;
  font-size: 13px !important;
  background-color: var(--color-white, #ffffff);
  width: 100%;
  box-sizing: border-box !important;
  font-family: "Roboto", sans-serif;
  height:30px;
  text-overflow: ellipsis;
  /* text-transform: capitalize; */
  
}
.search::placeholder{
  text-transform: inherit !important
  
}
.search:hover {
  border: 1px solid #58184570;
}
.search:focus {
  border: 1px solid var(--color-primary, #581845);
}
.search::placeholder {
  font-size: 13px;
  color: var(--color-gary-6, #888888);
}
.search::-webkit-input-placeholder{
  font-size: 13px !important;
}

.errorBorder {
  border: 1px solid var(--color-error, #f94949);
}
.errorBorder:hover {
  border: 1px solid var(--color-error, #f94949);
}
.errorBorder:focus {
  border: 1px solid #d74242;
}
.errorFocus {
  color: var(--color-error-1, #d74242);
}

.noOptionsDivStyle{
  background-color: #fff;
  height: 36px;
  border: 1px solid #D7C7D2;
  position: absolute;
  width: 100%;
  font-size: 13px !important;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 11;
}

