.descriptionpopup{
    width: 650px;
    background: white;
    padding: 25px;
    max-height: 800px;
    /* height: 600px; */
    border-radius: 4px;
  }
  
.popupheading{
    justify-content: space-between;
    margin-bottom: 5px;
    padding-bottom: 5px;
 }
.deletepopup{
    padding: 25px;
    background-color: white;
}
  
.delBtnContainer{
    justify-content: flex-end;
    margin-top:16px;
}
.templateHubEmailContent p{
    margin: 0;
}
.templateHubEmailContent p a{
    color: #333333 !important;; 
}
.templateHubEmailContent p br{
    display: none;
}
.templateHubEmailContent div a{
    color: #333333 !important;; 
}
