/* .overAll {
  /* margin: 0 10%; */
  /* margin-bottom: 20px; */

.btnStyle {
  margin-left: 16px;
}
.titleContainer {
  padding-bottom: 16px;
}
.filterOverAll {
  margin-right: 16px;
  padding-top: 20px;
  /* margin-left: 10%; */
}
.scollDiv {
  /* overflow-y: scroll; */
  padding-top: 20px;
  width: 100%;
  /* padding-right: 10%; */
  /* padding-bottom: 30px; */
}
.scollDivTab {
  padding-right: 20px;
}
.alignCenter {
  height: 100%;
}
.pagination {
  padding-top: 5px;
  /* padding-bottom: 10px; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.scrollBottom {
  margin-right: 2px;
}

.overAll {
  /* margin: auto;
  max-width: 1300px; */
}

@media screen and (min-width: 1280px) and (max-width: 1350px) {
  .overAll {
    padding: 0px 5% !important;
  }
}
@media screen and (min-width: 960px) and (max-width: 1280px) {
  .overAll {
    padding: 0px 3% !important;
  }
}

@media screen and (max-width: 960px) {
  .overAll {
    padding: 0 16px;
  }
}
.titleContainer {
  padding: 8px 10px 11px 10px;
  background-color: #eee8ec;
  font-size: 12px;
  margin-bottom: 10px;
  display: flex;
  color: #581845;
  justify-content: space-between;
  position: relative;
  padding-right: 40px;
  height: 40px;
}
.triangle {
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-right: 30px solid white;
  border-bottom: 17px solid transparent;
  position: absolute;
  top: -1px;
  right: 0;
}
.searchstyle {
  /* width: 412.27px; */
  display: flex;
  align-items: center;
}
.searchboxoverall {
  border-radius: 0px 20px 20px 0px;
  background-color: #f7f7f7;
  height: 30px !important;
}
.jobstext {
  height: 30px;
  padding: 10px;
  background-color: #eee8ec;
  display: flex;
  align-items: center;
  color: #581845;
}
.locationicon {
  margin: 5px 0 5px 0;
}
.crossIcon {
  all: unset;
  cursor: pointer;
  padding-top: 6px;
  
}
.searchicons {
  height: 30px;
  padding: 10px;
  background-color: #581845;
  border-radius: 0px 20px 20px 0px;
  cursor: default;
}
.boxstyle {
  text-transform: none !important;
  background-color: #f7f7f7;
  height: 30px;
  border: none !important;
  border-radius: 0 !important;
  scroll-behavior: smooth;
  width: 230px;
  text-overflow: ellipsis;
}
.searchstyle {
  /* width: 412.27px; */
  display: flex;
  align-items: center;
}
.middleline {
  margin-right: 10px;
  height: 25px;
  border: 1px solid #e4dae1;
  margin-top: 2px;
}
.cards{
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.nameFlex {
  padding-right: 8px;
  width: 100%;
  margin-left:-2px;
}
/* .pagination {
  margin: 1% 38%;
}

@media only screen and (max-width: 1366px) {
  .pagination{
    margin: 1% 35%;
  }
}

@media only screen and (min-width: 1366px) and (max-width:1920px) {
  .pagination{
    margin: 1% 35%;
  }
}

@media screen and (min-width: 1920px) {
  .pagination{
    margin: 1% 38%;
  }
} */
.updateBtnLoader{
  margin-right: 10px;
  margin-bottom: 4px;
}
.process{
  color: #979797;
}

.overAllloader {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px 24px;
}
.des {
  max-width: 575px;
  margin-top: 8px;
}
.flexConatiner {
  margin-left: 9px;
  margin-top: 4px;
}
.btn {
  margin-top: 8px;
}
.des {
  /* margin-left: 40px; */
}
