.overAll {
  position: absolute;
  width: 450px;
  background-color: var(--color-white, #ffffff);
  right: 0px;
  border-radius: 4px;
  z-index: 99;
}

.overAllOne {
  position: absolute;
  width: 430px;
  background-color: var(--color-white, #ffffff);
  right: 0px;
  border-radius: 4px;
  z-index: 99;
  height: 405px;
}

.scrollStyle {
  max-height: 300px;
  overflow-y: scroll;
  padding-bottom: 8px;
}

.headerStyle {
  padding: 3px 15px;
  margin-top: 5px;
}

.listStyle {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.readStyle {
  width: 8px;
  height: 8px;
  background-color: #581845 !important;
  border-radius: 100%;
  margin-top: 6.8px;
  margin-left: 12px;
}

.hoverStyle {
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  padding-right: 10px;
  display: flex;
  align-items: flex-start;
}

.hoverStyle:hover {
  background-color: rgb(88 24 69 / 15%);
}

.hr_line {
  margin-top: 0.1rem !important;
  margin-bottom: 0.1rem !important;
  margin-right: 10px !important;
  border: 0 !important;
  margin-left: 15px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.hr_lineforbutton {
  margin-top: 0.1rem !important;
  margin-bottom: 0.5rem !important;
  margin-right: 10px !important;
  border: 0 !important;
  margin-left: 15px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.line {
  margin-top: 0rem !important;
  margin-bottom: 0.5rem !important;
  margin-right: 10px !important;
  border: 0 !important;
  margin-left: 15px !important;
  border-top: 0.5px solid #581845 !important;
}

.countStyle {
  position: absolute;
  right: -2px;
  top: -1px;
  background-color: var(--color-error, #f94949);
  border-radius: 100%;
  z-index: 11;
  display: flex;
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
}

.model {
  background-color: #ffffff;
  border-radius: 10px;
  width: 400px;
  /* position: absolute; */
  padding: 20px;
  top: 150px;
  right: 102px;
}

.hoverback:hover {
  /* background-color: #d7c7d2; */

  border-radius: 100%;
}

.model_open {
  float: right;
  margin-bottom: 10px;
  margin-right: 10px;
}

.Btn_clear {
  margin-right: 10px;
  /* padding-right: 19px;
 padding-left: 19px; */
}

.confirm_txt {
  /* z-index: -1; */
  text-align: left;
  font-size: 13px;
  color: #333333;
}

.confirm_title {
  color: #333333;
  text-align: left;
  font-size: 13px;
}

/*sidebar*/

.sidebar {
  margin-top: 77px;
  height: 100%;
  width: 195px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow: hidden;
  padding-top: 16px;
  box-shadow: 1px 2px 2px #d7c7d2;
  /* border-top-right-radius: 0px; */
}

.sidemenucontent ul {
  list-style-type: none !important;
}

.sidemenucontent {
  overflow-x: hidden;
  overflow-y: scroll;
  /* scrollbar-width: thin; */
  /* scrollbar-color: #888 transparent; */
  /* height:85% */
  height: 83%;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar {
  width: 3px;
}

.sidemenucontentmini {
  overflow-x: hidden;
  /* overflow-y: scroll; */
  /* scrollbar-width: thin; */
  /* scrollbar-color: #888 transparent; */
  height: 80%;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar {
  width: 3px;
}

.sidebar a {
  padding: 1px 0px 1px 10px;
  text-decoration: none;
  font-size: 20px;
  color: #581845;
  display: block;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  border-left: 6px solid #ffffff !important;
  transition: width 0.3s ease;
  border-radius: 5px;
}

.supportBtn {
  padding: 1px 0px 1px 10px;
  text-decoration: none;
  font-size: 20px;
  color: #581845;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  border-left: 6px solid #ffffff !important;
  transition: width 0.3s ease;
  width: 95%;
  border-radius: 5px;
  display: block;
  font-size: 19px;
}

.supportBtn:hover {
  color: #581845;
  background-color: #eee8ec;
  border-radius: 5px 5px 5px 5px;
  border-left: 6px solid #581845 !important;
}

.text {
  margin-left: 12px;
  position: relative;
  bottom: 2px;
}

.textsetting {
  margin-left: 12px;
  position: relative;
  bottom: -2px;
}

.maintext {
  margin-left: 13px;
  position: relative;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  cursor: pointer;
}

.classpan {
  display: none;
}

.select_row .hoverview {
  color: #581845;
  background-color: #eee8ec;
  border-radius: 5px 5px 5px 5px;
  border-left: 6px solid #581845 !important;
}

.select_row .subhoverview {
  color: #581845;
  background-color: #eee8ec;
  border-radius: 5px 5px 5px 5px;
}

.selectpopup_row .hoverview {
  color: #581845;
  background-color: #eee8ec;
  border-radius: 0px 5px 5px 0px;
}

.overviewpopupdropdown .hoverview:hover {
  border-radius: 0px 5px 5px 0px;
  color: #581845;
  background-color: #eee8ec;
  width: 100%;
}

.jobspopupdropdown .hoverview:hover {
  border-radius: 0px 5px 5px 0px;
  color: #581845;
  background-color: #eee8ec;
  width: 100%;
}

.viewjobspopupdropdown .hoverview:hover {
  border-radius: 0px 5px 5px 0px;
  color: #581845;
  background-color: #eee8ec;
  width: 100%;
}

.candipopupdropdown .hoverview:hover {
  border-radius: 0px 5px 5px 0px;
  color: #581845;
  background-color: #eee8ec;
  width: 100%;
}

.commpopupdropdown .hoverview:hover {
  border-radius: 0px 5px 5px 0px;
  color: #581845;
  background-color: #eee8ec;
  width: 100%;
}

.setuppopupdropdown .hoverview:hover {
  border-radius: 0px 5px 5px 0px;
  color: #581845;
  background-color: #eee8ec;
  width: 100%;
}

.brandpopupdropdown .hoverview:hover {
  border-radius: 0px 5px 5px 0px;
  color: #581845;
  background-color: #eee8ec;
  width: 100%;
}

.myaccpopupdropdown .hoverview:hover {
  border-radius: 0px 5px 5px 0px;
  color: #581845;
  background-color: #eee8ec;
  width: 100%;
}

.selectitem.hoverview {
  border-radius: 5px 5px 5px 5px;
  color: #581845;
  background-color: #eee8ec;
  cursor: not-allowed;
}

.selectitem {
  cursor: not-allowed;
}

.sidebar .hoverview:hover {
  border-radius: 5px 5px 5px 5px;
  color: #581845;
  background-color: #eee8ec;
  border-left: 6px solid #581845 !important;
  transition: width 0.3s ease;
}

.sidebar .subhoverview:hover {
  border-radius: 5px 5px 5px 5px;
  color: #581845;
  background-color: #eee8ec;
}

.sidebarmini {
  border-radius: 5px 5px 5px 5px;
  margin-top: 77px;
  height: 100vh;
  width: 50px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 16px;
  margin-left: 2px;
  box-shadow: 1px 2px 2px #d7c7d2;
  transform: scale(1);
}

.sidebarmini a {
  border-radius: 5px 5px 5px 5px;
  padding: 1px 10px 1px 10px;
  text-decoration: none;
  font-size: 20px;
  color: #581845;
  display: block;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  border-left: 6px solid #ffffff !important;
}

.sidebarmini .hoverview:hover {
  color: #581845;
  background-color: #eee8ec;
  border-left: 6px solid #581845 !important;
}

.sidebar .setting {
  margin-bottom: 7px !important;
}

.sidebarmini .setting {
  position: absolute;
  bottom: 57px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.sidebarexpand {
  /* height: 35px;
  width: 145px;
  position: relative;
  bottom: 55px; */

  height: 35px;
  width: 166px;
  position: relative;
  bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sidebarmin {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* left: 3px;
  position: relative; */
}

.collapse {
  margin-left: 170px;
}

.Expend {
  /* padding: 1px 10px 1px 10px; */
  text-decoration: none;
  font-size: 20px;
  color: #581845;
  display: block;
  margin-bottom: 4px;
  margin-left: 3px;
  /* margin-right: 4px; */
  /* margin-left: 6px; */
  /* border-left: 6px solid #ffffff !important; */
}

/* .li >text{
font-family: 'Courier New', Courier, monospace;
} */
/* .svg{
  vertical-align:text-top;
} */

.backgroundcollapse {
  background-color: #eee8ec;
}

.backgroundcollapse:hover {
  background-color: #eee8ec;
}

.fixedlist {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  /* Add your preferred background color */
  padding: 10px;
  margin: 0;
  list-style: none;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  /* Add a shadow if desired */
}

.drop_down_menus {
  background-color: #fff;
  position: absolute;
  background-color: var(--color-white, #fff);
  border-radius: 12px;
  max-height: 450px;
  overflow-y: scroll;
  width: 230px;
  display: none;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47000000000000003);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47);
}

.show {
  display: block;
}

.filtername {
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  border-left: 6px solid #ffffff !important;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
}

.filternamemini {
  border-radius: 5px 5px 5px 5px;
  padding: 1px 10px 1px 10px;
  text-decoration: none;
  font-size: 20px;
  color: #581845;
  display: block;
  margin-bottom: 4px;
  margin-left: 4px;
  border-left: 6px solid #ffffff !important;
}

.nameHoverview {
  color: #581845;
  background-color: #eee8ec;
  border-radius: 5px 5px 5px 5px;
  border-left: 6px solid #581845 !important;
}

.filtername:hover {
  color: #581845;
  background-color: #eee8ec;
  border-left: 6px solid #581845 !important;
}

.filternamemini:hover {
  color: #581845;
  background-color: #eee8ec;
  border-left: 6px solid #581845 !important;
}

.filterselect_row {
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  border-left: 6px solid #ffffff !important;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  color: #581845;
  background-color: #eee8ec;
  border-radius: 5px 5px 5px 5px;
  border-left: 6px solid #581845 !important;
}

.overviewpopupdropdown {
  background-color: #ffffff;
  position: absolute;
  z-index: 3;
  left: 38px;
  list-style-type: none;
  border-radius: 5px;
  top: 5px;
  width: 170px;
  box-shadow: 1px 2px 2px #d7c7d2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 20px;
}

.jobspopupdropdown {
  background-color: #ffffff;
  position: absolute;
  z-index: 3;
  left: 38px;
  list-style-type: none;
  border-radius: 5px;
  top: 78px;
  width: 170px;
  box-shadow: 1px 2px 2px #d7c7d2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 20px;
}

.viewjobspopupdropdown {
  background-color: #ffffff;
  position: absolute;
  z-index: 3;
  left: 38px;
  list-style-type: none;
  border-radius: 5px;
  top: 114px;
  width: 170px;
  box-shadow: 1px 2px 2px #d7c7d2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 20px;
}

.candipopupdropdown {
  background-color: #ffffff;
  position: absolute;
  z-index: 3;
  left: 38px;
  list-style-type: none;
  border-radius: 5px;
  top: 148px;
  width: 170px;
  box-shadow: 1px 2px 2px #d7c7d2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 20px;
}

.sourcepopupdropdown {
  background-color: #ffffff;
  position: absolute;
  z-index: 3;
  left: 38px;
  list-style-type: none;
  border-radius: 5px;
  top: 186px;
  width: 170px;
  box-shadow: 1px 2px 2px #d7c7d2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 20px;
}

.commpopupdropdown {
  background-color: #ffffff;
  position: absolute;
  z-index: 3;
  left: 38px;
  list-style-type: none;
  border-radius: 5px;
  top: 222px;
  width: 170px;
  box-shadow: 1px 2px 2px #d7c7d2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 20px;
}

.setuppopupdropdown {
  background-color: #ffffff;
  position: absolute;
  z-index: 3;
  left: 38px;
  list-style-type: none;
  border-radius: 5px;
  top: 153px;
  width: 170px;
  box-shadow: 1px 2px 2px #d7c7d2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 20px;
}

.myaccpopupdropdown {
  background-color: #ffffff;
  position: absolute;
  z-index: 3;
  left: 38px;
  list-style-type: none;
  border-radius: 5px;
  top: 294px;
  width: 170px;
  box-shadow: 1px 2px 2px #d7c7d2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 20px;
}

.brandpopupdropdown {
  background-color: #ffffff;
  position: absolute;
  z-index: 3;
  left: 38px;
  list-style-type: none;
  border-radius: 5px;
  top: 258px;
  width: 170px;
  box-shadow: 1px 2px 2px #d7c7d2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 20px;
}

.wordContainer {
  cursor: pointer;
  max-width: 69%;
  word-break: break-word;
  hyphens: auto;
}

.select_row .newhoverview {
  color: #581845;
  background-color: #eee8ec;
  border-radius: 5px 5px 5px 5px;
}

.liflex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.overviewdropdownlinesuperadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.overviewdropdownlineadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.overviewdropdownlinehr {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.overviewdropdownlinehire {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.jobsdropdownlinesuperadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.jobsdropdownlinehire {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.jobsdropdownlinehire1 {
  border-left: 2px solid rgb(238, 232, 236);
  height: 35px;
}

.jobsdropdownlinehr {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.jobsdropdownlinehadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.viewjobsdropdownlinesuperadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.viewjobsdropdownlinehire {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.viewjobsdropdownlinehire1 {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.viewjobsdropdownlinehr {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.viewjobsdropdownlinehadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.candidatesdropdownlinesuperadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
}

.candidatesdropdownlineadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
}

.candidatesdropdownlinehire {
  border-left: 2px solid rgb(238, 232, 236);
  height: 35px;
}

.candidatesdropdownlinehire2 {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.candidatesdropdownlinehire3 {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
}

.candidatesdropdownlinehire4 {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
}

.candidatesdropdownlinehr {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
}

.sourcingdropdownlinesuperadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
}

.sourcingdropdownlineadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
}

.sourcingdropdownlinehr {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
}

.sourcingdropdownlinehire {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
}

.sourcingdropdownlinehire2 {
  border-left: 2px solid rgb(238, 232, 236);
  height: 35px;
}

.communicationdropdownlinesuperadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 140px;
}

.communicationdropdownlineadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 140px;
}

.communicationdropdownlinehire {
  border-left: 2px solid rgb(238, 232, 236);
  height: 140px;
}

.communicationdropdownlinehr {
  border-left: 2px solid rgb(238, 232, 236);
  height: 140px;
}

.branddropdownlinesuperadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.branddropdownlineadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.branddropdownlinehire {
  border-left: 2px solid rgb(238, 232, 236);
  height: 35px;
}

.branddropdownlinehr {
  border-left: 2px solid rgb(238, 232, 236);
  height: 35px;
}

.branddropdownlinehr2 {
  border-left: 2px solid rgb(238, 232, 236);
  height: 68px;
}

.myaccountdropdownlinesuperadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 104px;
  margin-left: 8px;
}

.myaccountdropdownlineadmin {
  border-left: 2px solid rgb(238, 232, 236);
  height: 35px;
  margin-left: 8px;
}

.myaccountdropdownlinehire {
  border-left: 2px solid rgb(238, 232, 236);
  height: 35px;
  margin-left: 8px;
}

.myaccountdropdownlinehr {
  border-left: 2px solid rgb(238, 232, 236);
  height: 35px;
  margin-left: 8px;
}

.help {
  position: absolute;
  width: 200px;
  background-color: var(--color-white, #ffffff);
  right: 0px;
  z-index: 99;
  margin-top: 5px;
  box-shadow: 0px 0px 8px 1px #d7c7d2;
  border-radius: 10px !important;
}

.card_data {
  padding: 10px;
  cursor: pointer;
  /* border-radius: 5px; */
}

.card_data:hover {
  background-color: #d7c7d2;
}

.doc {
  padding: 1px 0px 1px 10px;
  text-decoration: none;
  font-size: 20px;
  color: #581845;
  display: block;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  border-left: 6px solid #ffffff !important;
  transition: width 0.3s ease;
  width: 95%;
  border-radius: 5px;
}

.doc :hover {
  color: #581845;
  background-color: #eee8ec;
  border-radius: 5px 5px 5px 5px;
}

.makeversioncenter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #581845;
  padding-top: 10px;
  padding-bottom: 11px;
}

.removepadding {
  padding: 0px !important;
  margin-left: 0px !important;
}

.paddingimport {
  padding: 1px 10px 1px 10px;
  margin-left: 4px;
  margin-bottom: 10px;
}

.linestyle {
  border-top: 0.5px solid #581845;
  width: 183px;
}

.makepoation {
  position: relative;
  bottom: 22px;
}

.freetrailBanner {
  padding: 5px 10px;
  background: #2e6add20;
  /* margin: 0px 5px; */
  border-radius: 5px;
  border: 1px solid #2e6add;
  border-left: 8px solid #2e6add;
}

.paddingimport1 {
  padding: 1px 10px 1px 10px;
  margin-left: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.sidebarexpand1 {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.settingsButtton {
  padding: 0px !important;
  margin: 0px 0px 0px -6px !important;
  border-radius: 0px !important;
  font-size: 13px !important;
}

.settingsButtton a {
  border-left: none !important;
}