.commonStyle{
  cursor: auto;
}
.flexAlign-center {
  align-items: center;
}
.reverse {
  flex-direction: row-reverse;
}
.column {
  flex-direction: column;
  display: grid;
  height: fit-content;
}
.columnFlex {
  flex-direction: column;
  display: flex;
}
.row {
  flex-direction: row;
  display: flex;
}

.columnReverse {
  flex-direction: column-reverse;
}

.start {
  justify-content: flex-start;
}

.center {
  align-items: center;
}

.end {
  justify-content: flex-end;
}

.around {
  justify-content: space-around;
}

.between {
  justify-content: space-between;
}

.top {
  align-items: flex-start;
}

.middle {
  justify-content: center;
}

.bottom {
  align-self: self-end;
  /* align-items: flex-end; */
}
.wrap {
  flex-wrap: wrap;
}

.noWrap {
  flex-wrap: no-wrap;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
