.buttonContainer {
  width: 25px;
  height: 25px;
  background-color: #a80006;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2px;
}

.buttonContainer:hover {
  background-color: #850307;
}

.buttonContainer:hover span {
  background-color: white;
}

.spanContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.spanOne {
  width: 70%;
  height: 2px;
  background-color: #ddd;
  display: inline-block;
  position: absolute;
  left: 15%;
  top: calc(50% - 1px);
  transform-origin: center center;
  transform: rotate(45deg);
}

.spanTwo {
  width: 70%;
  height: 2px;
  background-color: #ddd;
  display: inline-block;
  position: absolute;
  left: 15%;
  top: calc(50% - 1px);
  transform-origin: center center;
  transform: rotate(-45deg);
}

.svgWrapper {
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  padding: 5px;
  border-radius: 100%;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.svgWrapper:hover {
  /* background-color: #D7C7D2; */
  border-radius: 50%;
}