.cardStructure {
  width: 350px;
  /* max-height: 160px; */
  /* margin-right: 20px; */
  /* padding: 20px 20px 0 20px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

}

.pipelineStructure {
  position: relative;
  max-height: 160px;
  width: 21%;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cardHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center ;
  cursor: pointer;
}

.rowGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.titleText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* max-width: 105px; */
}

.btn,
.btn2 {
  background-color: transparent;
  color: #581845;
  border: none;
  width: 100%;
  /* margin-top: 20px; */
  /* border-top: 1px solid #c3c3c3; */
  border-radius: unset;
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:active,
.btn:focus,
.btn2:active,
.btn2:focus {
  background-color: unset;
  transform: unset;
  border: unset;
  border-radius: unset;
}

.dropdownButton .dropdown-toggle .btn-success {
  background: transparent;
  padding: 0;
  color: inherit;
  border-color: transparent;
}

.btn-success {
  background: transparent;
  padding: 0;
  color: inherit;
  border-color: transparent;
}

.titleBar {
  /* padding: 10px 0; */
  align-items: center;
  cursor: pointer;
}

.default {
  /* position: absolute; */
  padding: 1px 10px 1px 10px;
  border: 2px solid #fcc203;
  border-radius: 20px;
  color: #fcc203;
  margin-left: 10px;
}

.pointer {
  cursor: pointer;
}

.svgContainer {
  display: flex;
  /* align-items: center; */
  margin-left: 5px;
  top: 16px;
  right: 0;
}

.svgTick {
  margin-right: 4px;
  cursor: pointer;
}

.svgTickMargin {
  margin-right: 4px;
  cursor: pointer;
  z-index: 11;
}

.svgTickDisable {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.5;
}

.tickStyle:hover path {
  fill: var(--color-gary-4, #979797) !important;
}

.svgClose {
  cursor: pointer;
  z-index: 11;
}

.dropDownListStyle {
  cursor: pointer;
}

hr.borderLine {
  height: 1px;
  border: none;
  background: #c3c3c3;
  margin: 0;
  padding: 0;
  /* border-top: 1px solid red; */
  width: 100%;
}

.overflowContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: auto;
}

.cardContent {
  padding-bottom: 10px;
}

.ribbon {
  background-color: #EEE8EC;
  height: 40px;
}

.triangle {
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-right: 30px solid white;
  border-bottom: 20px solid transparent;
  position: relative;
  top: 0px;
  right: 0px;
}