.textArea {
  margin-top: 5px;
  margin-bottom: 8px; 
}
.nomeeting{
  position: relative;
  top: 120px;
}
.overall1 {
  padding: 10px 16px 0px;;
}

.noteListStyle {
  /* border: 1px solid var(--color-gary-6, #b3b3b3); */
  border-radius: 4px;
  margin-top: 8px;
  box-sizing: border-box;
}
.dateStyle {
  box-sizing: border-box;
  margin-top: 5px;
}
.datetype {
  margin-right: 10px;
  text-align: center;
  align-items: center;
}
.notesTextStyle {
  font-size: 14px;
  color: #333333;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: clip;
  white-space: wrap;
  overflow-wrap: break-word;
}
.notesTextStyle ul {
  padding-left: 25px !important;
  list-style:disc !important;
}
.notesTextStyle u
 {
  color: #333333;
}
.reactquillchange p u{
  color: #333333;
}
 .textArea .ql-editor p {
  max-height: 20000px; /* Adjust the max height as needed */
  overflow-y: auto; /* Add vertical scroll if content exceeds max height */
}
.textArea .ql-editor {
  max-height: 20000px; /* Adjust the max height as needed */
  overflow-y: auto; /* Add vertical scroll if content exceeds max height */
}
.textArea ql-container ql-snow{
  height: 30px;
}
/* .reactquillchange p {
  max-height: 700px;
}   */
.notesTextStyle ol {
  padding-left: 25px !important;
}
.notesTextStyle a {
  color: #581845 !important;
  font-weight: 600 !important;
}
.notesTextStyle p {
  margin-bottom: 0px;
  font-size: 13px !important;
  color: #333333 !important;
}
.cardinnotes {
  padding: 10px;
}
.firstlastchar {
  font-size: 12px;
}
.profile {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
}
.notesOverAll {
  margin-top: 16px;
  position: relative;
  /* padding:0px 1px; */
  padding-bottom: 5px;

  /* overflow: scroll; */
}

.Notesscrol {
  overflow-y: scroll;
  /* scrollbar-width: thin; */
  padding: 16px 8px 0px 16px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {

  .Notesscrol {
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 16px 8px 0px 16px;
  }
  }
  

.dotSvg {
  cursor: pointer;
}

.dropDownList {
  margin-left: 8px;
}
.dropDownOverAll {
  cursor: pointer;
  padding: 4px 8px;
}

.dropBorder {
  border: 1px solid var(--color-gary-6, #b3b3b3);
  border-radius: 4px;
  background-color: var(--color-white, #ffffff);
  position: absolute;
  right: 0;
  bottom: -18px;
  box-sizing: border-box;
}
.notesOverAllContainer {
  border: 1px solid var(--color-gary-6, #b3b3b3);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
  /* min-height: 2000px; */
  overflow: scroll;
}
.saveBtn {
  margin-left: 16px;
}
/* .meetingFlex {
  margin-top: 24px;
  margin-bottom: 24px;
} */
.syncBtn {
  padding: 2px 4px;
}
/* .syncedWidth {
  margin: 0px 8px;
} */
.svgInfo {
  margin-right: 4px;
}
.settingBtn {
  margin-left: 8px;
}
.overall2 {
  padding: 16px;
}
.initialbutton {
  width: 100%;
}
.initialbuttons {
  width: 100%;
  padding-left: 10px;
  border: 1px solid #d7c7d2 !important;
  height: 40px;
  border-radius: 3px;
}

.notestext {
  display: flex;
  justify-content: space-between;
}
.middleline {
  margin-top: 20px;
  border-bottom: 1px solid #c3c3c3;
}
.overAllss {
  background-color: var(--color-white, #ffffff);
  width: 350px;
  padding: 25px;
  border-radius: 4px;
}
.scrollStyle {
  max-height: 300px;
  overflow: scroll;
  min-height: 300px;
}
.insertStyles {
  font-size: 14px;
  color: #333333;
}
.insertStyless {
  /* text-align: center; */
  font-size: 14px;
  color: #333333;
}

.newBtn {
  background-color: #fff;
  border-color: #a5889c;
  border-width: 1px;
  cursor: pointer;
  margin-bottom: 10px;
  width: auto;
}
.newBtn:hover,
.newBtn:active,
.newBtn:focus {
  background: unset;
  border-width: 1px;
  border-color: #a5889c;
}
.cancel {
  margin-right: 8px;
  background-color: #888888;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}
.cancel:active,
.cancel:focus {
  background-color: #888888;
  border: none;
}
.update {
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}
.update:active,
.update:focus {
  border: none;
}
.nojoppostyettext {
  /* color: #666666; */
  margin-top: -10px;
}
.borderbellow {
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: 10px;
  margin-top: -15px;
}
