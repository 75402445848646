.checkboxContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  /* background-color: white; */
  /* height: 22px; */
  width: 100%;
  position: relative;
  /* margin-bottom: 4px; */
  padding: 5px;
  /* border-radius: 100px; */
  /* box-shadow: 0px 0px 4px #00000059; */
}

.checkboxContainer .checkbox {
  margin-right: 10px;
  margin-top: 4px;
}

.checkboxContainer .label {
  /* height: 100%; */
  display: flex;
  /* align-items: center; */
  /* border-radius: 100px; */
  /* padding-left: 26px; */
  font-size: 13px;
  text-transform: capitalize;
  /* position: absolute; */
  /* width: 100%; */
}

.checkboxContainer .dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #fff;
  margin-right: 5px;
}
