.des ul {
  padding-left: 40px;
  list-style-type: disc !important;
}

.des ol {
  padding-left: 40px;
  list-style-type: auto;
  /* font-size: 13px */
}
 
.des td p {
  margin-bottom: unset !important;
  font-size: 13px;
}
.des td li {
  margin-bottom: unset !important;
  font-size: 13px;
}

.des td h1 {
  margin-bottom: unset !important;
  font-size: 38px;
  font-weight: 600;
}

.des td h2 {
  margin-bottom: unset !important;
  font-size: 32px;
  font-weight: 600;
}

.des td h3 {
  margin-bottom: unset !important;
  font-size: 26px;
  font-weight: 600;
}

.des td h4 {
  margin-bottom: unset !important;
  font-size: 20px;
  font-weight: 600;
}

.des td h5 {
  margin-bottom: unset !important;
  font-size: 16px;
  font-weight: 600;
}
.des td h6 {
  margin-bottom: unset !important;
  font-size: 14px;
  font-weight: 600;
}
.rolesandresponsibilitiescard td h6 {
  margin-bottom: unset !important;
  font-size: 14px;
  font-weight: 600;
}

.des a {
  font-weight: 600;
}

.jobDetail a {
  font-weight: 600;
}

.rolesandresponsibilitiescard a {
  font-weight: 600;
}

.des u {
  color: initial; 
}

.jobDetail u {
  color: initial;  
}

.rolesandresponsibilitiescard u {
  color: initial;  
}