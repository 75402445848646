.softText {
  margin-top: 10px;
  margin-bottom: 8px;
}
.overAll {
  padding: 25px;
  position: relative;
  
}
.svgEdit {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}
.techDiv {
  margin-right: 8px;
  margin-bottom: 16px;
}
.techText {
  margin-bottom: 8px;
}
.noValues {
  height: 150px;
  position: relative;
}

.svgAdd {
  position: absolute;
  right: -20px;
  top: -8px;
  cursor: pointer;
}