.popup {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 590px;
  background-color: white;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  right: 0;
  bottom: 2px;
  z-index: 1;
  margin-right: 25px;
}
.width100 {
  width: 100%;
}
.modelmiddle {
  width: 1000px;
  height: 595px;
  background-color: white;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  flex-direction: column;
}
.minimised {
  width: 400px;
  height: 30px;
  background-color: white;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  right: 0;
  bottom: 1px;
  z-index: 1;
  margin-right: 25px;
}

.topSection {
  background-color: #581845;
  height: 35px;
  border-radius: 5px 5px 0px 0px;
  padding: 5px 10px 5px 10px;
}
.inputField {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 0.5px solid #c3c3c3;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  /* padding: 5px 10px 5px 0px; */
}
.inputStyle {
  border: none !important;
}
/* .searchinput {
  border: unset !important;
} */

.searchinput {
  background-color: red;
  border: none !important;
}
.fromstyle {
  margin-left: 8px;
  padding: 1px 10px 1px 10px;
  background-color: #eee8ec;
  border-radius: 20px;
}

.filesname {
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  margin-top: 10px;
  margin-right: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.attachfile {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.filesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5px 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.buttoninsert {
  margin-left: '10px';
}

.buttoninsert:hover {
  background-color: 'white';
}
.action {
  background-color: white;
  bottom: 0px;
}
.iconContainer {
  border-bottom: 1px solid #c3c3c3;
  padding: 5px 5px;
}
.icons {
  cursor: pointer;
  padding: 4px;
  margin-left: 5px;
}
.icons:hover {
  background-color: #eee8ec;
  cursor: pointer;
  border-radius: 5px;
}
.iconsDisabled {
  padding: 4px;
  margin-left: 5px;
}
.bottomline {
  border-top: 0.5px solid #c3c3c3;
  position: absolute;
  bottom: 0;
  /* padding: 0px 10px 10px 10px; */
  padding-top: 7px;
  padding-bottom: 5px;
  z-index: 5;
  background-color: white;
  left: 0;
  right: 0;
  margin: 0px 10px 0px 10px;
}
