/* .cardOverAll {
  background-color: var(--color-gary-8, #fafafa);
  padding: 20px 155px 40px;
  border-radius: 4px;
   margin-top: 140px; 
  position: relative;
} */
/* .userInput {
  margin-top: 30px;
} */
/* .btnConatiner {
  margin-top: 24px;
} */
.row {
  display: flex;
  flex-direction: row;
}
.backbuttonchange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-left: 63px;
  width: 79.5%;
}
.backtohome {
  width: 100px;
}
.Backtextchange {
  font-size: 16px;
  color: #581845;
  font-weight: 600;
  margin-left: 10px;
}
.splitrow_1 {
  width: 50%;
  height: 100vh;
  background-color: #f0ebed;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.splitrow_2 {
  width: 50%;
  height: 100vh;
}

.center_aligh_slider {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
}
.center_aligh {
  width: 37%;
  position: absolute;
  top: 45%;
  left: 23%;
  transform: translate(-50%, -50%);
}

.text {
  color: #581845;
}

.error_content {
  color: #581845;
  font-size: 14px !important;
}

.input {
  width: 80%;
  margin-left: 63px;
  padding: 8px;
  border-radius: 9px;
  margin-top: 20px;
  box-shadow: 0px 3px 1px #beadb9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ffffff;
  text-indent: 30px;
  height: 35px;
}

.input:hover {
  border: 1px solid #a5889c;
}
.forgetinput_candidate {
  /* width: 80%; */
  /* margin-left: 63px; */
  padding: 8px;
  border-radius: 9px;
  /* margin-top: 20px; */
  box-shadow: 0px 3px 1px #beadb9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ffffff;
  text-indent: 30px;
  height: 35px;
}

.forgetinput_candidate:hover {
  border: 1px solid #a5889c;
}

.footer {
  position: fixed;
  bottom: 10px;
  left: 25%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #581845;
}

.usericon {
  position: absolute;
  left: 75px;
  top: 29px;
}
.login_button {
  width: 80%;
  /* width: 100px; */
  margin-top: 20px;
  margin-left: 63px;

  height: 30px;
  border-radius: 5px;
  background-color: #581845;
  border: 2px solid #581845;
}

.login_button:hover {
  box-shadow: none;
}
.container {
  margin-top: 50px;
}

.passwordicon {
  top: 24px;
  left: 0px;
}

.error_msg {
  margin-left: 65px;
  margin-top: 4px;
}
.logo {
  margin-top: 20px;
}
.text_margin {
  margin-top: 10px;
}
.verify_name {
  color: #581845;
  margin-top: 4px !important;
}
.background {
  background-color: #f0ebed;
}
.background:focus {
  background-color: #f0ebed !important;
}

.centered_form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.usericon_candidate {
  position: absolute;
  left: 75px;
  top: 2px;
  left: 10px;
}
.login_button_candidate {
  width: 83%;
  /* width: 100px; */
  margin-top: 20px;
  margin-left: 43px;

  height: 30px;
  border-radius: 5px;
  background-color: #581845;
  border: 2px solid #581845;
}

.login_button_candidate:hover {
  box-shadow: none;
}
.footer1 {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
  color: #581845;
}
