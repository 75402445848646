.cardOverAll {
  padding-top: 10px;
}
.mainTitle {
  margin-top: 8px;
  margin-bottom: 8px;
}
.title {
  margin-bottom: 8px;
}
.statusList {
  margin-right: 8px;
  margin-bottom: 8px;
}
.statusContainer{
  margin-top: 8px;
}
.des ul{
  padding-left: 40px;
  list-style-type: disc !important;
}
.des ol{
  padding-left: 40px;
  list-style-type: auto !important;
}
.rolesandresponsibilitiescard{
  padding: 16px;
}

.rolesandresponsibilitiescard:hover{
  box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  transition: all 0.2s ease-out;
}
.des td p{
  margin-bottom: none !important;
}
.rolesandresponsibilitiescard td p{
  margin-bottom: unset !important;
  font-size: 13px;
  
}
.rolesandresponsibilitiescard td li{
  margin-bottom: unset !important;
  font-size: 13px;
  
}
.rolesandresponsibilitiescard td h1{
  margin-bottom: unset !important;
  font-size: 38px;
  font-weight: 600;
}
.rolesandresponsibilitiescard td h2{
  margin-bottom: unset !important;
  font-size: 32px;
  font-weight: 600;
}
.rolesandresponsibilitiescard td h3{
  margin-bottom: unset !important;
  font-size: 26px;
  font-weight: 600;
}
.rolesandresponsibilitiescard td h4{
  margin-bottom: unset !important;
  font-size: 20px;
  font-weight: 600;
}
.rolesandresponsibilitiescard td h5{
  margin-bottom: unset !important;
  font-size: 16px;
  font-weight: 600;
}
.rolesandresponsibilitiescard td h6{
  margin-bottom: unset !important;
  font-size: 14px;
  font-weight: 600;
}
.des a {
  font-weight: 600;
} 
.jobDetail a {
  font-weight: 600;
} 
.rolesandresponsibilitiescard a {
  font-weight: 600;
}
.des u {
  color: initial; 
}

.jobDetail u {
  color: initial;  
}

.rolesandresponsibilitiescard u {
  color: initial;  
}