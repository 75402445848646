.gmailSvg {
  margin-left: 4px;
  margin-right: 8px;
}
.outlookFlex {
  margin-right: 16px;
}


.radioContainer {
  margin-top: 20px;
  margin-bottom: 10px;
}
.overAll {
  padding: 10px 16px;
}

.googleBtn button {
  cursor: pointer !important;
  position: relative !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  padding: 3px 12px 2px 12px !important;
  width: fit-content !important;
  border: 2px solid var(--color-primary, #581845) !important;
  background-color: var(--color-primary, #581845) !important;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1) !important;
}

.googleBtn button:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.2) !important;
}

.googleBtn button:focus {
  border: 2px solid var(--color-primary, #581845) !important;
  background-color: var(--color-primary, #581845) !important;
  box-shadow: none !important;
}

.googleBtn button span {
  color: var(--color-white, #ffffff);
  font-size: 14px !important;
  font-weight: bold;
  padding: 0 !important;
}

.changeGoogleBtn button {
  cursor: pointer !important;
  position: relative !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  padding: 0px !important;
  width: fit-content !important;
  box-shadow: none !important;
  margin: 0 16px;
}

.changeGoogleBtn button span {
  color: var(--color-link, #1890ff) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 0 !important;
}

.clickText {
  margin-right: 8px;
}
.changeBtn {
  margin: 0 16px;
}
.dashBoardText{
  margin-left: 3px;
}
.dashboardFlex{
  margin-top: 16px;
}

.cardStruture {
  max-height: 200px; 
  width: 270px;
  margin-right: 20px;
  padding: 15px 10px 0 10px;
  display: flex;
  height: 157px;
  flex-direction: column;
  align-items: flex-start;
}
.selectCard {
  position: relative;
  height: 157px;
  width: 270px;
  margin-right: 20px;
  padding: 15px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #00be4b;
  box-shadow: 0px 0px 2px 2px #edf4f0;
  border-radius: 5px;
}
.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.btn {
  background-color: transparent;
  color: #581845;
  border: none;
  width: 100%;
  
  /*change css */
  border-radius: unset;
  /* padding: 18px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:active,
.btn:focus {
  background-color: unset;
  transform: unset;
  border: unset;
  border-radius: unset;
}
.editmodal {
  background-color: var(--color-white, #ffffff);
  width: 500px;
  padding: 25px;
  border-radius: 4px;
}
/* .okButton{
  justify-self: flex-end;
  margin-top: 20px;
} */
.editmodal {
  background-color: var(--color-white, #ffffff);
  width: 500px;
  padding: 25px;
  border-radius: 4px;
}

.outlookEmailcard {
  margin-left: 20px;
}
.popfix{
  display: flex;
  justify-content: space-between;
}
.disconnectbtn {
  margin-left: 10px;
  border: 2px solid #ed4857;
}
.disconnectbtn:focus {
  border: 2px solid #ed4857 !important;
}

/* .disconnectbtn .button_buttonTypes-secondary__3QSSJ:hover {
  background-color: white !important;
} */
.disconnectbtn,
.buttonTypes-secondary:hover {
  background-color: white !important;
}

.disconnectbtn > span {
  color: #ed4857;
}

.modelheadder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.linefix{
   width:100% !important;
}
.model {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  height: 200px !important;
  width: 400px !important;
}

.borderbottom{
  border-bottom: 0.5px solid #581845;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.borderbottom1{
  border-bottom: 1px solid #c3c3c3;
  width: 100%;
  margin-top: 10px;
  /* margin-bottom: 5px; */
}

.bordertop{
  border-top: 1px solid rgb(195, 195, 195, 1);

}
.cancelBtn{
  background-color: #888888;
  color: #fff;
  font-weight: bold;

}
.ratingStar ul li {
  font-size: 30px;
  cursor: default;
  margin-right: 7px;
}

.ratingStar ul {
  font-size: 16px;
}
.backgroundstyle{
  padding: 25px;
  width: 350px;
  background-color: #fff;
  border-radius: 5px;
  justify-content: center;
}

.ribbon {
  background-color: #EEE8EC;
  height: 40px;
}

.triangle {
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-right: 30px solid white;
  border-bottom: 20px solid transparent;
  position: relative;
  top: 0px;
  right: 0px;
}