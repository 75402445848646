.overAll {
  background-color: var(--color-primary, #581845);
  padding: 2px 40px;
}
.profile {
  height: 40px;
  width: 40px; 
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  margin-right: 24px;
}

.linkTitle {
  margin-right: 4px;
  padding: 8px 12px;
  border-radius: 4px;
}

.linkTitle:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.navFocusColor {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.svgMargin {
  margin-right: 4px;
  padding: 8px 12px;
  border-radius: 4px;
}
.svgMargin:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.svgUserStyle {
  padding: 8px 2px;
  border-radius: 4px;
}

.profileStyle {
  padding: 8px 12px;
  border-radius: 4px;
}

.profileStyle:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}
 
.candiProfile {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
}
.dropdownhead:hover{
  background-color: '#581845',
}
.dropdownhead::after {
  display: none !important;
}