.drop {
    position: relative;
  }
  
  .drop1 {  
    position: absolute; 
    border-radius: 5px; 
    box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, 0.36);
    display: flex;
    z-index: 2;
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
    align-items: center;
    justify-content: center;
  }
  
  .crownicons {
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    padding-right: 2px;
    padding-bottom: 3px;
    border-radius: 100%;
    box-shadow: 0px 2px 8px 1px #c3c3c3;
  }
  .overallback{
    background-color: white;
    padding: 5px;
    border-radius: 4px;
    border: 0.5px solid #c3c3c3;
  }