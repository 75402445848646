.svgDownload {
  margin: 0 8px;
}
.sortText {
  /* margin: 0 8px; */
  /* position: relative;
  top: 7.4px; */
  font-size: 13px;
  color: rgb(88, 24, 69);
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.sortbybar{
  display: contents;
}
.overAll {
  margin-top: 7px;
  /* margin-bottom: 16px; */
  /* border-bottom: 1px solid var(--color-gary-7, #dfdfdf); */
  padding-bottom: 0px;
  padding-left: 11px;
}
.selectTagStyle {
  width: fit-content;
}

.pointer{
  /* border-radius: 10px;
  box-sizing: border-box;
  background-color: #EEE8EC;
  height: 30px;
  width: 120px;
  align-items: center;
  display: flex; */

  cursor: pointer;
    position: relative;
    box-sizing: border-box !important;
    border: none;
    border-radius: 4px;
    background-color: #EEE8EC;
    padding: 3px 12px 2px 12px;
    width: fit-content;
    height: 30px;
    display: flex;
}
.favourites{
  position: relative;
  left: 2px;
  font-size: 13px;
  color: #581845;
  top: 1px;
}

.svgHeart{
  position: relative;
  right: 2px;
}

.bulkSelection {
  background-color: #c6b8c2;
  padding: 5px 20px 5px 20px;
  border-radius: 11px;
  height: 30px;
  width: fit-content;
  white-space: nowrap;
  position: relative;
  /* left:62% */
  display: flex;
  justify-content: center;
  align-items: center;
}


/* @media only screen and (min-width: 1366px) and (max-width:1920px) {
  .bulkSelection{
    right: 30%;
  }
}

@media screen and (min-width: 1920px) {
  .bulkSelection{
    left:140%
  }
} */

.bulkButton {
  margin-left: 20px;
}

.bulktab{
  margin: 0;
}

.favandsort{
  position: relative;
  display: -webkit-inline-box;
  bottom:2px;
}

.totalcandidatescount{
  margin-right: 8px;
  color: #581845;
  font-weight: 500;
  height: 30px;
  align-items: center;
  position: relative;
  /* top: 1px; */
}

.totalcandidatescheckbox{
  right: -3px;
  position: relative;
  /* top: -4px; */
}
.totalCount{
  position: relative;
  color: #581845;
  font-weight: bold;
  right: 6px;
  font-size: 12px;
}

.updateBtnLoader{
  margin-right: 10px;
  margin-bottom: 4px;
}
.process{
  color: #979797;
}

.overAllloader {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px 24px;
}
.des {
  max-width: 575px;
  margin-top: 8px;
}
.flexConatiner {
  margin-left: 9px;
  margin-top: 4px;
}
.btn {
  margin-top: 8px;
}

