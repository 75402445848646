

.line {
  border: none;
  background: #c3c3c3;
  /* width: 670px; */
  height: 1px;
  margin-bottom: 10px;
}

.button {
  /* border: none;
  background: none; */
  background-color: white;
  padding: 5px;
  color: #581845;
}

.element {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
}
.banner {
  background-color: #ffffff;
  padding: 2px;
  -webkit-box-shadow: 1px 2px 1px 1px #d7c7d2;
  -moz-box-shadow: 1px 2px 1px 1px #d7c7d2;
  box-shadow: 1px 2px 1px 1px #d7c7d2;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
  height: 45px;
}
.slotcontainer {
  margin-top: 70px;
  width: 100%;
  height: 100%;
  padding: 0px 50px;
}
.slotter {
  display: flex;
  overflow: hidden;
  box-shadow: 5px 5px #888888;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 3px 0px 5px -1px rgba(0, 0, 0, 0.2);
  /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2), 0 0 0 transparent; */
  padding: 20px;
  border-radius: 4px;
}
.slotter:hover {
  display: flex;
  overflow: hidden;
  box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  transition: all 0.2s ease-out;  
  padding: 20px;
  border-radius: 4px;
}
.content {
  border-top: 1px solid #c3c3c3;

}
.leftside {
  padding-right: 40px;
}
.rightside {
  padding-left: 40px;
  border-left: 1px solid #c3c3c3;
}
.button1 {
  width: 180px;
  margin: 10px 10px 10px 0px;
  border: 1px solid #581845;
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px !important;
}

.button1:focus {
  width: 180px;
  margin: 10px 10px 10px 0px;
  border: 1px solid #581845;
  /* background-color: #d7c7d2 !important; */
  border-radius: 8px;
  background-color: #581858 !important;
    color: #fffcff !important;
  padding: 5px;
  font-size: 14px !important;
}
.button1:hover{
  background-color: #d7c7d2;
}

.select {
  max-height: 160px;
  overflow-y: scroll;
}
.confrompage {
  /* margin-top: 100px; */
  border-radius: 10px;
  box-shadow: 0px 0px 4px 4px rgba(88, 24, 69, 0.1);
  background: #f0ebed;
  padding: 25px;
  width: 720px;
}
.dashboard {
  /* margin-top: 100px; */
  border-radius: 10px;
  box-shadow: 0px 0px 4px 4px rgba(88, 24, 69, 0.1);
  background: #f0ebed;
  padding: 25px;
  width: 720px;
}

.line5 {
  border: none;
  background: #c3c3c3;
  width: 630px;
  height: 1px;
}
.successTick {
  align-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.footerlogo{
  font-weight: bold;
  font: size 16px;
  justify-content:  center;
  margin-bottom: 90px;
}

.unavailble {
  /* justify-content:  center; */
  font-size: 14px;
  /* margin-left: 1px; */
  margin-top: 340px;
  margin-left: 570px;
}

.overallContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}


.confromWindow{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.footerstyle{
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1); /* Add box shadow to top and bottom with reduced spread radius */
  background-color: white;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  /* background-color: yellow; */
  padding: 5px;
  font-size: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.pipestyles{
  height: 20px;
  border-left: 1px solid #581845;
  margin-left: 10px;
}