.rowBorder-normal {
  border-bottom: 0.3px solid #dfdfdf;
  padding: 8px 0px;
  margin: 0 8px; 
}
.rowBorder-outline {
  border-bottom: 1px solid #A5889C;
  padding: 8px 0px;
  margin: 0 8px;
}
.rowBorder-overAll {
  border-bottom: none;
  padding: 8px 0px;
  margin: 0 8px;
}
.rowFocus {
  background-color: var(--color-gary-8, #dbdbdb);
}
