.svgDownload {
  margin: 0 8px;
  cursor: pointer;
}
.totalText {
  margin: 0 8px;
}
.overAll {
  padding-bottom: 10px;
  padding-left: 11px;
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
}
.svgNone {
  margin: 0 8px;
  cursor: not-allowed;
}
.btnStyle {
  background-color: #eee8ec;
  border: #eee8ec;
  /* margin-right: 10px; */
  cursor: pointer;
}
.btnStyle:active {
  background-color: #eee8ec !important;
}
.bulkSelection {
  background-color: #c6b8c2;
  padding: 5px 20px 5px 20px;
  border-radius: 11px;
  height: 30px;
  width: fit-content;
  white-space: nowrap;
  position: relative;
  
}

.bulktab{
  margin: 0;
}
.bulkButton {
  margin-left: 20px;
}
.sortbybar{
  display: contents;
}
.sortText {
  /* margin: 0 8px; */
  position: relative;
  top: 5px;
  font-size: 13px;
  color: rgb(88, 24, 69);
}
.pointer{
  cursor: pointer;
    position: relative;
    box-sizing: border-box !important;
    border: none;
    border-radius: 4px;
    background-color: #EEE8EC;
    padding: 5px 12px 2px 12px;
    width: fit-content;
    height: 30px;
    display: flex;
}