.Container {
  padding: 10px 16px;
}

.textStyles {
  padding: 10px 0px;
}

.datePicker {
  border: 1px solid var(--color-gary-5, #cccccc) !important;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 12px;
  padding: 10px;
  text-align: end;
}

.msgText {
  border-bottom: 0.5px solid #581845;
  padding: 4px 2px;
  margin-left: 8px;
  margin-right: 8px;
}

.scheduleButton {
  margin-left: 15px;
}