/* .overlaybackground{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.34);
    z-index: 9999;
    pointer-events: auto;
} */
/* Styles for arrow box */
.box {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 390px;
    position: fixed;
    z-index: 9999999;
    top: 15px;
    /* text-align: justify; */
}

/* Styles for arrow */
/* .box::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px;
    top: -20px; 
    z-index: 9999; 
  } */

.box::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px;
    z-index: 9999;
}

/* top */
.box.arrowTopLeft::before {
    border-color: transparent transparent #fff transparent;
    top: -20px;
    left: 20px;
}

.box.arrowTopRight::before {
    border-color: transparent transparent #fff transparent;
    top: -20px;
    right: 20px;
}

.box.arrowTop::before {
    border-color: transparent transparent #fff transparent;
    top: -20px;
    left: calc(50% - 10px);
}

/* bottom */
.box.arrowBottom::before {
    border-color: #fff transparent transparent transparent;
    bottom: -20px;
    left: calc(50% - 10px);
}

.box.arrowBottomLeft::before {
    border-color: #fff transparent transparent transparent;
    bottom: -20px;
    left: 20px;
}

.box.arrowBottomRight::before {
    border-color: #fff transparent transparent transparent;
    bottom: -20px;
    right: 20px;
}

/* left */
.box.arrowLeft::before {
    border-color: transparent #fff transparent transparent;
    top: calc(50% - 10px);
    left: -20px;
}

.box.arrowLeftTop::before {
    border-color: transparent #fff transparent transparent;
    top: 20px;
    left: -20px;
}

.box.arrowLeftBottom::before {
    border-color: transparent #fff transparent transparent;
    bottom: 20px;
    left: -20px;
}

/* right */
.box.arrowRight::before {
    border-color: transparent transparent transparent #fff;
    top: calc(50% - 10px);
    right: -20px;
}

.box.arrowRightTop::before {
    border-color: transparent transparent transparent #fff;
    top: 20px;
    right: -20px;
}

.box.arrowRightBottom::before {
    border-color: transparent transparent transparent #fff;
    bottom: 20px;
    right: -20px;
}

.duplicatesidebar {
    width: 150px;
    height: 32px;
    color: #fff;
    background-color: #eee8ec;
    border-radius: 0px 5px 5px 0px;
    position: fixed;
    z-index: 9999999;
    display: flex;
    margin: 0 4px 4px 0;
    justify-content: center;
    box-shadow: 0px 0px 6px 6px #ffffff;
}
.iconcss{
    position: fixed;
    z-index: 9999999;
    box-shadow: 0px 0px 6px 6px #ffffff;
    border-radius: 30px;
}