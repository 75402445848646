.inputStyle {
  min-width: 26%;
  margin-right: 16px;
}
.inputConatiner {
  margin-top: 15px;
  margin-bottom: 15px;
}
.inputConatinerApplicants {
  margin-top: 15px;
  margin-bottom: 15px;
}
.svgRefresh {
  cursor: pointer;
  margin-left: 8px;
}
.viewText {
  margin-top: 5px;
  margin-left: 20px;
}
.candidatedatabase {
  display: flex !important;
}
.Applicantdatabase {
  display: flex !important;
}
.careerPage {
  margin-top: 7px;
  margin-left: 4px;
}

.linkSpaceDefault {
  margin-left: 2px;
}
.linkSpace {
  cursor: pointer;
  margin-left: 2px;
}
.marginLeft {
  margin-right: 16px;
}
.filterStyle {
  margin-bottom: 8px;
}
.pagination {
  margin-top: 10px;
  margin-bottom: 15px;
}
.svgRefresh {
  cursor: pointer;
}

.inComplete {
  margin-right: 16px;
}
.inputWidth {
  min-width: 280px;
}
.importText {
  margin-right: 6px;
  margin-top: 6px;
  margin-left: 4px;
}
.SelectTagRow {
  margin-top: 10px;
}
.skillContainer {
  min-width: 225px;
  max-width: 275px;
  margin-right: 15px;
}

.bulkmodel {
  background-color: white;
  padding: 25px;
  height: 320px;
  width: 700px;
  border-radius: 5px;
}
.verifymodel {
  background-color: white;
  width: 715px;
  height: 336px;
  padding: 25px;
  border-radius: 5px;
}

.verifybutton {
  height: 70px;
  width: 140px;
  border: 1px solid #d7c7d2;
}

.verifybutton span {
  color: #000000;
}
.overAll {
  width: 318px;
  height: 240px;
  position: relative;
  align-items: center;
  display: flex;
  padding-right: 5px;
}
.dot{
  list-style-type: disc !important;
  padding-left: 30px;
}

.verifymodel1 {
  background-color: white;
  width: 300px;
  height: 300px;
  padding: 25px;
  border-radius: 5px;
}
.box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #ED4857;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 269px;
  background-color: rgb(0, 190, 75);
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}


/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbontopright{
  left: -10px;
  top: 14px;
  transform: rotate(45deg);
}

.sampleDatastyle{
  position: absolute;
  top: 36%;
  left: 33%;
  border: 1px solid #EEE8EC;
  border-radius: 5px;
  padding: 10px;
  width: 35%;
  background-color: #EEE8EC;
}


.postyet2 {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.postyet3 {
  /* margin-bottom: 5px; */
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.postyet4 {
  text-align: center;
}

.CloseIcon {
  position: absolute;
  top: 52%;
  right: 45px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
}

.jobtitleCloseIcon {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
  padding-top: 7px;
}


.crossIcon{
  all: unset;
  cursor: pointer;
  padding-top: 5px;
  bottom: 0;
  position: absolute;
  right: 40px;
  top: 7px;
  display: flex;
  width: fit-content;
}