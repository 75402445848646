.overAll {
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.openStyle {
  margin-right: 8px;
  margin-top: 3px;
}
.border {
  padding: 8px 24px; 
  height: 45px;
  border-bottom: 1px solid  #ffffff;
  background-color: #EEE8EC;
  box-shadow: 3px 0 5px -2px rgba(238, 232, 236, 1);
}
.button {
  padding: 16;
  display: grid;
  margin: 16;
}
.middle{
  box-shadow: 3px 0 5px -2px rgba(238, 232, 236, 1);
}
.dotstyle{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #581845;
  margin-left: 5px;
  margin-right: 5px; 
}
.trucatetext{
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.trucatetextmane{
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}