.displayNone {
  display: none;
}
.overAll {
  /* padding: 16px; */
  margin-bottom: 10px;
  padding: 0 16px;
}
.changeStyle11 {
  cursor: pointer;
}
.border {
  /*margin: 24px 18px 0.4px 2px;*/
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #b3b3b3;
  align-items: center;
  /*min-width: 370px;*/
  width: 133px;
  height: 121px;
  display: flex;
  justify-content: center;
  min-height: 80px;
}

.imglogo {
  width: 133px;
  height: 121px;
}
.labelStyle {
  margin: 0;
  margin-left: 4px;
}
/* .companyrow1{
max-width: 135px;
} */
.cardOverAll {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.uploadText {
  margin-top: 8px;
}
.btnStyle {
  margin-left: 16px;
}
.svgClose {
  cursor: pointer;
  margin-left: 8px;
  position: relative;
  bottom: 45px;
  right: 27px;
}

.title {
  margin-bottom: 4px;
}
.desText {
  margin-bottom: 24px;
}
.innerFlex {
  position: relative;
}
.cancel {
  margin-right: 8px;
}
.overAll {
  overflow-y: scroll;
  /* margin-top: 15px; */
}
.bottomline {
  border-bottom: 0.5px solid #a5889c;
  margin-top: 30px;
}
.companyuserheading {
  display: flex;

  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.profilehead {
  padding-left: 8px;
}
@media screen and (min-width: 1280px) and (max-width: 1350px) {
  .overAll {
    padding: 0px 5% !important;
  }
}
/* @media screen and (min-width: 960px) and (max-width: 1280px) {
  .overAll {
    padding: 0px 3% !important;
  }
} */

@media screen and (max-width: 960px) {
  .overAll {
    padding: 0 16px;
  }
}
.title {
  margin-top: 24px;
  margin-bottom: 24px;
}
.borderStyle {
  /* padding: 0 20px; */
  /* border: 1px solid var(--color-gary-7, #dfdfdf); */
  border-top: 2px solid var(--color-gary-7, #dfdfdf);
  /* border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; */
}
/* .with80{
  width: 150px;
}

.width90{
 
  width: 150px;
} */
.savebutton {
  margin-right: 15%;
  position: relative;
  top: 5rem;
}
.gray_color {
  color: #666666;
  font-size: 13px;
}
.companyrow {
  margin-top: 10px;
  width: 80%;
  margin-bottom: 10px;
}

.changeStyle {
  position: absolute;
  border-radius: 4px;
  background-color: rgba(196, 196, 196, 0.8);
  cursor: pointer;
  height: 121px;
  z-index: 99;
  width: 131px;
}
.changeStyle1 {
  position: absolute;
  border-radius: 4px;
  /*background-color: rgba(196, 196, 196, 0.8);*/
  cursor: pointer;
  height: 121px;
  width: 131px;
}
.merginghover {
  position: relative;
}
.changeimgfile {
  z-index: 100;
  width: 300px;
  border-radius: 4px;
  height: 35px;
  margin-top: -35px;
  /* background-color: rgba(196, 196, 196, 0.319); */
  background-color: rgb(196, 196, 196);
}
.labelchange {
  font-size: 14px;
  color: #581845;
  margin-bottom: 0px;
}
.changeimgfile1 {
  z-index: 100;
  border-radius: 4px;
  width: 300px;
  height: 35px;
  margin-top: -35px;
  background-color: rgb(196, 196, 196);
}
.changeStyleNoreadius {
  position: absolute;
  background-color: rgba(196, 196, 196, 0.8);
  cursor: pointer;
}
.text {
  text-align: center;
}
/* .imgContainer {
  position: relative;
  width: 131px;
} */
.fileStyle {
  display: none;
}
.savecontinuebutton {
  margin-top: 30px;
  height: 35px;
}
.btnStyle {
  margin: 0;
  cursor: pointer;
  width: fit-content;
}
.merginghover1 {
  width: 300px;
  margin-top: 8px;
  position: absolute;
}
.imgStyle {
  border-radius: 4px;
  height: 121px;
  object-fit: cover;
  box-sizing: border-box;
  width: 300px;
  /* -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36); */
  cursor: pointer;
  border: 2px dashed #d7c7d2;
}

.changelogo {
  cursor: pointer;
  color: #581845;
  font-weight: 700;
  height: 15px;
}

.changelogo {
  cursor: pointer;
  color: #581845;
  font-weight: 700;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltipcontent {
  margin-top: 5px;
  font-size: 13px !important;
}
.imgStylebanner {
  border-radius: 4px;
  height: 121px;
  object-fit: cover;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 300px;
  /* -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36); */
  cursor: pointer;
  border: 2px dashed #d7c7d2;
}

/* .imgStyleNoRadius {
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  object-fit: cover;
} */
.svgCloseStyle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 99;
}
.uploadImg {
  position: relative;
  height: 121px;
  width: 131px;
}
.textsection {
  margin-top: 1rem;
}

.phoneInput {
  border: 1px solid #d7c7d2 !important;
  /* width: 90%     !important; */
  height: 30px !important;
  font-size: 13px;
}
/* .phoneInputs{
  margin-top: 2px;
} */
.phoneInput:hover {
  border: 1px solid #58184570 !important;
}
.phoneInput:focus {
  border: 1px solid var(--color-primary, #581845) !important;
  box-shadow: none !important;
}
.displayNone {
  display: none;
}
/* .overAll { */
/* padding: 16px; */
/* margin-bottom: 10px;
} */
.border {
  /*margin: 24px 18px 0.4px 2px;*/
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #b3b3b3;
  align-items: center;
  /*min-width: 370px;*/
  width: 133px;
  height: 121px;
  display: flex;
  justify-content: center;
  min-height: 80px;
}
.imglogo {
  width: 133px;
  height: 121px;
}
.labelStyle {
  margin: 0;
  margin-left: 4px;
}
.maxWidth {
  max-width: 400px;
}
.maxWidth2 {
  margin-top: 20px;
}
.maxWidth3 {
  margin-top: 20px;
}
.btnstyleclosesave {
  width: 100px;
  height: 35px;
}

.cardOverAll {
  padding: 20px;
  margin-top: 1rem;
  margin-right: 0.5rem;
  margin-left: 1px;
  margin-bottom: 1rem;
}
.cancelbutton {
  margin-right: 20px;
}
.overallbtn {
  display: flex;
  justify-content: flex-end;
  margin-right: 28px;
  margin-top: 40px;
}
.cardOverAll2 {
  padding: 20px;
  margin-top: 1rem;
  margin-right: 0.5rem;
  margin-left: 1px;
  margin-bottom: 2rem;
}
.headingcontent {
  margin-left: 10px;
}
.margintopline {
  border-top: 1px solid #c3c3c3;
  margin-bottom: 15px;
  margin-top: 15px;
}
.uploadText {
  margin-top: 8px;
}
.btnStyle {
  margin-left: 16px;
}
.svgClose {
  cursor: pointer;
  margin-left: 8px;
  position: relative;
  bottom: 45px;
  right: 27px;
}
.title {
  margin-bottom: 4px;
}
.desText {
  margin-bottom: 24px;
}
.innerFlex {
  position: relative;
}

/*.cancel .overAll {
  
  overflow: scroll; */
/* padding: 0 36px; */
/* 
  margin-bottom: 10px;
} */

/* @media screen and (min-width: 1280px) and (max-width: 1350px) {
  .overAll {
    padding: 0px 5% !important;
  }
}
@media screen and (min-width: 960px) and (max-width: 1280px) {
  .overAll {
    padding: 0px 3% !important;
  }
}

@media screen and (max-width: 960px) {
  .overAll {
    padding: 0 16px;
  }
} */
.title {
  margin-top: 24px;
  margin-bottom: 24px;
}
.borderStyle {
  /* padding: 0 20px; */
  /* border: 1px solid var(--color-gary-7, #dfdfdf); */
  border-top: 2px solid var(--color-gary-7, #dfdfdf);
  /* border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; */
}
.with80 {
  width: 300px;
  margin-right: 20px;
}
.width90 {
  width: 300px;
}
.savebutton {
  margin-right: 15%;
  position: relative;
  top: 52px;
}
.gray_color {
  color: #666666;
  font-size: 13px;
}
.companyrow {
  margin-top: 10px;
  width: 97%;
  margin-bottom: 10px;
}
.companyrow2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.companyrow9 {
  margin-top: 5px;
  width: 97%;
  margin-bottom: 10px;
}
/* img{ */
/* height: 125px; // not crt methode
    width: 145px; */
/* } */

.changeStyle {
  position: absolute;
  border-radius: 4px;
  background-color: rgba(196, 196, 196, 0.8);
  cursor: pointer;
  height: 125px;
  z-index: 99;
  width: 145px;
}
.inputheight {
  /* margin-top: 5px; */
  margin-right: 20px;
  width: 300px;
  padding: 0px 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.changeStyle11 {
  margin-bottom: 4px !important;
  cursor: pointer;
  padding-right: 15px;
}
.urlimagefile {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
}
.urlimagefile1 {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
  margin-top: 8px;
}
.companyprofileimg {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 261px;
  margin-top: 10px;
}
.changeStyle111 {
  cursor: pointer;

  padding-left: 20px;
  font-size: 12px !important;
}
.changeStyle1 {
  position: absolute;
  border-radius: 4px;
  /*background-color: rgba(196, 196, 196, 0.8);*/
  cursor: pointer;
  height: 125px;
  width: 145px;
}
.changeStyleNoreadius {
  position: absolute;
  background-color: rgba(196, 196, 196, 0.8);
  cursor: pointer;
}
.text {
  text-align: center;
}
.openpopup1 {
  padding-left: 10px;
}
.openpopup2 {
  height: 40px;
  /* border: 1px dashed #D7C7D2 !important; */
  padding-left: 10px;
  width: 30px;
  font-size: 12px;
  color: #888888;
}
.model {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  max-height: auto;
  /* height: 200px;
    width: 400px;
   
    top: 150px;
    right: 102px; */
}

.openpopup3 {
  padding-left: 35vh;
}
/* .imgContainer {
  position: relative;
} */
/* .fileStyle {
  display: none;
} */
/* .cardfront {
  z-index: 10000;
  margin-top: -10.2vh;
  margin-left: -1.5vh;
  padding: 5px 10px 5px;
  margin-left: 20px;
  max-width: 425px;
position: absolute;
} */

.cardfront {
  z-index: 10000;
  /* margin-top: -12vh; */
  margin-left: -1.5vh;
  padding: 5px 10px 5px;
  margin-left: 20px;
  max-width: 425px;
  position: relative;
}

.cardfront1 {
  z-index: 10000;
  padding: 5px 10px 5px;
  margin-left: 20px;
  font-size: 13px;
  margin-top: -1vh;
  max-width: 425px;
}
.pngsize {
  height: 16px;
  width: 16px;
}
.btnStyle {
  margin: 0;
  cursor: pointer;
  width: fit-content;
}
.moreinformation {
  color: #fcc203;
  font-size: 12px;
  margin-left: 8px;
  border-bottom: 1px solid #fcc203;
}
/* .imgStyle {
  border-radius: 4px;
  height: 125px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
} */
/* .imgStyleNoRadius {
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
} */
.svgCloseStyle {
  /* position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 99;*/

  position: relative;
  top: -123px;
  left: 120px;
  cursor: pointer;
  z-index: 9999;
  width: fit-content;
}
.uploadImg {
  position: relative;
  height: 125px;
  width: 145px;
}
.textsection {
  margin-top: 24px;
}
.passwordhead {
  font-size: 18px;
  font-weight: 700;
  color: #581845;
  margin-top: 15px;
}
.gray_color11 {
  color: #333333;
  font-size: 13px;
}

.overallhead {
  background-color: #eee8ec;
  display: flex;
  justify-content: space-between;
  height: 40px;
  /* margin-left: -10px; */
}

.ribbon {
  background-color: #EEE8EC;
  height: 40px;
}

.triangle {
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-right: 30px solid white;
  border-bottom: 20px solid transparent;
  position: relative;
  top: 0px;
  right: 0px;
}

.warningFlex {
  padding: 6px 8px;
  border: 1px solid #2E6ADD;
  background-color: #2E6ADD20;
  border-left: 8px solid #2E6ADD !important;
  border-radius: 8px;
  width: fit-content;
  /* margin: 0 16px; */
  margin-top: 5px;
  font-size: 13px;  
}

.warningText {
  margin-left: 8px;
  font-size: 13px;
}

.rocket {
  width: 75px;
  height: 75px;
  background-color: rgb(215, 199, 210);
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0px 0px 10px 3px rgb(178 171 171 / 60%);
}

.CloseIcon {
  position: absolute;
  top: 47%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
}