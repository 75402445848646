/* .profile {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  margin-right: 24px;
}

.linkTitle {
  margin-right: 4px;
  padding: 8px 12px;
  border-radius: 4px;
}

.linkTitle:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.navFocusColor {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.svgMargin {
  margin-right: 4px;
  padding: 3px 10px;
  border-radius: 8px;
 
}
.svgInputDiv {
  margin-right: 4px;
  padding: 8px 10px;
  border-radius: 4px;
}
.svgMargin:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}



.profileStyle {
  padding: 8px 12px;
  border-radius: 4px;
}

.profileStyle:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.candiProfile {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
}


.logoStyle {
  height: 90px; 
  width: 60px;
  margin-right: 8px;
}
.noPointer {
  pointer-events: none;
} */

/* new rewamp css */

.overAll {
  background-color: #ffffff;
  padding: 2px 0px;
  -webkit-box-shadow: 1px 2px 1px 1px #d7c7d2;
  -moz-box-shadow: 1px 2px 1px 1px #d7c7d2;
  box-shadow: 1px 1px 1px 1px #d7c7d2;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
}

.dropdown_item {
  -webkit-box-shadow: 0px 0px 8px 1px #d7c7d2;
  -moz-box-shadow: 0px 0px 8px 1px #d7c7d2;
  box-shadow: 0px 0px 8px 1px #d7c7d2;
  border-radius: 10px !important;
  height: 'auto' !important;
  width: '380px' !important;
  margin-top: '6px' !important;
  padding: 10px;
  right: 15px !important;
}
.svgMargin {
  margin-right: -15px;
  padding: 3px 10px;
  border-radius: 8px;
  cursor: pointer;
}
.svgMargin1 {
  margin-right: -15px;
  padding: 3px 10px;
  border-radius: 8px;
  cursor: pointer;
}
.svgMargin1:hover {
  /* background-color: #d7c7d2;
  border-radius: 100%; */
}
.svgMargins {
  margin-right: -15px;
  padding: 3px 10px 3px 0px;
  border-radius: 8px;
  cursor: pointer;
}
/* .svgMargin:hover{
  background-color: #D7C7D2;padding: '3px 10px 3px 0px';
  border-radius: 100%;
  padding:2px ;
} */
.pointerapply {
  cursor: pointer;
  margin-left: 10px;
}
.dropdown_toggle {
  /* margin-top:25px; */
  background-color: '#ffffff' !important;
  border: '#ffffff' !important;
  box-shadow: 'none' !important;
}
/* .Signot:hover {
   box-shadow: none !important;
 } */

.logout {
  margin: 10px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.searchStyle {
  height: 27px;
  border-radius: 10px !important;
  padding: 6px 10px 5px 38px !important;
  border: 1px solid #eee8ec !important;
  outline: none;
  font-size: 14px;
  background-color: #eee8ec !important;
  width: 100% !important;
  box-sizing: border-box !important;
  font-family: 'Roboto', sans-serif;
  color: #581845 !important;
}

.searchStyle::placeholder {
  text-align: center;
  opacity: 0.6;
  text-transform: inherit !important;
  color: #581845 !important;
  font-size: 12px !important;
  margin-right: 10px !important;
}

.profiledroup {
  margin-left: 8px;
  cursor: pointer;
}

.dropdown_span {
  color: '#581845' !important;
  margin-left: '15px' !important;
}

.candiProfile {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
}



/* .searchStyle:hover {
   border: 1px solid #d7c7d2;
 } */

/* .searchStyle:focus {
   border: 1px solid #d7c7d2;
 } */
