.applicantTitle {
  border-bottom: 2px solid var(--color-gary-7, #dfdfdf);
  /* padding-bottom: 16px; */
  padding-bottom: 6px;
  margin-bottom: 8px;
}
.cardOverAll {
  /* padding: 16px; */
  margin-top: 6px;
  margin-bottom: 20px;
}
.tabDiv {
  padding: 10px 0px 8px 0px;
}
.overAll {
  /* padding: 40px; */
  overflow: scroll;
  padding: 0px 20px 0px 5px;
}
.round {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: var(--color-gary-5, #cccccc);
  position: absolute;
  right: 0;
  top:5px;
}
.step {
  /* position: relative;
  margin: 0 400px;
  margin-bottom: 36px; */
  margin-right: -32px;
  padding: 10px 0 20px 0;
}
/* .stepOne {
  position: relative;
  left: -23px;
  top: 22px;
}
.stepTwo {
  position: relative;
  right: 51px;
  top: 22px;
}
.stepThree {
  position: relative;
  top: 47px;
  left: 46px;
} */
.step3Flex {
  position: absolute;
  right: 0;
  top: -20px;
}
.stepOne {
  position: relative;
  left: 0px;
  top: 0px;
}

.stepTwo {
  position: relative;
  right: 10px;
  top: 0px;
}
.stepThree {
  position: relative;
  top: 0px;
  right: 21px;
}
.stepFour {
  position: relative;
  top: 0px;
  right: 32px;
}