.inviteStyle {
  cursor: pointer;
}
.invitetext{
  width: 73px;
}

.inviteDisable {
  opacity: 0.3;
  pointer-events: none;
  height: 50px;
  position: relative;
  top: 20px;
}
.inviteContainer {
  align-self: center;
  width: 25%;
  min-height: 75px;
}
.profileFlex {
  width: 40%;
}
.appliedText {
  margin: 0 4px;
}
.applied{
  right: 8px;
  position: relative;
  top: 0px;
}
.svgInviteNone {
  pointer-events: none;
  opacity: 0.3;
}

.svgInviteNoneOne {
  pointer-events: none;
  opacity: 0.3;
}
.svgInvitePointer {
  cursor: pointer;
}

.notinterestedtext{
  font-size: 13px;
  color: #ED4857;
  margin-left: 2px;
  cursor: pointer;
}

.interestedtext{
  font-size: 13px;
  color: #1890FF;
  margin-left: 4px;
  cursor: pointer;
}

.invitetoapplybutton{
  align-self: center;
  width: 100%;
  min-height: 75px;
  padding-bottom: 18px;
}
.invitetoapplybutton1{
  width: 100%;
  height: 55px;
  position: relative;
  top: 20px;
}
.interestedrow{
  margin-bottom: 6px;
  margin-left: -13px;
  left: 5px;
  position: relative;
}

.notinterested{
  width: 103px;
  margin-bottom: -2px;
  margin-left: -34px;
  display: flex;
  left: 19px;
  position: relative;
  flex-direction: column;
  top: 0px
}

.svgInviteapplied{
  display: none;
}

.svgnointerested{
  display: none;
}
.nolastinvitetext{
  display: none;
}
.svgnoticon{
  left: 3px;
  position: relative;
}

.lastinviteset{
display:flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
