.overAll {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto !important;
  width: 100%;
  /* height: initial; */
  padding-right: 5px;
}

has context menu
.freeImg {
  width: 100%;
}
.warningText {
  margin-left: 8px;
  color: #333333;
  font-weight:400;
  font-size: 13px;
}
.warningFlex {
  padding: 8px 16px;
  border: 1px solid  #2E6ADD;
  
  border-radius: 4px;
  width: fit-content;
  max-width: 1196px;
  /* color: #581845; */
  background-color: #2E6ADD20;
  border-radius: 8px;
  border-left: 8px solid #2E6ADD !important;
  /* border-top: 4px; */
}
.borderBottom {
  border-bottom: 1px solid #ccc;
  padding: 4px 16px;
}
.ribbon{
  background-color: #EEE8EC;
  height: 40px;
}
/* .triangle{
  display:flex;
  justify-self: flex-end;

} */
.triangle {

width: 0px;
  
height: 0px;
  
border-top: 20px solid transparent;
  
border-right: 30px solid white;
  
border-bottom: 20px solid transparent;
  
position: relative;
  
top: 0px;
  
right: 0px;

  
  }
  .scroll{
    overflow-y: auto !important;
  }