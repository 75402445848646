.overAll {
  cursor: pointer !important;
}
.svgRadio:hover path {
  fill: #58184570 !important;
}
.labelStyle {
  margin-left: 6px;
  font-size: 13px;
}
.pointer {
  cursor: pointer !important;
}

.disabledPointer {
  cursor: not-allowed;
  opacity: 0.5;
}
