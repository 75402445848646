.flexContainer {
  /* margin-top: 10px; */
  width: 100%;
}
.overAll {
  padding: 16px;
  margin: 16px 0px;
}
.tagOne {
  margin-right: 8px;
}
.tagTwo {
  margin-left: 8px;
}
