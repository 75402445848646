.navBarContainer {
  padding: 0 20%;
}
.flexContainer {
  padding: 0 50px;
  overflow: scroll;
}
.titleStyle {
  margin-bottom: 8px;
  display: flex;
  margin-top: 10px;
}
.btnConatiner {
  margin-bottom: 10px;
}
.inputNone {
  height: 0;
  width: 0;
  border: none;
  position: absolute;
}
.position {
  position: relative;
}
.positionOne{
  padding-top: 50px;
  position: relative;
}
.marginAuto {
  margin: auto;
}

@media screen and (min-width: 2000px) {
  .marginAuto {
    min-width: 1500px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 2000px) {
  .marginAuto {
    min-width: 1400px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .marginAuto {
    min-width: 1300px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .marginAuto {
    min-width: 1000px;
  }
}

.footerStyle {
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 24px;
}
.warningFlex1 {
  padding: 6px 8px;
  border: 1px solid #2e6add;
  background-color: #2e6add20;
  border-left: 8px solid #2e6add !important;
  border-radius: 8px;
  width: fit-content;
  margin-top: 10px;
  font-size: 13px;
  /* margin-bottom: 15px; */
  margin-left: 6px;
}
.warningText1 {
  margin-left: 3px;
  font-size: 13px;
}
.footerstyle{
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1); /* Add box shadow to top and bottom with reduced spread radius */
  background-color: white;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  /* background-color: yellow; */
  padding: 5px;
  font-size: 10px;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.pipestyles{
  height: 20px;
  border-left: 1px solid #581845;
  margin-left: 10px;
}