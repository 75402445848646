.profileCard {

  height: 590px;
  padding: 30px;
  width: auto;
  box-shadow: 5px 5px #888888;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 3px 0px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2), 0 0 0 transparent;

}

.profileCardMain:hover {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  transition: all 0.2s ease-out;
}

.profileImg {
  border-radius: 70px;
  /* border-color:#581845;  */
  /* border-style: solid; */
  display: block;
  align-items: center;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.36);
  /* -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36); */
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  /* box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36); */
  height: 120px;
  width: 120px;




}

/* .overAll {
  margin-top: 60px;
} */

.borderBottom {
  border-bottom: 1px solid #ccc;
  padding: 4px 16px;
}

.pointer {
  cursor: pointer;
}

.profileCardMain {
  height: 590px;
}
.companyColor{
  color:#333333;
  margin-bottom: 5px;
}

.iconCircle {
  border-radius: 50%;
  border: 1px solid #581845;
  background-color: white;
  color: black;

}

.iconCircle1 {


  /* border-radius: 30%;
  width: 50;
  border: 1px solid purple;
  background-color: white;
  color: black; */

  border-radius: 45px;
  border: 1px solid #581845;
  background-color: white;
  color: black;
  width: auto;
  height: auto;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: none;
  pointer-events: none;
}

/* border-radius: 50px;
border: 1px solid purple;
background-color: white;
color: black;
width: auto;
height: auto;
justify-content: center;
display: flex;
align-items: center; */
.circleflex {
  height: auto;
  line-height: auto;
  width: auto;
  font-size: 2em;

  border-radius: 50%;
  background-color: white;
  border: 1px solid #581845;
  align-content: center;
  text-align: center;
  align-items: center;
  padding: 3px;
  /* margin-left: 5px;
  margin-right: 5px; */





}

.line {
  border: 0.5px solid #C3C3C3;
}

.circleflexicon {
  height: 40px;
  line-height: 40px;
  width: 40px;
  font-size: 2em;
  /* font-weight: bold; */
  border-radius: 50%;

  border: 1px solid black;
  align-items: center;
  text-align: center;
  align-content: center;



}

.tdstyle {
  text-align: center;
  border: 1px solid white;
  border-spacing: 40px;
}

.tablestyle {
  border: 1px solid white;
  border-spacing: 10px;
}

.buttonsizeauto {
  width: 360px
}

.textoverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40px;
}

.textoverflow1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  width: 70px;
}

.address {
  margin-left: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* width: 92%; */
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 13px;
}


.overAll {
  position: relative;
}

.imgContainer {
  border-radius: 65px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  height: 120px;
  width: 120px;
  align-items: center;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  background-color: #fff;
  justify-content: center;
  align-content: center;

}

.changeStyle {
  position: absolute;
  border-radius: 4px;
  background-color: rgba(196, 196, 196, 1.0);
  cursor: pointer;
  height: 120px;
  width: 120px;
  border-radius: 65px;
}

.svgCloseStyle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 99;
}

.fileStyle {
  display: none;
}

.imgStyle {
  height: 120px;
  width: 120px;
  border-radius: 65px;
  object-fit: cover;
}

.svgClose {
  position: absolute;
  left: 49px;
  top: -30px;
}

.weburl {
  margin-left: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 13px;
}

.weburls {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
}