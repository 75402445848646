.inboxContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  position: relative;
}

.model {
  width: 250px;
}
.titleContainer {
  padding: 10px;
  height: 40px;
  background-color: #eee8ec;
  font-size: 12px;
  /* margin-bottom: 10px; */
  display: flex;
  color: #581845;
  /* justify-content: space-between; */
  position: relative;
  padding-right: 40px;
}

.inputSearch {
  width: 250px !important;
  border: 1px solid #a5889c;
}
.inputSearch:focus {
  border: 1px solid #a5889c;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: none;
}
.inputSearch:hover {
  border: 1px solid #a5889c !important;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 53px);
}
.containerColumn {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  /* display: table-cell; */
}
.containerColumn1 {
  display: flex;
  flex-direction: column;
  /* display: table-cell; */
  margin-left: 5px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 0px 2px 0px;
  border-radius: 4px 4px 0 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
.dropdown {
  background-color: #eee8ec;
  border-color: 1px solid #581845;
}
.Toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.Toggle::after {
  content: none;
}
.Toggle:focus {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 30px solid white;
  border-bottom: 20px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
}

.container {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
}

.integrationContent {
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.backbutton {
  position: absolute;
  top: 8px;
  left: 8px;
}
