.labelText {
  margin-right: 2px;
  color: #581845;
  font-size: 13px;
 }

.labelConatiner {
  margin-bottom: 2px;
}
.overAll{
  position: relative;
}