.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.modal {
  position: fixed;
  z-index: 1000;
  border-radius: 4px;
  overflow: hidden;
}

.animate_popup {
  animation: popup 50ms forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
