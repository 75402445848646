.overAlll {
  /* margin: 0px 15px 0px 0px; */
  padding-bottom: 20px;
}
.overAllContainer {
  overflow: hidden !important;
}
.tabFlex {
  position: relative;
}
.candidatesText {
  position: absolute;
  position: absolute;
  right: 0;
  top: 8px;
}
.ribbon{
  background-color: #EEE8EC;
  height: 40px;
}
.triangle {

  width: 0px;
    
  height: 0px;
    
  border-top: 22px solid transparent;
    
  border-right: 30px solid white;
    
  border-bottom: 22px solid transparent;
    
  position: relative;
    
  top: -1px;
    
  right: 0px;
  }
    
