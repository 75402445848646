.listOverAll {
  margin-right: 8px;
  /* margin-top:7.5px; */
  /* margin-top:5px; */
  /* margin-bottom: 1.5px; */
  margin-bottom: 5px;
}
.listHeadingStyle {
  /* margin-top: 6px;
  margin-bottom: 4px; */
  margin-top: 2px;
  margin-bottom: 2px;
}
.overAll {
  background-color: var(--color-white, #ffffff);
  width: 700px;
  padding: 16px;
  border-radius: 4px;
}

.trimStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border-bottom: 1px solid var(--color-gary-6, #b3b3b3); */
  /* height: 34px; */
  height: 20px;
  /* padding-top: 8px; */
}
.trimStyle p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto', sans-serif;
  font-size: 13px !important;
  margin: 0;
  text-align: justify;
}
.trimStyle h6 {
  display: none;
}
.trimStyle h1{
  display: none;
}
.trimStyle h2{
  display: none;
}
.trimStyle h3{
  display: none;
}
.trimStyle h4{
  display: none;
}
.trimStyle h5{
  display: none;
}
/* .trimStyle li{
  display: none;
} */
.normalStyle {
  padding-top: 8px;
}
.normalStyle p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin: 0;
  margin-top: 8px;
  text-align: justify;
  margin: 0;
}
.normalStyle ul {
    padding-left: 16px;
  }
.padding{
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}
.iconspace{
  padding: 5px 10px 0px 0px;
}
.pointer{
  cursor: pointer;
}
.cardstyle {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
 /* margin-left: 3px; */
  border-radius: 8px;
  /* box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.16); */
}
.cardstylehilight{

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
 /* margin-left: 2px; */
 border: 1px solid #581845;
  border-radius: 8px;
 
}
.boxshadow{
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
}