.overAll {
  padding: 0px 8px 0px 8px;
}
.leftConatiner {
  width: 200px;
}

.rightConatiner {
  /* border-left: 1px solid var(--color-gary-7, #dfdfdf); */
  padding-left: 40px;
  position: relative;
}
.yearText {
  margin-top: 8px;
}
.roleText {
  margin-top: 10px;
  margin-bottom: 16px;
}
.toolsText {
  margin-top: 16px;
}
.svgTrash {
  margin-left: 8px;
  cursor: pointer;
}
.borderBottom {
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
}
.borderFlex {
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 52px;
}
.listStyle {
  padding-left: 16px;
  margin-bottom: 0;
}
.noValues {
  height: 150px;
}
.statusFlex {
  margin-left: 16px;
}

.trashFlex {
  position: absolute;
  right: 0;
  top: 0;
}

.preStyle {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin: 0;
  margin-top: 8px;
  text-align: justify;
}
.vrLine {
  border-left: 1px solid var(--color-gary-7, #dfdfdf);
}
