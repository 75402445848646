.listScroll {
  overflow-y: scroll;
}
.pagination {
  /* margin: 1% 38%; */
  width: 100%;
  padding: 1% 0%;
}

/* @media only screen and (max-width: 1366px) {
  .pagination{
    margin: 1% 35%;
  }
}

@media only screen and (min-width: 1366px) and (max-width:1920px) {
  .pagination{
    margin: 1% 39%;
  }
}

@media screen and (min-width: 1920px) {
  .pagination{
    margin: 1% 43%;
  }
} */

.tabPaddingRight {
  padding-right: 13%;
}
.tabPaddingRightOne {
  padding-right: 6%;
}

.dataIcond{
  display: flex;
}

.svgIcon{
width: 15px;
margin-bottom: 15px;
}
.cardOverAll {
  padding: 8px 8px 8px 8px;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-top: 8px;
  margin-right: 8px;
  height: 110px;
  width: 100%;
}
.cardwrap{
  display: flex;
  flex-wrap: wrap;
  width: 32%;
  margin-left: -5px;
  margin-right: 19px;
}
.profile {
  height: 42px;
  width: 42px;
  border-radius: 100%;
}
.profileDiv {
  position: relative;
  margin-left: 4px;
  margin-right: 8px;
} 