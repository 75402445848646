.overAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px 24px;
}
.des {
  max-width: 575px;
  margin-top: 8px;
}
.flexConatiner {
  margin-left: 9px;
  margin-top: 4px;
}
.btn {
  margin-top: 8px;
}
.des {
  /* margin-left: 40px; */
}
.updateBtnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 68px;
  height: 30px;
  /* margin-top: 10px; */
}
