.overAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px;
  position: relative;
  padding-right: 32px;
  width: 190%;
  margin-left: -50%;
  height: 100%;
}

.addInterviewerContainer {
  width: calc(100vw - 120px);
  height: 100vh;
  position: relative;
}

.addInterviewer {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 100%;
  background-color: white;
  animation: slideLeft 300ms ease-out forwards;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

@keyframes slideLeft {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

.menus {
  width: 250px;
  width: 250px;
  background-color: #eee8ec;
  padding: 5px;
}

.inputSearchWrapper {
  margin: 4px 4px 8px 4px;
}
.calenderContent {
  display: flex;
  flex-direction: column;
  /* width: calc(100% - 15%);
  height: 100%; */
}

.calendar {
  width: 100%;
  padding: 0px 6px;
  margin-top: 12px;
  height: calc(100% - 10px);
  /* display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 10px) !important;
  padding: 0px 2px;
  margin-top: 12px; */
}
.calendar > .rbc-time-view {
  height: 62% !important;
}

.inputSearch {
  background-color: white;
  outline: none;
  border: 1px solid #c3c3c3;
  color: #333333;
}
