.overFlowContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  height: 100%;
  width: "100%";
}

.filterOverAll {
  padding-top: 10px;
  padding-right: 16px;

}

.tabsContainer {
  padding-top: 10px;

  color: #666666;
  font-size: 12px;
  /* overflow-y: scroll; */
}

.triangle {
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-right: 30px solid white;
  border-bottom: 22px solid transparent;
  position: relative;
  top: 0;
  right: 0;
}

.jobstext {
  width: 99.27px;
  height: 30px;
  padding-left: 33px;
  padding-right: 32.27px;
  font-size: 15px;
  font-weight: 600;
  background-color: #eee8ec;
  border: none;
  display: flex;
  align-items: center;
  color: #581845;
}

.jobstext {
  height: 30px;
  padding: 10px;
  background-color: #eee8ec;
  display: flex;
  align-items: center;
  color: #581845;
}

/* .overallsearchbox{
    display: flex;
    justify-content: space-between;
  } */
.totaljobs {
  /* padding-left: 15px; */
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
}

/* .boxstyles{
  text-transform: none !important;
} */
.middleline {
  margin-right: 20px;
  height: 25px;
  border: 1px solid #e4dae1;
  margin-top: 2px;
}

.searchstyle {
  width: 412.27px;
  display: flex;
  align-items: center;
}

.searchboxfirst {
  border: none !important;
}

.searchboxoverall {
  border-radius: 0px 20px 20px 0px;
  background-color: #f7f7f7;
  height: 30px !important;
}

.locationicon {
  position: absolute;
  margin-left: 143.81px;
  margin-top: 4px;
  margin-bottom: 8px;
  margin-right: 8.38px;
}

.searchicons {
  width: 26.34px !important;
  height: 30px;
  padding-left: 6.08px;
  padding-right: 9.37px !important;
  padding-top: 9px !important;
  padding-bottom: 8.29px;
  background-color: #581845;
  border-radius: 0px 20px 20px 0px;
  color: #ffffff;
}

.searchbox {
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 30px;
}

/* .inputsearch_search {
  text-transform: none !important;
} */

.tabsStyle {
  margin-top: 30px;
  position: relative;
}

.infiStyle {
  position: absolute;
  right: 0;
  top: 9px;
}

.svgInfy {
  margin-left: 4px;
}

.width99 {
  width: 99%;
  margin-bottom: 50px;
}

.overAll {
  /* margin-left: auto; */
  margin-right: auto;
  /* max-width:  auto;
  min-width: auto; */


}

.overAll2 {
  /* margin-left: auto;
  margin-right: auto; */
  /* max-width: auto; */
  /* min-width: 1300px; */
  /* background-color: rgb(0 0 0 / 4%); */
  height: 81vh;
  margin-top: 3px;
}

.overAll1 {
  /* margin-left: auto;
  margin-right: auto; */
  max-width: auto;
  margin-left: 120px;
  height: auto;
  background-color: #F0EBED;
  /*min-width: 1300px;*/
}

.overAllCard {
  overflow: scroll;
  color: #666666;
  font-size: 12px;
  padding-bottom: 12px;
}

.screenStatusStyle {
  position: relative;
  bottom: 12px;
}

.career {
  margin-right: 5px;
  cursor: pointer;
}

@media screen and (min-width: 1280px) and (max-width: 1350px) {
  .overAll {
    padding: 0px 5% !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1280px) {
  .overAll {
    padding: 0px 3% !important;
  }
}

@media screen and (max-width: 960px) {
  .overAll {
    padding: 0 16px;
  }
}

.invited {
  text-align: center;
}

.screenStatus {
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
  color: #666666;
  line-height: 22px;
}

.screen {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.noJob {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  color: #581845;
}

.downtext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666666;
}

.btneStyle {
  margin-top: 15px;
}

.font {
  font-size: 12px;
  color: #666666;
  line-height: 22px;
}

.fontScreen {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  padding-left: 5px;
  color: #581845;
  top: 2px;
}

.fontScreens {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  padding-left: 5px;
  top: 2px;
  /* position: relative; */
  color: #581845;
}

.styletext {
  font-size: 13px;
  font-weight: 700;
  color: #581845;
  top: 2px;
  /* position: relative; */
}

.styletexts {
  font-size: 13px;
  font-weight: 500;
  color: #581845;
  top: 2px;
}

.cardOverAll {
  padding: 8px 8px 8px 8px;
  margin-top: 12px;
  margin-left: 1px;
  width: 99%;
}

.margin {
  margin: 5px;
}

.titleContainer {
  background-color: #eee8ec;
  font-size: 13px;
  margin: none;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.padding10 {
  padding: 3px;
  margin-top: 8px;
}

.padding5 {
  padding-left: 5px;
  padding-right: 5px;
}

.inputNone {
  height: 0;
  width: 0;
  background-color: transparent;
  border: none;
}

.icon {
  position: relative;
  bottom: 2px;
  margin-right: 8px;
}

.icontop {
  position: relative;
  bottom: -4px;
  margin-right: 8px;
}

.justify {
  text-align: center;
  justify-content: space-around;
}

.style2 {
  margin-left: 8px;
}

.postbutton {
  margin-left: 67.73px;
  width: 150px;
  height: 35px;
  margin-bottom: 182px;
}

.padchange {
  padding: 0 !important;
  padding-bottom: 15px !important;
  padding-right: 10px !important;

}

.padchanges {
  padding: 0 !important;
  padding-bottom: 15px !important;
  padding-right: 15px !important;
  vertical-align: baseline !important;
  padding-top: 7.5px !important;
}

.location {
  padding: 0 !important;
  padding-bottom: 15px !important;
  padding-right: 15px !important;
  vertical-align: baseline !important;
  padding-top: 7.5px !important;
}

.texttruncate {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.padchangesmiddle {
  vertical-align: baseline !important;
  padding: 0 !important;
  padding-bottom: 15px !important;
  padding-right: 45px !important;
  text-align: center;
  padding-top: 7.5px !important;
}

.zeroorder {
  font-weight: 500;
  font-size: 13px;
}

.numberorder {
  font-weight: 700;
  font-size: 13px;
  color: #581845;
}

.padchang {
  padding: 0 !important;
  padding-bottom: 15px !important;
  padding-right: 15px !important;
  vertical-align: baseline !important;
  padding-top: 7.5px !important;
  /* border-top: 1px solid #A5889C !important; */


}

.table tbody td .padchang {
  max-width: 100px !important;
}

.tabeboarder {
  border-right: 0.5px solid #555555;
  display: flex;
  /* padding:0; */
  color: #666666;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}

.tableboarder {
  color: #666666;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}

.hellothere {
  font-size: 12px;
  font-weight: 600;
  color: #581845;
  /* max-width: 110px; */
}

.hovercolor:hover {
  color: #581845 !important;

}

.hovercol:hover {
  color: #581845 !important;

}

.hovercolor {
  padding: 0 !important;
  padding-bottom: 15px !important;
  padding-right: 15px !important;
  padding-top: 7.5px !important;
  vertical-align: baseline !important;
  /* border-top: 1px solid #A5889C !important; */
}

.hovercolormiddle {
  padding: 0 !important;
  padding-bottom: 15px !important;
  padding-right: 45px !important;
  text-align: center;
  padding-top: 7.5px !important;
  vertical-align: baseline !important;
  /* border-top: 1px solid #A5889C !important; */
}

.hovercol {
  padding: 0 !important;
  padding-bottom: 15px !important;
  padding-right: 5px !important;
  font-size: 14px;
  padding-top: 7.5px !important;
  vertical-align: baseline !important;
}

.zitamatch:hover {
  color: #581845 !important;
}

.zitamatch {
  font-weight: 700;
  cursor: pointer;
  font-size: 13px;
}

.maxwidthjobtype {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .twobutton {
  padding-right: 175px;
} */
.ulstyle1 {
  margin-bottom: -0.25rem !important;
  margin-left: 16.5px;
  list-style-type: disc !important
}

.ulstyle11 {
  margin-bottom: -0rem !important;
  margin-left: 16.5px;
  list-style-type: disc !important
}

.ulstyle2 {
  margin-bottom: -0.25rem !important;
  margin-left: 16.5px;
  list-style-type: disc !important
}

.ulstyle3 {
  margin-bottom: -0.25rem !important;
  margin-left: 16.5px;
  list-style-type: disc !important
}

.postyet1 {
  /* margin-left: 570px; */
  margin-top: 245px;
  font-weight: 700;
  /* font-size: 13px; */
  font-size: 16px;
  margin-bottom: 10px;
  color: #581845;
  text-align: center;
}

.postyet2 {
  /* margin-left: 531px; */
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  text-align: center;
}

.postyet3 {
  /* margin-left: 509px; */
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  text-align: center;
}

.postyet4 {
  /* margin-left: 596px; */
  text-align: center;
}

.svcopy {
  color: "#FFC203 100%";
}

.svcopy {
  color: rgb(215, 164, 3);
  margin-top: 7.5px;
}

.tablescroll {
  max-height: 500px;
  overflow-y: scroll;

}

.stickyheader::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 0.5px solid #dee2e6;
  z-index: -1;
  /* Layer the pseudo-element below the content */
}

.stickyheader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.warningText {
  margin-left: 8px;
  font-size: 13px;
}

.warningFlex {
  padding: 6px 8px;
  border: 1px solid #2E6ADD;
  background-color: #2E6ADD20;
  border-left: 8px solid #2E6ADD !important;
  border-radius: 8px;
  width: fit-content;
  margin-top: 5px;
  font-size: 13px;


}

.screenstatu li {
  vertical-align: top;
}

.screenstatu ul {
  list-style-type: disc;
  margin: 0;
  /* Remove default margin */
  padding: 0;
  /* Remove default padding */
}

.selectdiv option {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;

}

.circle1 {
  color: #FCC203;
  background-color: #FCC203;
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.circle2 {
  color: #00BE4B;
  background-color: #00BE4B;
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.circle3 {
  color: #FF0000;
  background-color: #FF0000;
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.jdidText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}