.svgRefresh {
  /* margin-left: 16px; */
  cursor: pointer;
  align-items: center;
  display: flex;
  /* padding-top: 2px; */
}

.filterStyle {
  padding-left: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
}

.matchRadioStyle {
  width: 100px;
  margin-bottom: 8px;
}

.matchTextStyle {
  margin-bottom: 5px;
  /* margin-top: 8px; */
}

.profileTextStyle {
  margin-bottom: 5px;
  /* margin-top: 8px; */
}

.qualificationTextStyle {
  margin-bottom: 5px;
  /* margin-top: 8px; */
}

.qualificationConatiner {
  padding-top: 8px;
  padding-bottom: 8px;
}

.checkBoxContainerOne {
  min-width: 116px;
}

.checkBoxContainer {
  padding-bottom: 16px;
  padding-top: 8px;
}

.checkBoxOne {
  padding-bottom: 16px;
}

.showCheckBox {
  margin-top: 24px;
}

.cardConatiner {
  width: 270px;
  padding-top: 8px;
}

.scrollFlex {
  overflow-y: scroll;
  padding-left: 14px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-top: 12px;
  /* scrollbar-width: thin; */
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .scrollFlex {
    scrollbar-width: thin;
  }
}

.scrollFlex::-webkit-scrollbar {
  width: 4px;
  margin-left: 16px;
  height: 4px;
  overflow: scroll;
  scrollbar-color: rgba(26, 26, 26, 0.3) white;
}

.filter_color {
  background-color: white !important;
  border-radius: 11px;
  width: 300px;
  align-items: center;
}

.filter_ali {
  float: right;
  position: relative;
}

.drop_down {
  margin-right: 10px;
  margin-bottom: 20px;
  position: relative;
}

.drop_down_header {
  padding: 0px 10px 0px 10px;
  background-color: #ffffff !important;
  cursor: pointer;
  width: 230px;
  /* height: 30px; */
  border: 1px solid #581845;
  border-radius: 11px;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.drop_down_menus {
  background-color: #fff;
  position: absolute;
  background-color: var(--color-white, #fff);
  border-radius: 10px;
  width: 100%;
  z-index: 10;
  display: none;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47000000000000003);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47);
  max-height: 480px;
  overflow-y: auto;
}

.show {
  display: block;
  /* z-index: 9999999; */
}

.mtstyle {
  color: #581845 !important;
  border-top: 1px solid #dee2e6;
  padding: 10px;
  text-transform: none !important;
}

.InputSearch {
  text-transform: none !important;
}
.quickFilters {
  margin-right: 10px;
}

.quickfil {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px 2px 10px;
  width: fit-content;
  border: 1px solid #581845;
  margin-left: 10px;
  margin-bottom: 10px;
}
.appFilterContainer {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: sticky;
  background-color: #fff;
}
/* .viewfilter{
  cursor: pointer;
  padding-right: 120px;
  width: 100%;
  } */

.rotate-up {
  transform: rotate(180deg);
}

.rotate-down {
  transform: rotate(0deg);
}


.filtersvg {
  transform: rotate(270deg);
}

.crossIcon {
  all: unset;
  cursor: pointer;
  padding-top: 37px; 
  right: 13px;
  position: absolute;
}