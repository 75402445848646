.taskList {
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 8px;
}
.borderLeftStyle {
  border-radius: 4px;
}
.borderRightStyle {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.container {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  z-index: 11;
}

.tagColor-sky {
  background-color: #aed6f1;
}
.tagColor-yellow {
  background-color: #fad7a0;
}
.tagColor-red {
  background-color: #f5b7b1;
}
.tagColor-green {
  background-color: #a2d9ce;
}

.tagColor-theme {
  background-color: #e8daef;
}
.overAll {
  margin-right: 8px;
  margin-bottom: 8px;
}
.closeStyle {
  background-color: var(--color-gary-7, #dfdfdf);
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
