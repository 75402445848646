.chooseText {
  margin-top: 30px;
  margin-bottom: 40px;
}
.rightCard {
  margin-left: 8px;
}
.leftCard {
  margin-right: 8px;
}
.warningText {
  margin-left: 8px;
}
.warningFlex {
  /* padding: 12px 16px;
  border: 1px solid #2E6ADD;
  background-color: #2E6ADD20;
  border-left: 8px solid #2E6ADD !important;
  border-radius: 8px;
  width: fit-content;
  /* margin-top: 40px; */
  margin-top: 20px;
  /* margin-left: 50px;
  margin-right: 50px;
  max-width: 1198px; */
  /* margin-top: 40px; */
  font-size: 13px;
  max-width: 1196px;
  padding: 6px 8px;
  border: 1px solid #2E6ADD;
  background-color: #2E6ADD20;
  border-left: 8px solid #2E6ADD !important;
  border-radius: 8px;
  width: fit-content;

}
 
.triangle {
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-right: 30px solid white;
  border-bottom: 22px solid transparent;
  position: relative;
  top: 0;
  right: 0;
}

.titleContainer {
  /* padding: 10px; */
  background-color: #eee8ec;
  font-size: 12px;
  margin:none;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  