.titleFlex {
  background-color: #ffb142;
  height: 54px;
  /* border-radius: 4px; */
}
.borderBottom {
  border-bottom: 1px solid var(--color-gary-5, #cccccc);
  height: 42px;
}
.singleTile {
  height: 40px;
  background-color: #fdce78;
  /* border-radius: 4px; */
  padding-left: 8px;
}
.borderNone {
  height: 42px;
}
.scrollablecontainer {
  overflow-x: auto;
}

/* Styling for the sticky column */
.stickycolumn {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff; /* You can adjust the background color as needed */
  border-right: 1px solid #ccc; /* Add a border to separate the columns */
}

/* Styling for the scrollable content */
.scrollableContent {
  overflow: auto;
  max-height: 400px;
}
