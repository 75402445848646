.automatePopup {
  background-color: var(--color-white, #ffffff);
  padding: 25px;
  border-radius: 4px;
}

.screenCards {
  padding: 25px;
  /* height: 500px; */
}

.popUpheadingflex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popUpButtonflex {
  margin-top: 15px;
  border-top: 0.5px solid gray;
  padding-top: 10px;
}

.newAutomateBtn {
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 10px;
  width: 100%;
  border: 1px solid #a5889c;
  margin-right: 15px;
  margin-bottom: 15px;
}

.leftContent {
  border-top: 0.5px solid gray;
  margin-top: 10px;
  padding-top: 10px;
  /* height: 520px; */
  display: flex;
}

.TemplateCard {
  position: absolute;
  top: 66px;
  padding: 25px;
  left: 170px;
  height: 625px;
}




.overAll {
  background-color: var(--color-white, #ffffff);
  padding: 25px 20px 25px 25px;
  border-radius: 4px;
}

.scrollStyle {
  overflow: scroll;
  padding-top: 2px;
}

.listHeadingStyle {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 14px;
}

.listHeadingStyles {
  font-size: 14px;
}

.totalcountchanges {
  font-size: 13px;
  color: #581845;
}

.trimStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 13px;
}

.listOverAll {
  margin-right: 8px;
}

.insertStyles {
  font-size: 14px;
}

.preStyle {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  text-align: justify;
}

.inputchanges {
  padding-left: 25px;
  height: 30px;
  width: 270px;
  border-radius: 10px;
}

.iconspace {
  padding: 5px 10px 0px 0px;
}

.border {
  border-right: 0.5px solid #C3C3C3;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
}

.tempPadding {
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
}

.tempcardstyle {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: var(--color-white, #ffffff);
  /* box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
    -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%); */
  box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.16);
  margin-left: 2px;
}

.tempcardstylehilight {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #581845;
  border-radius: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: var(--color-white, #ffffff);
  /* box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%); */
  /* -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%); */
  box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.16);
  margin-left: 2px;
}

.descCardstyles {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.16);
  overflow: scroll;
}

.automatetemplatealignment {
  font-size: 13px;
}

.automatetemplatealignment p {
  margin: 0;
}

.automatetemplatealignment p a {
  color: #000000 !important;
  font-weight: normal !important;
}

.automatetemplatealignment div a {
  color: #000000 !important;
  font-weight: normal !important;
}



.botomBtncontainer {
  margin-top: 10px;
  border-top: 0.5px solid #C3C3C3;
  padding-top: 10px;
}

.totalcountchanges{
  font-size: 13px;
  color: #581845;
}

.automationEmailContent p a {
  color: #333333 !important;
}

.automationEmailContent p br {
  display: none;
}

.automationEmailContent div a {
  color: #333333 !important;
}
.intemationPopup {
  padding: 25px;
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  min-width: 530px;
  max-width: 600px;
}