.theme-footer-one {
    position: relative;
    background-color: #581845;
}
.theme-footer-one .top-footer .footer-title {
   
    font-size: 22px;
    padding-bottom: 20px;
    color: #ffffff;
}
.theme-footer-one .top-footer .footer-list ul li a {
    font-size: 14px;
    line-height: 30px;
    color: #cbcbcb;
   
    transition: all 0.3s ease-in-out;
}

.mail-address {
    color: #cbcbcb;
    font-size: 14px;
   
    margin-bottom: 15px;
    line-height: normal;
}
.theme-footer-one .bottom-footer-content ul li a {
    font-size: 12px;
    color: #eeeeee;
    margin-right: 20px;
   
}
.theme-footer-one .bottom-footer-content p {
    font-size: 12px;
    margin-top: 2px;
    margin-right: 10px;
}
.theme-footer-one .bottom-footer-content {
    padding: 0px 0 30px;
}

.theme-footer-one .top-footer .footer-list ul li a:hover {
    color: #fcc51b;
}
.hoverstyle:hover {
    color: #fcc51b !important;
}
@media (max-width: 767px){
.theme-footer-one .top-footer .footer-title {
    padding-bottom: 18px;
}}

.theme-footer-one .top-footer [class*=col-] {
    margin-bottom: 30px;
}