.cardOverAll {
  background-color: var(--color-gary-8, #fafafa);
  padding: 20px 40px;
  border-radius: 4px;
  position: relative;
}
.userInput {
  width: 350px;
  margin-top: 30px;
  margin-bottom: 16px;
}
.userPassword {
  width: 350px;
  position: relative;
}
.btnConatiner {
  margin-top: 24px;
}

.overAll {
  position: relative;
  width: 500px;
  height: auto;
  background-color: var(--color-gary-8, #fafafa);
  padding: 25px;
  border-radius: 4px;
}
.svgZitaFlex {
  padding: 40px 100px 50px 48px;
}
.svgClose {
  position: absolute;
  top: 16px;
  right: 22px;
  z-index: 11;
}
.Log_title {
  color: #581845;
  margin-bottom: 4px;
}
.text {
  color: #581845;
}