.overflowContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: auto;
}
.titleBar {
  padding: 10px 0;
  align-items: center;
  cursor: pointer;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.searchicons {
  height: 30px;
  padding: 10px;
  background-color: #581845;
  border-radius: 0px 20px 20px 0px;
  cursor: default;
  border-right :1px solid;
  border-top :1px solid;
  border-bottom :1px solid;
}

.searchbarstyle{
  text-transform: none !important;
  background-color: #f7f7f7;
  height: 30px;
  border: 1px solid #dee2e6 ! important;
  width: 220px;
  text-overflow: ellipsis;
  padding-left: 15px;
  padding-right: 17px;
  border-radius: 20px 0px 0px 20px;
}

.searchbarstyle:hover{
  border: 1px solid #58184570 ! important;
}
.searchbarstyle:focus-visible {
  border: 1px solid #581845 !important;
  outline: none !important;
}
.crossIcon {
  all: unset;
  cursor: pointer;
  padding-top: 5px;
  top: 12px;
  right: 193px;
  position: absolute;
}
.addtemplateBtn{
  cursor: pointer
}

.TempHubintemationPopup{
  padding: 25px;
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
}