.jobRoleFlex {
  margin-right: 16px;
  z-index: 11;
  position: relative;
}
.jobTitleFlex {
  margin-right: 16px;
  position: relative;
}
.overAll {
  margin-top: 15px;
}
.errorMessage {
  color: var(--color-error, #f94949);
  font-size: 12px;
}
.postion {
  position: relative;
}
.displayNone {
  position: absolute;
  z-index: -10;
  width: 0;
  height: 0;
  background-color: transparent;
  border: none;
}
.jdlabels{
  font-weight: bold;
}

.cancelbtn{
  background-color: #888;
}