.overAll {
  overflow: scroll;
  padding-bottom: 40px;
}
/* .svgZitaFlex {
  padding: 40px 106px 50px 48px;
}
.inpuFlex {
  background-color: var(--color-gary-8, #fafafa);
  border-radius: 4px;
  padding: 24px 50px;
}
.inputWidth {
  width: 320px;
}
.inputWidthOne {
  width: 320px;
  margin-right: 20px;
}


.phoneInput {
  border: 1px solid var(--color-gary-5, #cccccc) !important;
  width: 320px !important;
  height: 31px !important;
}
.phoneInput:hover {
  border: 1px solid var(--color-gary-6, #b3b3b3) !important;
}
.phoneInput:focus {
  border: 1px solid var(--color-primary, #581845) !important;
  box-shadow: none !important;
}
.dropDownStyle {
  margin: 2px ​0 10px -1p !important;
  width: 318px !important;
}
.marginRight {
  margin-top: 16px;
} */

.dropDownStyle {
  margin: 2px !important;
  width: 275px !important;
}
.checkBoxStyle {
  align-items: center;
  margin-top: 5px;
}

.check {
  position: absolute;
  top: -14px;
  right: 0px;
}

.checks {
  /* position: absolute; */
  top: -14px;
  right: 0px;
}

.phoneInput {
  /* border: 1px solid var(--color-gary-5, #cccccc) !important; */
  width: 100% !important;
  height: 31px !important;
  border-radius: 0px !important;
  background: transparent !important;
  border: none !important;
  border-bottom: thin solid #6a5e67 !important;
  outline: none !important;
  color: black;
}

.phoneInput:hover {
  border: none !important;
  border-bottom: thin solid #6a5e67 !important;
  outline: none !important;
}

.phoneInput:focus {
  border: none !important;
  border-bottom: thin solid #6a5e67 !important;
  outline: none !important;
  box-shadow: none;
  color: black;
}

.row {
  display: flex;
  flex-direction: row;
}
.row1 {
  width: 50%;
  height: 100%;
  background: url('../../assets/images/Signup.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.row2 {
  width: 50%;
  height: 100%;
  background: url('../../assets/images/Signup.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.center_aligh {
  width: 40%;
  position: absolute;
  top: 45%;
  left: 25%;
  transform: translate(-50%, -50%);
}

.center_aligh_form {
  width: 45%;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  /* z-index: -1; */
}

.form_body {
  margin-right: 25px;
  background-color: #f0ebed;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 4px rgba(88, 24, 69, 0.1);
}

/* .logo {
        margin-top: 20px;
      } */
.margin {
  margin-top: 10px;
}

.signup_input {
  border: none;
  outline: none;
  border-bottom: thin solid #6a5e67;
  background: transparent;

  border-radius: 0px;
  padding: 3px 12px 3px 0px;
}

.signup_input:focus {
  border: none;
  outline: none;
  border-bottom: thin solid #6a5e67;
}

.signup_input:hover {
  border: none;
  outline: none;
  border-bottom: thin solid #6a5e67;
}

.signup_input,
.flag_dropdown {
  background-color: transparent;
}

.signup_col {
  margin-top: 10px;
  margin-right: 20px;
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.login_button {
  width: 100% !important;
  margin-top: 20px;
  /* padding: 5px; */
  /* border-radius: 5px; */
  /* background-color: #581845; */
  /* border: 2px solid #581845; */
}

.login_button:hover {
  box-shadow: none;
}

.account_link {
  margin-top: 13px !important;
  margin-bottom: 12px !important;
  color: #581845;
}
.account_link1 {
  font-size: 11px;
  margin-bottom: 13px !important;
  color: #581845;
}

.text {
  color: #581845;
}
.terms_con {
  color: #581845;
  margin-left: 10px;
}

.title {
  color: #581845;
  font-weight: 600;
  font-size: 20px !important;
}

.free_trail {
  color: #581845;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
}

.margin {
  margin-top: 10px;
  margin-left: 8px;
  color: #581845;
}

.text_margin {
  /* Center vertically and horizontally */
  margin-top: 130px;
  position: relative;
  top: 26px;
}
.verificationtext {
  text-align: center;
  margin-top: 45px;
  color: #581845;
}

.labelText {
  font-size: 14px;
}

.messages {
  padding: 20px 40px 0 40px;
  color: #581845;
  margin-top: -10px;
  text-align: center;
}

.successform_body {
  margin-right: 25px;
  background-color: #f0ebed;
  height: 490px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 4px rgba(88, 24, 69, 0.1);
}

.sign_dot {
  list-style-type: disc !important;
  padding: 5px;
}
.makemecenter
{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
