.inputConatiner {
  background-color: aqua;
  display: flex;
  align-items: center;
}

.svgContainer {
  display: flex;
  align-items: center;
  margin-top: 0px;
  position: absolute;
  top: -1px;
  right: 0;
  left: 85px;
}
.svgContainer2 {
  display: flex;
  align-items: center;
  margin-top: 0px;
  position: absolute;
  top: -1px;
  right: 0;
  left: 88px;
}
.svgContainer1 {
  display: flex;
  align-items: center;
  /* margin-top: 4px; */
  position: absolute;
  top: -2px;
  right: -9px;
}

.svgTick {
  margin-right: 4px;
  cursor: pointer;
}
.svgClose {
  cursor: pointer;
}
.nameStyle {
  cursor: pointer;
  word-wrap: break-word;
}
.textContainer {
  position: relative;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.svgEdit {
  display: none;
  position: absolute;
  top: -6px;
  right: -16px;
}
.svgEditName {
  display: none;
  position: absolute;
  top: -6px;
  /* right: 16px; */
  right: 0px;
}
.nameStyle:hover + .svgEditName {
  display: block;
  z-index: 99;
}
.nameStyle:hover + .svgEdit {
  display: block;
  z-index: 99;
}

.svgTickMargin {
  margin-right: 4px;
  cursor: pointer;
}
.svgTickDisable {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.5;
}
.overAll {
  position: relative;
  width: 100%;
}
.inputOverAll {
  display: inline-grid;
  position: relative;
}
.svgExternal {
  cursor: pointer;
  position: relative;
  top: -2px;
}
.textContainerName {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.tickStyle:hover path {
  fill: var(--color-gary-4, #979797) !important;
}

.sample:hover .svgEditName {
  display: block;
  z-index: 99;
  margin-top: 3px;
}

.sample:hover + .svgEdit {
  display: block;
  z-index: 99;
}
.sample {
  /* background-color: green; */
  position: relative;
  width: 100%;
}
