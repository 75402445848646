.checkboxStyle {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  width: fit-content;
  margin: 0;
}

.checkboxStyle > input {
  height: 16px;
  width: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #a5889c;
  border-radius: 3px;
  outline: none;
  transition-duration: 0.3s;
  background-color: var(--color-white, #ffffff);
  cursor: pointer;
}
.checkboxStyle > input:hover {
  border: 1px solid #581845;
}

.checkboxStyle > input:checked {
  border: 1px solid #581845;
  background-color: var(--color-white, #ffffff);
}

.checkboxStyle > input:checked + .checkBoxTick::before {
  /* content: '\2711';
  display: block;
  text-align: center;
  color: var(--color-primary, #581845);
  position: absolute;
  left: 4px;
  font-size: 13px;
  font-style: normal;
  /* font-weight: bold;
  top: 1px; */
  content: '';
  position: absolute;
  border-color: #581845;
  border-style: solid;
  border-width: 0px 1.5px 1.5px 0;
  height: 10px;
  top: 9px;
  left: 6px;
  margin-top: -5px;
  transform: rotate(45deg);
  width: 5px;
}
.checkboxStyle > input:checked + .withOutLabel::before {
  top: 8px;
}
.withLabel {
  top: 0px;
  margin-left: 8px;
  white-space: nowrap;
}
.disabledStyled > input {
  cursor: not-allowed !important;
  /* background-color: var(--color-gary-8, #fafafa); */
  opacity: 0.5;
}
