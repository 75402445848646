.cardConatiner {
  padding: 5px;
  height: 105px;
}
.profile {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  top: 4px;
}
.profileContainer {
  /* margin-bottom: 8px; */
  /* padding-right: 16px; */
}
.jobList {
  /* margin-bottom: 4px; */
}
.unlockBtn {
  position: relative;
  top: 14px;
}
.btnUnlocked {
  max-width: 170px;
}
.btnUnlock {
  max-width: 152px;
}
.profileOverAll {
  margin-left: 4px;
}
.overAll {
  margin-top: 10px;
  width: 100%;
  margin-right: 5px;
  margin-left: 7px;
}

.jobTitle1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95px;
}
.line {
  width: 1px;
  height: 80px;
  background-color: rgb(165, 136, 156);
  margin-top: 8px;
  margin-right: 20px !important;
  /* z-index: 12; */
}
.pointer {
  cursor: pointer;
}
.relocateContainer {
  margin-top: 57px;
  /* padding-top: 6px; */
  margin-left: -116px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 65px;
}
.jobTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 83px;
}

@media screen and (min-width: 1411px) and (max-width: 1600px) {
  .jobTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 115%;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
@media screen and (min-width: 1600px) and (max-width: 3000px) {
  .jobTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
@media screen and (((min-width: 1000px) and (max-width: 1411px)) or ((min-width: 750px) and (max-width: 1000px))) {
  .jobTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .overAll {
    margin-top: 16px;
    width: 50%;
    margin-right: 16px;
    margin-left: 7px;
  }
  @media screen and (min-width: 750px) and (max-width: 1000px) {
    .jobTitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    .overAll {
      margin-top: 16px;
      width: 50%;
      margin-right: 16px;
      margin-left: 7px;
    }
  }
}

.paginationStyle {
  align-items: center;
  justify-content: center;
  display: flex;
  /* padding-top: 10px; */
}

.svgcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
