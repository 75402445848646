.overAll {
  width: 113px;
  /* width: 100%; */
}

.tagOne {
  margin-right: 8px;
}

.tagTwo {
  margin-left: 8px;
}

.marginTop16 {
  margin-bottom: 10px;
  position: relative;
}

.imgStyle {
  height: 110px;
  width: 110px;
  border-radius: 4px;
  margin-right: 16px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  object-fit: contain;
}

.colorPicker {
  position: absolute;
  z-index: 11;
  right: 0;
  top: 55px;
}

.headerColorPicker {
  position: absolute;
  z-index: 11;
  right: 0;
  top: 55px;
}

.colorInput {
  margin-right: 8px;
  flex: 6;
  position: relative;
}

.overAll {
  position: relative;
}

.imgContainer {
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  height: 120px;
  width: 120px;
  align-items: center;
  position: relative;
  background-color: #fff;
}

.changeStyle {
  position: absolute;
  border-radius: 4px;
  background-color: rgba(196, 196, 196, 1.8);
  cursor: pointer;
  height: 120px;
  width: 120px;
}

.svgCloseStyle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 99;
}

.fileStyle {
  display: none;
}

.imgStyle {
  height: 120px;
  width: 120px;
  border-radius: 4px;
  object-fit: cover;
}

.svgClose {
  position: absolute;
  bottom: 100px;
  right: 10px;
}


.overAllcard {
  position: relative;
  width: 130px;
}