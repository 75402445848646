.border-black {
  /* border: 1px solid var(--color-gary-2, #333333); */
  padding: 2px 8px 2px 8px;
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 13px;
  border: 1px solid #A5889C;
}
.border-gray {
  border: 1px solid var(--color-gary-4, #979797);
  padding: 2px 8px 2px 8px;
  border-radius: 4px;
  box-sizing: border-box;
}
.border-success {
  border: 2px solid var(--color-success, #34cc65);
  /* padding: 2px 8px 2px 8px; */
  padding: 6px 16px;
  border-radius: 4px;
  box-sizing: border-box;
}
.border-error {
  border: 1px solid var(--color-error, #f94949);
  padding: 2px 8px 2px 8px;
  border-radius: 4px;
  box-sizing: border-box;
}
.border-yellow {
  border: 1px solid var(--color-secondary, #ffc203);
  padding: 2px 8px 2px 8px;
  border-radius: 4px;
  box-sizing: border-box;
}
.border-green {
  /* border: 1px solid var(--color-gary-2, #333333); */
  padding: 2px 8px 2px 8px;
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 13px;
  border: 1px solid #A5889C;
}