.inputStyle {
  border-radius: 4px;
  border: 1px solid #d7c7d2 !important;
  outline: none;
  font-size: 13px;
  background-color: var(--color-white, #ffffff);
  width: 100%;
  box-sizing: border-box !important;
  font-family: 'Roboto', sans-serif;
  padding: 4px 12px 4px 12px;
}
.inviteBtnLoader {
  /* padding: 6px 12px 5px 12px; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 59px;
  height: 30px;
  margin-right: 16px;
}
.inputStyle:hover {
  border: 1px solid #58184570 !important;
}
.inputStyle:focus {
  border: 1px solid var(--color-primary, #581845) !important;
}

.inputStyle::placeholder {
  font-size: 13px;
  color: #888888;
}
.marginTop {
  margin-top: 16px;
}

.muModalpadding {
  padding: 9px;
}

.verticalLine {
  border-bottom: 0.5px solid #581845;
  /* padding: 5px 0px; */
  margin-bottom: 5px;
}
.verticalLineBottom {
  border-bottom: 1px solid #c3c3c3;
  margin-bottom: 5px;
}
.checks {
  /* position: absolute; */
  /* top: -14px; */
  /* right: 0px; */
  margin-top: -2px !important;
}
