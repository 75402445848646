.overAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 25px 25px 20px 25px;
}
.title {
  /* height: 40px; */
  /* background-color: var(--color-primary, #581845); */
  align-items: center;
  border-radius: 4px;
  /* padding: 0 16px; */
}
.scrollStyle{
  overflow: scroll;
  /* padding: 20px; */
}
.addBtn {
  margin: 0 16px;
}
.inputContainer {
  /* padding: 16px; */
  padding: 10px 0px 10px 0px;
}
.noContent {
  padding: 16px;
  overflow: hidden;
}

.jobTitle {
  height: 36px;
  background-color: var(--color-gary-8, #fafafa);
  display: flex;
  align-items: center;
  padding-left: 16px;
  border: 1px solid var(--color-gary-5, #cccccc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.error{
  position: absolute;
}
.totalsearchcount{
  position: relative;
  left: 10px;
  top: 6px;
}
.alignrow{
  display: flex;
  flex-direction: row;
}
.scroll{
  overflow: scroll;
  padding: 10px 15px 10px 10px;
}
.btnstyle{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.border{
  border-right: 0.5px solid #C3C3C3;
  margin-right: 10px;
  margin-left: 10px;
}
/* .paddingtitle{
  padding: 10px 0px 0px 10px;
} */

.normalStyle p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  /* margin: 0; */
  /* margin-top: 8px; */
  text-align: justify;
  /* margin: 0; */
}

  .normalStyle li{
    list-style: disc;
    margin-left: 25px;
    }
    .normalStyle h6{
      font-size: 13px;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      }
    .bordertop{
      border-top: 0.5px solid #C3C3C3;
      width: 100%;
      margin-top: 10px;
     
    }
    .boxshadow{
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
    }
    .cardstyle {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
     
      padding: 10px;
      background-color: #fff;
     
      border-radius: 8px;
      box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.16); 
    }
    