.overAll {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 13px;
    /* overflow: hidden; */
    height: 100%;
  }
  .profilehight {
    height: auto !important;
    overflow-y: auto;
  }
  @media screen and (min-width: 1280px) and (max-width: 1350px) {
    .overAll {
      margin: auto !important;
      padding: 0px 5% !important;
    }
  }
  @media screen and (min-width: 960px) and (max-width: 1280px) {
    .overAll {
      padding: 0px 0px !important;
    }
  }
  
  @media screen and (max-width: 960px) {
    .overAll {
      padding: 0 16px;
    }
  }
  .title {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .borderStyle {
    padding: 0 20px;
    border: 1px solid var(--color-gary-7, #dfdfdf);
    border-top: 2px solid var(--color-gary-7, #dfdfdf);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .with80 {
    width: 80%;
  }
  .titleContainer {
    padding: 10px;
    background-color: #eee8ec;
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
    color: #581845;
    justify-content: space-between;
    position: relative;
    padding-right: 40px;
  }
  .triangle {
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-right: 30px solid white;
    border-bottom: 22px solid transparent;
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .companyrow {
    margin-top: 10px;
    width: 80%;
  }
  .overFlow {
    overflow-y: scroll;
  }
  
  .warningText {
    margin-left: 8px;
  }
  .warningFlex {
    padding: 6px 8px;
    border: 1px solid var(--color-warning, #ffb142);
    background-color: rgba(255, 165, 0, 0.1);
    border-radius: 4px;
    width: fit-content;
  }

  .overallhead {
    background-color: #eee8ec;
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
  
  .tabContentContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }
  .ribbon{
    background-color: #EEE8EC;
    height: 40px;
  }
  .triangle {
  
  width: 0px;
    
  height: 0px;
    
  border-top: 26px solid transparent;
    
  border-right: 30px solid white;
    
  /* border-bottom: 20px solid transparent; */
    
  position: relative;
    
  top: -1px;
    
  right: 0px;
  
    
    }
  