.title {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 5px;
}
.overAll {
  /* border: 1px solid var(--color-gary-7, #dfdfdf); */
  padding: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 24px;
  font-size: 24px;
}
