.jobTitle {
  /* padding: 7px; */
  font-size: 14px;
}
.titleCard {
  padding: 4px 2px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  -moz-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}
.titleCard:hover {
  box-shadow: 0px 0px 8px 1px rgba(225, 225, 225, 1) !important;
  -webkit-box-shadow: 0px 0px 8px 1px rgba(225 225 225 /10%) !important;
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  transition: all 0.2s ease-out;
}
.overAll {
  /* margin-bottom: 40px; */
  position: relative;

  /*box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);*/
  /*-webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);*/
  /*-moz-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);*/
}
.hideStyle {
  position: absolute;
  width: 100%;
  z-index: 11;
  margin: 5px 0px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.75;
  /* -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px); */
  background-color: #b3b3b3;
}
.noText {
  padding: 8px 16px;
  border: 3px solid #581845;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.18);
}
.line {
  border-bottom: 0.5px solid #581845;
  padding: 7px 2px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  font-size: 13px;
}
