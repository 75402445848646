.overAll {
  padding: 25px;
  position: relative;
  height: 90%;
}
.insideFlex {
  margin-bottom: 16px;
}
.svgEdit {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.titleStyle {
  margin-bottom: 8px;
  display: flex;
}
