.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
    justify-content: flex-start;
    cursor: pointer;
  }
  
  .with80 {
    width: 90%;
  }
  .input{
    font-size: 13px;
  }
  
  .columnGroup {
    display: flex;
    flex-direction: column;
  }
  
  .borderRightLine {
    padding-right: 60px;
    border-right: 1px solid #c3c3c3;
  }
  
  .paddingLeft {
    padding-left: 60px;
  }
  
  .container,
  .pipelineTitle,
  .colGroup {
    display: flex;
    flex-direction: column;
  }
  
  .container {
    width: 100%;
    padding: 0 20px;
  }
  
  .header,
  .rowGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
  }
  .colorPicker {
    position: absolute;
    z-index: 1000;
    top: 35px;
    left: 3%;
  
  }
  
  .header {
    width: 100%;
    padding: 10px 20px;
  }
  
  .header,
  .pipelineTitle {
    margin-bottom: 30px;
  }
  
  .rowGroup {
    width: fit-content;
    align-items: center;
  }
  
  .jobPipelineIcon {
    width: 24px;
    height: 24px;
  }
  /* 
  .title {
      margin-left: 2px;
  } */
  
  .equalRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  
  .pipelineStages,
  .otherSuggestions {
    flex: 3;
    display: flex;
    flex-direction: column;
  }
  
  .stagesCards {
    display: flex;
    flex-direction: column;
  }
  
  .stagesDesc {
    margin-bottom: 20px;
  }
  
  .pipelineCard {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 3px 3px 3px 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
    border-radius: 5px;
    width: fill-available;
    background-color: white;
    margin-right: 10px;
  }
  
  .drgIcon,
  .colorCircle {
    margin-right: 10px;
  }
  
  .colorCircle {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    border: none;
  }
  
  .editIcon {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  
  .stagesSuggestionTxt {
    margin-bottom: 20px;
  }
  
  .chipsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .chip {
    display: flex;
    width: fit-content;
    padding: 5px 10px;
    border: 1px solid #581845;
    border-radius: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .pillStyle {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px 2px 10px;
    width: fit-content;
    border: 1px solid #581845;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .pillbutton {
    border: none;
    background-color: unset;
  }
  .newBtn {
    /* background-color: #fff;
    border-color: #a5889c;
    border-width: 1px;
    cursor: pointer; */
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px 2px 10px;
    width: fit-content;
    border: 1px solid #581845;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .newBtn:hover,
  .newBtn:active,
  .newBtn:focus {
    background: unset;
    border-width: 1px;
  }
  .svgTick {
    margin-right: 4px;
    cursor: pointer;
  }
  
  /* .borderLine {
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 20px;
    margin-bottom: 20px;
  } */
  .svgContainer {
    display: flex;
    /* align-items: center; */
    margin-left: 5px;
    top: 16px;
    right: 0;
  }
  
  .svgTick {
    margin-right: 4px;
    cursor: pointer;
  }
  .svgTickMargin {
    margin-right: 4px;
    cursor: pointer;
    z-index: 11;
  }
  .svgTickDisable {
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: 0.5;
  }
  .tickStyle:hover path {
    fill: var(--color-gary-4, #979797) !important;
  }
  .svgClose {
    cursor: pointer;
    z-index: 11;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
  }
  .cancel {
    margin-right: 8px;
    background-color: #888888;
    border: none;
    border-radius: 5px;
  }
  .cancel:active, .cancel:focus{
    background-color: #888888;
    border: none;
  
  
  }
  .savebutton {
    margin-right: 15%;
    position: relative;
    top: 5rem;
    border-radius: 5px;
  }
  .bottomBorder {
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 50px;
  }
  .colorMenu{
    position: absolute; 
    z-index: 1;
    top: 35px;
    left: 35px;
    padding: 10px;
    width: 180px;
    /* height: 250px; */
    background-color: #fff;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
  }
  .colorButton{
    width: 30px;
    height: 30px;
    border-radius: 50px;
    position: relative;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  