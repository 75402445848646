.slace {
  margin: 0 2px 0px 2px;
}
.marginTop {
  margin-top: 0;
}

.inputOverAll {
  display: flex;
  position: relative;
  width: 100px;
  margin-right:-35px;
}
.svgContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 126px;
}
.svgTick {
  margin-right: 4px;
  cursor: pointer;
}
.svgTickMargin {
  margin-right: 4px;
  cursor: pointer;
  z-index: 11;
  margin-left: 0%;
}
.svgTickDisable {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.5;
}
.tickStyle:hover path {
  fill: var(--color-gary-4, #979797) !important;
}
.svgClose {
  cursor: pointer;

}
.pointer { 
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.locationStyle {
  cursor: pointer;
  max-width: 210px;
  width: 180px;
}
.width85 {
  min-width: fit-content;
  width: 100%;
}

.textworkexp{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-Left:-8px;
  width:100%;
}
.locationtab{
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1366px) {
  .locationtab{
    overflow: hidden;
  }
}

@media only screen and (min-width: 1366px) and (max-width:1920px) {
  .locationtab{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow:hidden;
    width: 190px
  }
}

@media screen and (min-width: 1920px) {
  .locationtab{
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.locationloader{
  position: relative;
  bottom: 26px
}

.locationinput{
  border-radius: 0px;
  padding: 0;
  border: none;
  border-bottom: 1px solid var(--color-gary-6, #b3b3b3);
  background-color: var(--color-white, #ffffff);
  width: 120%;
  height: 20px;
  box-sizing: border-box !important;
  font-family: 'Roboto', sans-serif;
}