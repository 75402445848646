.titleContainer {
  margin-bottom: 36px;
}
.loginText {
  margin-top: 16px;
}
.inputStyle {
  padding: 8px;
  border-radius: 9px;
  box-shadow: 0px 3px 1px #beadb9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ffffff;
  text-indent: 30px;
  height: 35px;


  /* border-radius: 4px; */
  /* border: 1px solid var(--color-gary-5, #cccccc) !important; */
  /* outline: none;
  font-size: 14px; */
  background-color: var(--color-white, #ffffff);
  width: 100%;
  /* box-sizing: border-box !important;
  font-family: 'Roboto', sans-serif;
  padding: 4px 12px 3px 12px; */
}
.inputStyle:hover {
  border: 1px solid var(--color-gary-6, #b3b3b3) !important;
}
.inputStyle:focus {
  border: 1px solid var(--color-primary, #581845) !important;
}

.inputStyle::placeholder {
  font-size: 12px;
  color: var(--color-gary-6, #b3b3b3);
}
.marginTop {
  margin-top: 16px;
}
.btnContainer {
  margin-top: 24px;
}
.inputPosition {
  position: relative;
}
.actionRightStyle {
  margin: 0;
  right: 8px;
  top: 2px;
  position: absolute;
}

.splitrows_1 {
  width: 50%;
  height: 100vh;
  background-color: #f0ebed;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.splitrows_2 {
  width: 50%;
  height: 100%;
}

.centers_aligh {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
}
.centers_aligh,
center {
  color: #581845;
}

.centers_aligh,
u,
a {
  color: #581845;
}

.centers_aligh,
u,
a:hover {
  color: #581845;
}

.centers_aligh_slider {
  width: 28%;
  position: absolute;
  top: 55%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.Logs_title {
  color: #581845;
  margin-bottom: 4px;
}

.textx {
  color: #581845;
}


.inputsp {
  padding: 8px;
  border-radius: 9px;
  margin-top: 20px;
  box-shadow: 0px 3px 1px #beadb9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ffffff;
  text-indent: 30px;
  height:35px;
}

.usericons {
  /* top: 26px;
  left: 0px;
  height: 25px;
   background-color: #D7C7D2; 
  width: 30px; */
  position: absolute;
  left: 10px;
  top: 30px;
}

.btnLog{
  padding: 8px;
  border-radius: 9px;
  box-shadow: 0px 3px 1px #beadb9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ffffff;
  text-indent: 30px;
  height: 35px;
  /* width: 100%; */
}

.rows {
  display: flex;
  flex-direction: row;
}

.logos {
  margin-top: 20px;
}

.login_button {
  width: 100% !important;
  margin-top: 20px;
  padding: 5px;
  border-radius: 5px;
  background-color: #581845;
  border: 2px solid #581845;
}

.login_button:hover {
  box-shadow: none;
}

.Passicons {
  position: absolute;
  left: 10px;
  top: 26px;
}
.row1 {
  background: url('../assets/images/Signup.png');
  width: 70%;
  height: 100vh; /* Set the height to 100% of the viewport height */
  background-size: cover;
  justify-content: center;
}

.form_body {
  height: 200px;
  width: 623px; 
  padding: 20px; 
  justify-content: center;
  background-color: #f0ebed;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 4px rgba(88, 24, 69, 0.1);
}

.center_aligh {
  width: 37%;
  position: absolute;
  top: 45%;
  left: 23%;
  transform: translate(-50%, -50%);
}

.container {
  margin-top: 50px;
  color:#581845;
}

.logo{
align-items: center;
}
