.overAll-normal {
  /* border: 1px solid var(--color-gary-7, #dfdfdf);
  border-radius: 4px; */
}
.overAll-outline {
  border: none;
}
.overAll-overAll {
  border: none;
}
.loader {
  position: absolute;
  left: 50%;
  bottom: 50%;
}
.emptyText {
  /* padding: 70px 0; */
  min-height: 50vh;
  display: flex;
  align-items: center;
}
.overFlowLoader {
  position: relative;
}
.rowScroll {
  overflow-y: scroll;
  height: 150px !important;
}
.emptyTextOne {
  position: relative;
  top: 80px;
}
.candidateempty{
  min-height: 150px;
  display: flex;
  align-items: center;
}