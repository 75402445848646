.jobTitleFlex {
  margin-top: 24px;
  /* width: 100%; */
}

.jobTitleText {
  margin-right: 8px;
  width: 68px;
}

.btnStyle {
  margin-left: 16px;
}

/* .overAll {
  background-color: red;
} */
.ribbon {
  background-color: #EEE8EC;
  height: 45px;
}

.triangle {

  width: 0px;

  height: 0px;

  border-top: 22px solid transparent;

  border-right: 30px solid white;

  border-bottom: 22px solid transparent;

  position: relative;

  top: 71;

  right: 43;


}

.dndBoardContainer {

  width: 100%;

}

.btnStyle {

  margin-right: 10px;

}




.titleContainer {

  padding: 10px;

  background-color: #eee8ec;

  font-size: 12px;

  margin-bottom: 10px;

  display: flex;

  color: #581845;

  justify-content: space-between;

  position: relative;

  padding-right: 40px;

}

.triangle {

  width: 0;

  height: 0;

  border-top: 22px solid transparent;

  border-right: 30px solid white;

  border-bottom: 22px solid transparent;

  position: absolute;

  top: 0;

  right: 0;

}

.jobstext {

  height: 30px;

  padding: 10px;

  background-color: #eee8ec;

  display: flex;

  align-items: center;

  color: #581845;

  font-size: 13px;

}

.boxstyle {
  text-transform: none !important;
  background-color: #f7f7f7;
  height: 30px;
  border: none !important;
  border-radius: 0 !important;
  scroll-behavior: smooth;
  /* width: 270px; */
  width: 300px;
  text-overflow: ellipsis;
}

.boxstyle1 {
  text-transform: none !important;
  background-color: #f7f7f7;
  height: 30px;
  border: none !important;
  border-radius: 0 !important;
  scroll-behavior: smooth;
  /* width: 270px; */
  width: 240px;
  padding: 3px 25px 3px 10px;
}

.boxstyle2 {

  text-transform: none !important;

  background-color: #f7f7f7;

  height: 30px;

  border: none !important;

  border-radius: 0 !important;

  scroll-behavior: smooth;

  /* width: 210px; */

  width: 230px;

  text-overflow: ellipsis;

}

.searchstyle {

  /* width: 412.27px; */

  display: flex;

  align-items: center;

  position: relative;
}

.searchboxoverall {

  border-radius: 0px 20px 20px 0px;

  background-color: #f7f7f7;

  height: 30px !important;

}

.middleline {

  margin-right: 10px;

  height: 25px;

  border: 1px solid #e4dae1;

  margin-top: 2px;

}

.locationicon {

  margin: 5px 0 5px 0;

}

.searchicons {

  height: 30px;
  padding: 10px;
  background-color: #581845;
  border-radius: 0px 20px 20px 0px;
  cursor: default;
  width: 35px
}

.scrollStyle {
  /* scrollbar-width: thin; */
  /* scrollbar-color: rgba(26, 26, 26, 0.3) white; */
  /* overflow-y: scroll; */
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {

  .scrollStyle {
    scrollbar-width: thin;
    scrollbar-color: rgba(26, 26, 26, 0.3) white;
    /* overflow-y: scroll; */
  }
  }

.col {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  z-index: 99;
}

.filterFlex {

  /* margin-right: 5px; */

  margin-left: 10px;

}

.tabsStyle {
  margin-top: 15px;
  position: relative;
}

.screenrow {
  justify-content: space-between;
}

.infiStyle {
  position: relative;
  right: 3px;
  top: 3px
}

.crossIcon {
  all: unset;
  cursor: pointer;
  padding-top: 5px;
  bottom: 38px;
  right: 28px;
  position: relative;
}

.css-tj5bde-Svg {
  fill: "#581845" !important;
}

.updateBtnLoader {
  margin-right: 10px;
  margin-bottom: 4px;
}

.process {
  color: #979797;
}

.overAllloader {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px 24px;
}

.des {
  max-width: 575px;
  margin-top: 8px;
}

.flexConatiner {
  margin-left: 9px;
  margin-top: 4px;
}

.btn {
  margin-top: 8px;
}

.jobcrossIcon{
  all: unset;
  cursor: pointer;
  padding-top: 5px;
  bottom: 0;
  position: absolute;
  right: 40px;
  top: 7px;
  display: flex;
  width: fit-content;
}