.titleTextStyle{ 
    padding: 0 8px;
    border-right: 0.5px solid #555555;
}
.titleTextStyle1{ 
    padding: 0 8px;
}

.titleTextStyle1{
    padding: 0 8px;
}