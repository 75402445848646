.errorMessageStyle {
  /* margin-top: 4px; */
  position: absolute;
  bottom: -22px;
}
.css-1elcgmh-menu {
  opacity: 1;
}

.color {
  background-color: red;
    
}
.customoptionhover:hover{
  background-color: #eee8ec;
}
.customoption{
  background-color: #581845;
  color: white;
  padding: 5px;
}
.customoptionhover{
  padding: 5px;
}
.disabledoption{
  cursor: not-allowed !important;
}