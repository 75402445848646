.titleBar {
    /* padding: 10px 0; */
    align-items: center;
    cursor: pointer;
}

.cardContent {
    padding-bottom: 10px;
}

.cardStructure {
    width: 350px;
    /* max-height: 160px; */
    /* margin-right: 20px; */
    /* padding: 20px 20px 0 20px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

}

.cardHeader {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.ribbon {
    background-color: #EEE8EC;
    height: 40px;
}

.triangle {
    width: 0px;
    height: 0px;
    border-top: 20px solid transparent;
    border-right: 30px solid white;
    border-bottom: 20px solid transparent;
    position: relative;
    top: 0px;
    right: 0px;
}


.overflowContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: auto;
  }