.sidebar {
  /* margin-top: 1px; */
  /* height: -webkit-fill-available; */
  /* z-index: 1; 
  top: 0;
  left: 0;
  bottom: 0; */
  height: 100vh;
  background-color: #eee8ec;
  overflow-x: hidden;
  padding-top: 16px;
  box-shadow: 2px 2px 2px #d7c7d2;
  display: flex;
  justify-content: center;
  margin-right: 5px;
}
ul {
  list-style-type: none;
}

.sidebar a {
  padding: 8px 8px 8px 10px;
  text-decoration: none;
  font-size: 20px;
  color: #581845;
  /* display: block; */
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  /* border-left: 6px solid #ffffff !important; */
  /* transition: width 0.3s ease; */
}

.select_row :hover {
  background-color: #d7c7d2;
}

.select_row {
  background-color: #d7c7d2;
}
