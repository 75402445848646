.msgText {
  border-bottom: 0.5px solid #581845;
  /* padding: 4px 2px; */
  padding: 5px 0px;
  margin-left: 10px;
  margin-right: 10px;
}
.profileStyle {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  /* margin-bottom: 10px; */
   margin-top: 4px; 
  font-size: 14px;
  align-items: center;
  position: relative;
  background-color: #fff;
}
/* .overAll{
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  -moz-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
} */
.mesgListCard {
  padding: 8px 8px;
  margin-top: 8px;
  /* box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  -moz-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%); */
  margin-bottom: 1px solid #581845;
}
.overAll:hover {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  transition: all 0.2s ease-out;
}

.scrollStyle {
  overflow-y: scroll;
  height: 450px;
  padding: 0px 7.5px 5px 11px;
}
.noContent {
  align-self: center;
}
.countStyle {
  /* position: absolute; */
  /* right: -4px;
  top: -4px; */
  margin-top: 1px;
  background-color: var(--color-error, #f94949);
  border-radius: 100%;
  z-index: 0;
  display: flex;
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
}
.overAll {
  width: auto;
  height: 295px;
  /* margin-top: 5px; */
  margin-right: 5 px;
}
/* .line{
  border: solid #581845;
 

} */
.borderbottom {
  border-bottom: 1px solid #c3c3c3;
  /* margin-top: 5px;
  margin-bottom: 5px; */
  padding: 5px 0px;
}

.messagesizereducer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}
.readStyle {
  width: 8px;
  height: 8px;
  background-color: #581845 !important;
  border-radius: 100%;
}
.countStyleinner {
  position: absolute;
  right: -3px;
  top: 3px;
  background-color: var(--color-error, #f94949);
  border-radius: 100%;
  z-index: 1;
  display: flex;
  height: 12px;
  width: 12px;
  justify-content: center;
  align-items: center;
}
