.commonStyle {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow:0 1px 4px 0 rgb(0 0 0 / 47%); 
   box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%); 
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);

}

/* .commonStyle:hover {
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.4);
} */
