.overAll {
  /* background-color: var(--color-primary, #581845); */
  /* padding: 8px; */
  margin-bottom: 44px;
  width: 270px;
  position: relative;
  /* box-shadow: 3px 0 5px -2px rgba(238, 232, 236, 1); */
  /* color: #333333; */
}
.countStyle1 {
  position: absolute;
  right: 83px;
  /* top: 1px; */
  top: -3px;
  background-color: #ed4857;
  /* border: 0.1px solid #a5889c; */
  border-radius: 100%;
  z-index: 11;
  display: flex;
  height: 27px;
  width: 27px;
  justify-content: center;
  align-items: center;
}
.countStyle2 {
  position: absolute;
  right: 80px;
  /* top: 1px; */
  top: -6px;
  background-color: #eeac17;
  /* border: 0.1px solid #a5889c; */
  border-radius: 100%;
  z-index: 11;
  display: flex;
  height: 27px;
  width: 27px;
  justify-content: center;
  align-items: center;
}
.countStyle3 {
  position: absolute;
  right: 80px;
  /* top: 1px; */
  top: -6px;
  background-color: #00be4b;
  /* border: 0.1px solid #a5889c; */
  border-radius: 100%;
  z-index: 11;
  display: flex;
  height: 27px;
  width: 27px;
  justify-content: center;
  align-items: center;
}
.curser {
  cursor: pointer;
}
.useNameStyle {
  margin-left: 8px;
}
.marginStyleFlex {
  margin-right: 16px;
}
.fontLable {
  margin-bottom: 8px;
}
.nameStyle {
  margin-bottom: 16px;
}
.marginBottom {
  margin-bottom: 8px;
}
.fontLabelStyle {
  margin-bottom: 8px;
  width: 150px;
  white-space: nowrap;
}
.btnStyleOne {
  max-width: 124px;
}
.btnContainer {
  margin-top: 8px;
}
.flexNameConatiner {
  margin-left: 156px;
}
.barStyle {
  position: relative;
}
.matchStyle {
  position: relative;
  top: 3px;
}
.svgDownloadStyle {
  cursor: pointer;
}
.nonMatch {
  padding: 1px 8px;
  background-color: var(--color-gary-5, #cccccc);
  width: fit-content;
  box-sizing: border-box;
  border: 5px solid var(--color-error-1, #d74242);
  border-radius: 4px;
  margin-top: 16px;
}
.imageStyle {
  border-radius: 100%;
  height: 70px;
  width: 70px;
  border-radius: 100%;
  margin-right: 16px;
  position: absolute;
  top: 12px;
  left: 16px;
}
.inviteStyle {
  margin-left: 8px;
  cursor: pointer;
}
.inviteIconClassName {
  pointer-events: none !important;
  opacity: 0.3;
}
.nonMatchFlex {
  position: relative;
  top: 5px;
}
.inviteIconNone {
  display: none !important;
}

.profile {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.profileimg {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  /* position: relative;
  margin-right: 16px; */
  /* position: absolute; */
  top: 12px;
  left: 16px;
  /* background-image: linear-gradient(
    to right top,
    #581845,
    #982a4e,
    #d04e46,
    #f3832f,
    #fcc203
  ); */
}
.headerpart {
  font-size: 13px;
  border-bottom: 1px solid #c3c3c3;
  padding: 0px 8px 0px 8px;
}
.headerpart1 {
  font-size: 13px;
  border-bottom: 1px solid #c3c3c3;
}
.headingpart {
  font-size: 13px;
  color: #581845;
  font-weight: 400;
}
.changingtext {
  font-size: 13px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.changingtextsource {
  font-size: 13px;
  max-width: 121px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.changingtexts {
  font-size: 13px;
  white-space: 'nowrap';
  text-overflow: 'ellipsis';
  overflow: ' hidden';
  max-width: '200px';
}
.changingtexttype {
  font-size: 13px;
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.borderstyles {
  border-top: 1px solid #c3c3c3;
  padding-top: 10px;
}
.starstyle i {
  font-size: 20px;
  cursor: default;
}
.starratingoverall ul li {
  font-size: 24px !important;
  cursor: default !important;
}
.starratingoverall ul {
  margin-top: -26px;
  margin-bottom: 20px;
}
.phoneInput {
  border: none !important;
  padding: 0 !important;
  height: fit-content !important;
  color: #212529 !important;
  font-size: 14px !important;
  background-color: transparent !important;
  width: fit-content !important;
}
.phoneHide {
  pointer-events: none;
  max-width: 155px;
}
.phoneHide div div {
  display: none;
}
.drop1 {
  position: absolute;
  border-radius: 5px;
  box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, 0.36);
  display: flex;
  z-index: 2;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  align-items: center;
  justify-content: center;
}
.drop {
  position: relative;
}
.pointer {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.favicon {
  position: absolute;
  right: 5px;
  top: 3%;
  transform: translateY(-50%);
  cursor: pointer !important;
}

.svgDownloadStyle {
  cursor: pointer;
}
.downloadspin {
  cursor: pointer;
  justify-content: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #581845;
  /* margin-right: 5px; */
  margin-right: 2px;
  margin-bottom: 3px;
}
.loderboder {
  --bs-spinner-border-width: 2px;
}
