.svgRefresh {
  margin-left: 8px;
  cursor: pointer;
}
.overAllfilter {
  position: relative;
  z-index: -1;
  width: 300px;
  padding: 3px 10px;
  padding-bottom: 15px;
}
.filterStyle {
  padding: 8px 0px 8px 0px;
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
}
.jobTextStyle {
  /* color: #581845; */
  font-weight: bold;
  font-size: 13px;
}
.matchRadioStyle {
  margin-bottom: 8px;
  width: 150px;
  display: flex;
  word-wrap: break-word;
}
.qualificationRadioStyle {
  margin-bottom: 8px;
}
.qualificationTextStyle {
  margin-top: 16px;
  margin-bottom: 2px;
}
.filterContainer {
  padding: 16px;
  overflow-y: scroll;
}
.filtername {
  font-weight: 400;
}
.filterContainer::-webkit-scrollbar {
  width: 4px;
  margin-left: 16px;
  height: 4px;
  overflow-y: scroll;
}
.expContainer {
  padding-top: 16px;
}
.locantionStyle {
  margin-top: 16px;
}
/* .skillContainer { */
/* position: relative;
  z-index: -1; */

/*margin-bottom: 13px;*/
/* } */

.matchRadioStyling {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.filter_ali {
  float: right;
  position: relative;
}

.drop_down {
  position: relative;
  float: right;
  margin-bottom: 20px;
}

.drop_down_header {
  padding: 0px 10px;
  background-color: #ffff !important;
  width: 230px;
  /* height: 30px; */
  border: 1px solid #581845;
  border-radius: 11px;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
/* #typePrompt:hover  */
.hoverbo.hover {
  display: block;
}

.hoverbo {
  display: none;
}
.drop_down_menus {
  /* background-color: rgb(142, 142, 245);
  margin:0px;
  border-radius: 23px;
  display: none;
  position: absolute;
  background-color: white;
  border-style: solid;
  border-color: #581845;
  padding: 10px;
  width: 235px;
  height: 425px; */

  background-color: #fff;
  position: absolute;
  background-color: var(--color-white, #fff);
  border-radius: 12px;
  max-height: 480px;
  overflow-y: scroll;
  width: 230px;
  display: none;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47000000000000003);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47);
  /* scrollbar-width: thin; */
  z-index: 10;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .drop_down_menus {
    scrollbar-width: thin;
  }
}

::-webkit-scrollbar {
  width: 3px; /* You can adjust this value to change the width of the scrollbar */
}

.show {
  display: block;
  /* z-index: 9999999; */
}

.filtername {
  display: flex;
  font-style: normal;
  color: #581845;
  font-weight: 600;
  font-size: 16px;
  /* margin-bottom: 5px; */
}
.mtstyle {
  color: #581845 !important;
  border-top: 1px solid #dee2e6;
  padding: 12px;
}

.alldefl {
  border-style: solid;
  border-color: #a5889c;
  padding: 3px 9px 3px 7px;
  margin-left: 12px;
  border-radius: 16px;
}
.quickfil {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px 2px 10px;
  width: fit-content;
  border: 1px solid #581845;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 13px !important;
}
.checkboxalign {
  flex-wrap: wrap;
  /* width: 230px; */
}
.quickfilonlyall {
  border-style: solid;
  border: 2px solid #a5889c;
  cursor: default;
  margin-left: 15px;
  padding-left: 10.5px;
  padding-top: 5px;
  padding-bottom: 5.27px;
  padding-right: 15px;
  border-radius: 16px;
  color: #581845;
}

.stylesvg {
  margin-left: 10px;
}
.checkbox {
  color: #581845;
}

.boxstyle {
  text-transform: none !important;
  height: 35px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jobTitleBoxstyle {
  text-transform: none !important;
  height: 35px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px 18px 3px 12px !important;
}
.selectoptions {
  margin-top: 5px;
}
.filterContainers {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: sticky;
  background-color: #fff;
}
.toggletext {
  margin-left: 5px;
  font-size: 13px;
  top: 1px;
  position: relative;
  /* font-weight: bold; */
  color: #333333 !important;
}

.filtersvg {
  transform: rotate(270deg);
}
.crossIcon {
  all: unset;
  cursor: pointer;
  /* padding-top: 17px; */
  bottom: 56px;
  right: 13px;
  position: absolute;
}

.jobTitleCloseIcon {
  position: absolute;
  top: 65%;
  right: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
}