.btnContainer {
  padding: 16px 0;
}
.overAll {
  margin-bottom: 16px;
}

.active {
  cursor: auto;
}

.Inactive {
  pointer-events: none;
  position: relative;
  box-sizing: border-box !important;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  padding: 3px 12px 2px 12px;
  width: fit-content;
}
.subscriptionLink{
color: blue;
cursor: pointer;
}
.Inactive {
  border: 2px solid #f94949;
  background-color: #f94949;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.Inactive:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.2);
}

.Inactive:focus {
  border: 2px solid #f94949;
  background-color: #f94949;
  box-shadow: none;
}

.boxshadow{
  background-color: #D7C7D24D;
  /* padding: 15px 20px 15px 20px; */
  padding: 10px 5px 10px 5px;
  margin-right: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
}
.boxshadow1{
  background-color: #D7C7D24D;
  padding: 15px 33px 15px 33px;
  margin-right: 28px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
}
.textstyle{
  color: #581845;
  display: flex;
  justify-content: center;
}