.tableStyle {
  margin-top: 10px;
  margin-left: 5px;
}
.warningFlex1 {
  padding: 6px 8px;
  border: 1px solid #2e6add;
  background-color: #2e6add20;
  border-left: 8px solid #2e6add !important;
  border-radius: 8px;
  width: fit-content;
  font-size: 13px;
  margin-bottom: 15px;
  margin-left: 6px;
}
.warningText1 {
  margin-left: 3px;
  font-size: 13px;
}

.ribbon {
  background-color: #EEE8EC;
  height: 40px;
}

.triangle {
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-right: 30px solid white;
  border-bottom: 20px solid transparent;
  position: relative;
  top: 0px;
  right: 0px;
}