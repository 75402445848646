.previewBtn {
  margin-right: 8px;
}

.marginTop16 {
  margin-top: 10px;
  margin-bottom: 30px;
}

.actions {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 40%;
  background-color: white;
  padding: 10px;

}

.leftFlex {
  overflow: hidden;
  /* margin-left: 8px; */
  /* margin-right: 1px; */
  padding: 0 10px 0px 16px;
  height: 100%;
}

.rightFlex {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-right: 8px; */
  /* margin-left: 1px; */
  padding-bottom: 10px;
  height: 95%;
}

.careerPageCard {
  margin-top: 10px;
  padding: 8px;
}

.pageCard {
  margin-top: 16px;
  padding: 8px;
}

.headerCard {
  padding: 8px;
  margin-top: 16px;
}

.bannerCard {
  padding: 8px;
  margin-top: 16px;
}

.footerCard {
  padding: 8px;
  margin-top: 16px;
}

.ribbon {
  background-color: #EEE8EC;
  height: 40px;
}

.triangle {
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-right: 30px solid white;
  border-bottom: 20px solid transparent;
  position: relative;
  top: 0px;
  right: 0px;
}

.warningText {
  margin-left: 8px;
  font-size: 13px;
}

.warningFlex {
  padding: 6px 8px;
  border: 1px solid #2E6ADD;
  background-color: #2E6ADD20;
  border-left: 8px solid #2E6ADD !important;
  border-radius: 8px;
  width: fit-content;
  margin: 0 16px;
  margin-top: 15px;
  font-size: 13px;


}