.pointer {
  cursor: pointer;
}
.overAll {
  padding: 16px;
  /* width: 700px; */
  overflow: scroll;
}
.border {
  /* border: 1px solid var(--color-gary-6, #b3b3b3); */
  border-radius: 4px;
  padding: 12px;
}

.preStyle {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0;
  margin-top: 8px;
  text-align: justify;
}
.resumeStyle {
  margin-top: 24px;
  margin-bottom: 16px;
}
.resumeStyleOne {
  margin-bottom: 16px;
}
.notText {
  padding: 50px 0px;
}
.textCenter {
  color: var(--color-gary-4, #979797);
}
