.cardStyle {
  padding: 16px;
  /*box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);*/
  /*-webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);*/
  /*-moz-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);*/
}
.jobActivityCard:hover {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  transition: all 0.2s ease-out;
}
.overall {
  border-bottom: 0.5px solid #581845;
  margin-left: 10px;
  margin-right: 10px;
}
.borderBottom {
  font-size: 14px;
  padding: 10px 5px;
}
.title {
  text-overflow: ellipsis; 
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
   max-width: 295px;
}
.jobActivityCard {
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 5px;
  margin-top: 6px;
}
.cardStyle {
  padding: 16px 20px;
  padding-bottom: 0px;
  margin-bottom: 24px;
}
.listFlex {
  margin-bottom: 16px;
}
.valueStyle {
  margin-left: 2px;
}
.buttondisable {
  cursor: none;
  pointer-events: none;
  border-style: none;
  width: auto;
  height: 30px;
}
.buttonflex {
  background-color: #eee8ec;
  border-radius: 20px;
  width: auto;
  height: 30px;
}
.scroll {
  max-height: 280px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.line {
  border: 0.5px solid #581845;
}
.circleflexover {
  height: 26px;
  line-height: 26px;
  width: auto;
  font-size: 1em;

  border-radius: 20%;
  background-color: #eee8ec;
  text-align: center;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card {
  padding: 15px;
  width: 100%;
  height: auto;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 columns */
}

.gridItem {
  margin: 10px 8px;
}
.box {
  border: 1px solid rgb(242, 237, 241);
  background: rgb(242, 242, 242);
  border-radius: 4px;
  padding: 10px;
}
