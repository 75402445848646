.overAll {
  position: relative;
  width: 36%
}
/* .round { */
  /* height: 25px; */
  /* width: 100%; */
  /* border-radius: 100%; */
  /* align-items: center; */
  /* justify-content: center; */
  /* display: flex; */
  /* position: absolute; */
/* } */
.round {
  height: 30px;
  width: 100%;
  /* border-radius: 100%; */
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
  top: -10px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.stepOne{
  height: 30px;
  width: 100%;
  /* border-radius: 100%; */
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
  top: -10px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
/* .bar {
  height: 4px;
  width: 100%;
  display: none;
} */
.roundeSelect {
  
  background-color: #b1c851
}
.roundeNonSelect {
  background-color: var(--color-gary-5, #cccccc);
}
.barNonSeletc {
  background-color: var(--color-gary-5, #cccccc);
}