.svgRefresh {
  margin-left: 16px;
  cursor: pointer;
}
.button {
  border: none;
  background: none;
}
.filterStyle {
  padding-left: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
}
.matchRadioStyle {
  width: 116px;
  margin-bottom: 8px;
}
.matchTextStyle {
  margin-bottom: 8px;
  margin-top: 16px;
}
.profileTextStyle {
  margin-bottom: 8px;
  margin-top: 8px;
}
.qualificationTextStyle {
  margin-top: 16px;
  margin-bottom: 8px;
}
.qualificationConatiner {
  padding-top: 8px;
  padding-bottom: 8px;
}
.checkBoxContainerOne {
  min-width: 116px;
}
.checkBoxContainer {
  padding-bottom: 16px;
  padding-top: 8px;
}
.checkBoxOne {
  padding-bottom: 16px;
}
.showCheckBox {
  margin-top: 24px;
}
.cardConatiner {
  /* height: 221px;
    width: 460px;
    margin-right: 0px;
    padding: 15px 10px 10px 10px;
    display: flex;
    gap: 44px;
    margin-left: 41px;
    margin-top: 35px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; */
  position: relative;
  max-height: 190px;
  min-width: 350px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 6px;
  padding: 15px 10px 7px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 5px 5px #888888;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 3px 0px 5px -1px rgba(0, 0, 0, 0.2);
}
.cardConatiner:hover {
  /* box-shadow: 5px 5px 10px #a5889c; */
  box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
  transition: all 0.2s ease-out;  
}
.scrollFlex {
  overflow-y: scroll;
  padding-left: 14px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-top: 12px;
  /* scrollbar-width: thin; */
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .scrollFlex{
    scrollbar-width: thin;
  }
}

.scrollFlex::-webkit-scrollbar {
  width: 4px;
  margin-left: 16px;
  height: 4px;
  overflow: scroll;
  scrollbar-color: rgba(26, 26, 26, 0.3) white;
}

.title {
  margin-left: 10px;
  margin-top: 10px;
}
.setting {
  width: 130px;
  height: 110px;
  position: relative;
  margin-left: 100px;
  /* position: 'absolute', */
}
.gird {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal columns */
  /* grid-template-rows: auto;  */
  gap: 10px;
}
.window {
  width: 500px;
  height: 150px;
  margin-left: 10px;
  margin-top: 10px;
}
.dropDownListStyle {
  cursor: auto;
}
.rowGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cardHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.titletext {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 240px;
}
.copybutton {
  /* margin-top: 10px;
  margin-left: 15px; */
  border: none;
  background: none;
  margin-left: 15px;
  cursor: pointer;
}
.btnsetting {
  /* margin-right: 10px; */
  /* margin-top: 10px;
  margin-left: 70px; */
  border: none;
  background: none;
}

/* .rowGroup1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
} */
.title2 {
  margin-left: 10px;
  margin-top: 10px;
}
.initials {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: #fcc203;
  margin-right: 10px;
  margin-top: 10px;
}
.textinitials {
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.line {
  /* height: 1px; */
  margin: 15px 0px 7px 0px;
  /* width: 440px; */
  border: 0.5px solid #c3c3c3;
  display: flex;
  width: 100%;
}

.rowGroup2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  /* margin-top: 10px; */
  /* margin-top : -13px; */
}
.previewbtn {
  border: none;
  background: none;

  /* margin-top: 10px; */
  /* margin-left: 15px; */
}
.buttonshare {
  background-color: white;
  /* padding: 3px; */
  /* margin-top: 15px; */
  /* height: 40px; */
  /* width: 90px; */
  /* margin-left: 300px; */
}
.sharetxt {
  color: black;
}

.overflowbtn{
  max-width: 332px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.pointer{
  cursor: pointer;
}