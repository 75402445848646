.cardOverAll {
  max-width: 590px;
  padding: 20px;
}
.cardOverAll:hover{
  background-color: #eee8ec;
}
.titleStyle {
  margin-bottom: 16px;
}
.exStyle {
  margin-top: 16px;
  margin-bottom: 16px;
}
.desText {
  letter-spacing: -0.2px;
}

