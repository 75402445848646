.filter {
  margin-top: 6%;
  margin-left: 40%;
}
.filterButton {
  margin-top: 6%;
}

.dropDown {
  background-color: rgba(229, 229, 229, 0.6);
  width: 200px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dropDownBtn {
  max-width: 165px;
  height: 30px;
  outline: none;
  border-radius: 6px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  margin: 0;
  background-color: unset;
  border: 1px solid #c3c3c3;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 13px !important;
}

/* .dropDownBtn div {
  width: 18px;
  height: 18px;
  align-self: center;
} */

.dropDownCard {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  right: 0%;
}

.dropDownCard > div {
  width: 160px;
  z-index: 100;
  position: relative;
}

.showDropDownCard {
  display: block;
}

@media screen and (min-width: 768px) {
  .filterButton {
    margin-left: 40%;
  }
}
@media screen and (min-width: 1024px) {
  .filterButton {
    margin-left: 50%;
  }
}
@media screen and (min-width: 1440px) {
  .filterButton {
    margin-left: 65%;
  }
}
@media screen and (min-width: 2560px) {
  .filterButton {
    margin-left: 80%;
  }
}
.noContent {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5%;
  margin-left: -15%;
}

.filterCard {
  padding: 4%;
  width: 170%;
  position: absolute;
  right: 0;
}

.filter_wrapper {
  width: fit-content;
  background-color: var(--color-primary-light);
  border-radius: 20px;
  display: flex;
  height: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.filter_wrapper button {
  padding: 0px 15px;
  border-radius: 100px;
  outline: none;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  transition: all 1s ease-in-out;
  font-size: 13px;
}

.filter_wrapper button.activeFilter {
  background-color: var(--color-primary);
  color: white;
}

.dropdownFilter {
  position: absolute;
  right: 0;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  top: calc(100% + 6px);
  padding: 16px;
  border-radius: 5px;
  z-index: 1000;
  display: none;
}

.overlay {
  position: fixed;
  background-color: darkorange;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  bottom: 0;
}

.myEventsOptions {
  display: flex;
  justify-content: space-between;
  margin: 4px 0px;
}

.myEventsOptions p {
  margin: 0;
}

.headerMenu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  padding: 0px 10px;
  margin: 7px 0px;
}


.calendarLogo p {
  margin: 0;
  font-size: 1.3rem;
  margin: 0 10px;
}

.calendarInputs {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: flex-end;
}

.calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 125px) !important;
  padding: 0px 2px;
  margin-top: 12px;
}

.scheduleButton {
  background-color: var(--color-primary);
  border-radius: 5px;
}

.scheduleButton span {
  font-weight: normal;
  letter-spacing: 1px;
}

.calenderContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.calendar > .rbc-time-view {
  height: 62% !important;
}


