.overAll {
  /* padding: 16px 24px; */
  /* margin-top: 24px;*/
}

.SelectTag__control--is-focused {
  padding-bottom: 126px;
}

.split {
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
  width: 100%;
  padding: 0px 10px 35px 0px;
}
.jobTitle {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
}
.containerOne {
  margin-top: 16px;
}

.margin16 {
  margin-right: 16px;
  position: relative;
}
.postion {
  position: relative;
}

.btnContainer {
  margin-top: 8px;

  bottom: 10px;
  right: 0;
  left: 2px;
  /* border-top: 1px solid #dfdfdf; */
  padding: 5px;
  background-color: #ffffff;
}
.draftBtn {
  margin: 0 16px;
}
.showStyle {
  margin-top: 26px;
}
/* Assuming this is the dropdown container */
.dropdown-container {
  position: relative;
}

/* Style the dropdown */
.dropdown {
  /* Your existing styles for the dropdown */
}

/* Increase padding bottom when dropdown is active */
.dropdown.active {
  padding-bottom: 150px; /* Adjust this value as needed */
  overflow-y: auto; /* Show scrollbar if content exceeds the height */
}
.updateBtnLoader {
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 68px;
  height: 30px;
  /* margin-top: 10px; */
}
