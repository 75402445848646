.overAll {
  padding: 25px;
  background-color: #ffffff;
  border-radius: 4px;
}
.warningFlex {
  padding: 8px 16px;
  border: 1px solid var(--color-warning, #ffb142);
  background-color: rgba(255, 165, 0, 0.1);
  border-radius: 4px;
  width: fit-content;
  max-width: 1196px;
  margin-bottom: 28px;
}
.warningFlex {
  padding: 6px 8px;
  border: 1px solid #2E6ADD;
  background-color: #2E6ADD20;
  border-left: 8px solid #2E6ADD !important;
  border-radius: 8px;
  width: fit-content;
  margin-top: 15px;
  font-size: 13px;
}
.warningText {
  margin-left: 8px;
  font-size: 13px;
}
