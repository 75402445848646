.overAll {
  padding: 16px; 
  margin-top: 8px;
  overflow-y: scroll;
}

.messageContainer {
  border: 1px solid var(--color-gary-6, #b3b3b3);
  border-radius: 4px;
  margin-top: 8px;
}
