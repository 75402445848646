/* Add these styles to your existing or new CSS file */

/* Styles for the main menu */
.navbar-nav {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .nav-item1 {
    position: relative;
  }
  
  .nav-link2 {
    padding: 20px 0;
    margin: 0 17px;
    display: block;
    color: #581845;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    transition: all 0.3s ease-in-out;
  }
  
  /* Styles for the dropdown */
  .dropdown-menu1 {
    display: none;
    position: absolute;
    top: 88%;
    left: 0;
    background-color: #fff; 
    background-clip: none; 
    border: none;
    min-width: 270px;
    z-index: 1;
    /* z-index: 1;
      background-color: #fff;
      border-radius: 0;
      display: block;
      right: 0;
      left: 0;
      padding: 8px 0 10px;
      top: 100%;
   
      transform: scale(1, 0);
      opacity: 0;
      min-width: 270px;
      box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
      margin: 0;
      transform-origin: 0 0;
      transition: all 0.3s ease-in-out; */
  }
  
  .nav-item1:hover .dropdown-menu1 {
    display: block;
  }
  
  .dropdown-item1 {
    
    display: block;
    color: #333; 
    text-decoration: none;
    font-weight: 500;
    line-height: 45px;
    text-transform: capitalize;
    color: #333333;
    padding: 0 5px 0 25px;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
  
  
  }
  
  .navbar .dropdown-menu1 .dropdown-item1:hover {
    color: #581845;
  }
  
  
  .navbar .dropdown-menu1 .dropdown-item1:hover, .navbar .dropdown-menu1 .dropdown-item1:focus, .navbar .dropdown-menu1 .dropdown-submenu:hover > .dropdown-item1 {
    padding-left: 30px;
    background: #f0ebed;
  }
  
  .signupnav{
  
      font-size: 15px;
      line-height: 35px;
      background: #581845;
      border-radius: 5px;
      color: #fff;  
       
      letter-spacing: -0.3px;
      padding: 0 12px;
      transition: all 0.3s ease-in-out;
      margin-left: 10px;
  }
  .signupnav1{
   
      font-size: 15px;
      line-height: 35px;
      background: #eeeeee;
      border-radius: 5px;
      color: #581845;
      letter-spacing: -0.3px;
      padding: 0 12px;
      transition: all 0.3s ease-in-out;
     
      
  }
  .loginnav{
    
    font-size: 15px;
    /* border: 1px solid black; */
    line-height: 35px;
    /* background: #581845; */
    border-radius: 5px;
    color: #581845;
    
    font-weight: 500;
    letter-spacing: -0.3px;
    padding: 0 12px;
    transition: all 0.3s ease-in-out;
    margin-left: 10px;
  }
  
  .mainheadingpadding{
    padding: 0px 100px 0px 100px;
  }
  
  @media (max-width: 430px)  {
    .mainheadingpadding{
      padding: 0px 10px 0px 10px;
    }
  }
  
  #mega-menu-holder .dropdown-toggle::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333; /* Adjust the color as needed */
    margin-left: 5px; /* Adjust the spacing between the text and the arrow */
    vertical-align: middle;
  }
  
  #mega-menu-holder .dropdown-toggle::after {
    
    display: none;
   
  }
  .makeicon{
    position:absolute;
    top:20px;
    right: -2px;
    cursor: pointer;
  }
  
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0px !important;
  }