.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;
  /* min-width: 190px; */
}
.cardStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: unset;
  cursor: pointer;
  position: relative;
}

.cardsample {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: unset;
  cursor: pointer;
  filter: blur(8px);
  transform: scale(1);
}
.crownicons {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  padding-right: 2px;
  padding-bottom: 3px;
  border-radius: 100%;
  box-shadow: 0px 3px 1px 1px #c3c3c3;
}
.sample {
  display: flex;
  justify-content: center;
  text-align: center;
}

.sampletext {
  position: relative;
  z-index: 1;
  top: 20px;
  height: 3px;
}

.cardFlexStyle {
  width: 100%;
  padding: 8px 4px 8px 4px;
  border: 1px solid #581845;
  border-left-width: 5px;
  border-left-style: solid;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 135px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47000000000000003);
  justify-content: space-between;
  cursor: pointer;
}

.profile {
  height: 56px;
  width: 56px;
  border-radius: 100%;
}
.nameContainer {
  margin-left: 15px;
  max-width: 61% !important;
  cursor: pointer;
}
.svgContainer {
  margin-right: 8px;
}
.svgDownloadStyle {
  margin-right: 5px;

  cursor: pointer;
}
.svgCalnStyle {
  margin-right: 8px;
  cursor: pointer;
}
.percentageStyle {
  background-color: var(--color-secondary, #ffc203);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 1px;
  width: 45px;
  position: absolute;
  top: 45px;
  left: 5px;
}
.percentageblur {
  background-color: var(--color-secondary, #ffc203);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 1px;
  width: 45px;
  position: absolute;
  top: 45px;
  left: 5px;
  filter: blur(4px);
}
.svgView {
  margin-left: 6px;
  cursor: auto;
  margin-bottom: 1px;
}
.svgNewTag {
  /* align-items: flex-end; */
  display: flex;
  flex: 1;
}

.overAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px;
  position: relative;
  padding-right: 32px;
  /* width: 200%;
  margin-left: -57%;
  height:110%;
  margin-top: -10%; */
}

.linkBtnStyle {
  display: contents;
}
.pointer {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.pointerstage {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  margin-right: 10px;
}

.downloadspin {
  cursor: pointer;
  justify-content: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #581845;
  margin-right: 5px;
}
.loderboder {
  --bs-spinner-border-width: 2px;
}
