.overAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px;
  position: relative;
  padding-right: 32px;
}
.titelTextStyle {
  margin-left: 12px;
  width: 100%;
}
.btnContainer {
  margin-top: 16px;
  /* padding-left: 70%; */
}
.btnCancelStyle {
  margin-right: 8px;
  background-color: #888888;
  border: none;
  border-radius: 5px;
  color: #ffffff;
}
.btnCancelStyle:active,
.btnCancelStyle:focus {
  background-color: #888888;
  border: none;
}
.btnDeleteStyle {
  border: none;
    border-radius: 5px;
    text-transform: capitalize;
    /* margin-top: 20px; */
}
.btnDeleteStyle:active, .btnDeleteStyle:focus{
  border: none;

}

.svgClose{
  position: absolute;
  right: 4px;
  top: 4px;
}
.info{
  margin-top: 8px;
}