.margin16 {
  margin-right: 16px;
  position: relative;
}
.containerOne {
  margin-top: 16px;
}
.addBtn {
  margin-top: 10px;
  margin-bottom: 20px;
}
.svgTrash {
  position: relative;
  top: 20px;
}
.errorMessage {
  color: var(--color-error, #f94949);
  font-size: 12px;
}

.svgadd{
  margin-right: 8px;
  bottom: 1px;
  position: relative;
  cursor: pointer;
}

.CloseIcon {
  position: absolute;
  top: 72%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
}