.overAll {
  background-color: var(--color-white, #ffffff);
  padding: 20px 30px;
  border-radius: 4px;
  position: relative;
  min-width: 800px;
}
.svgClose {
  position: absolute;
  top: 16px;
  right: 16px;
}
.title {
  margin-bottom: 40px;
}
.locationFlex {
  margin-top: 20px;
  margin-bottom: 20px;
}
.updateBtnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 68px;
  height: 30px;
}
.updatebtnLoader1{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 50px;
  height: 30px;
}