.overAll {
  background-color: var(--color-white, #ffffff);
  padding: 25px;
  border-radius: 4px;
  position: relative;
  /* -width: 800px; */
  width: 800px;
  /* padding-bottom: 40px !important; */
}
.svgClose {
  position: absolute;
  top: 16px;
  right: 16px;
}
/* .title {
  margin-bottom: 40px;
} */
.cancel {
  margin-right: 8px;
  background-color: #888888;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}
.cancel:active,
.cancel:focus {
  background-color: #888888;
  border: none;
}
.borderLine {
  border-top: 1px solid #c3c3c3;
  /* padding-bottom: 20px; */
}
.softSkillFlex {
  padding-top: 20px;
  /* padding-bottom: 50px; */
}
.updateBtnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 68px;
  height: 30px;
  /* margin-top: 10px; */
}
.resumeupdateBtnLoader{
  border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 3px 12px 3px 12px;
    width: 68px;
    height: 30px;
    margin-top: 18px;
}