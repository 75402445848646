body {
  position: relative;
  min-height: 100vh;
  margin: 0;
}

.App {
  position: relative;
  min-height: 100vh;
}

.help_icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  padding: 8px 15px;
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: var(--color-primary, #581845);
  color: white;
  align-items: center;
  transition: transform 0.3s ease;
}

.help_icon1 {
  position: fixed;
  bottom: 20px;
  left: 70px;
  z-index: 1000;
  padding: 8px 8px;
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: var(--color-primary, #581845);
  color: white;
  align-items: center;
  transition: transform 0.5s ease;
}

.dropdown {
  position: absolute !important;
  bottom: 60px;
  left: 40px;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 160px;
  padding: 10px 10px;
  border-radius: 5px;
}

.dropdown_value {
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  /* border-radius: 5px; */
}

.dropdown_value:hover {
  background-color: #eee8ec !important;
}
