.overAll {
  padding: 20px;
  margin-bottom: 16px;
  /* margin: 13px; */
  margin-left: 6px;
  margin-right: 3px;
}
.switchFlex {
  margin-top: 8px;
  margin-bottom: 30px;
}
.inputHide{
  height: 0;
  width: 0;
  border: none;
}