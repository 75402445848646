.overflowContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}
.titleBar {
  padding: 10px 0;
  align-items: center;
  cursor: pointer;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.pipelineStructure {
  position: relative;
  max-height: 160px;
  width: 23%;
  margin-right: 20px;
  margin-left: 2px;
  margin-top: 2px;
  margin-bottom: 20px;
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rowGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cardHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.btn,
.btn2 {
  background-color: transparent;
  color: #581845;
  border: none;
  width: 100%;
  /* margin-top: 20px; */
  /* border-top: 1px solid #c3c3c3; */
  border-radius: unset;
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:active,
.btn:focus,
.btn2:active,
.btn2:focus {
  background-color: unset;
  transform: unset;
  border: unset;
  border-radius: unset;
}
.aligncards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  flex-direction: row;
  overflow-y: scroll;
  align-content: flex-start;
}
.titleText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 170px;
}
.deletepopup {
  padding: 25px;
  background-color: white;
}

.delBtnContainer {
  justify-content: flex-end;
  margin-top: 16px;
}
.pointer {
  cursor: pointer;
}
.actionBtnContainer {
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.crossIcon {
  all: unset;
  cursor: pointer;
  padding-top: 6px;
  position: relative;
  left: 10px;
}
.searchicons {
  height: 30px;
  padding: 10px;
  background-color: #581845;
  border-radius: 0px 20px 20px 0px;
  cursor: pointer;
}
.boxstyle {
  background-color: #f7f7f7;
  height: 30px;
  border-radius: 20px 0px 0px 20px;
  scroll-behavior: smooth;
  width: 218px;
  text-overflow: ellipsis;
}
.searchstyle {
  /* width: 412.27px; */
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.middleline {
  margin-right: 10px;
  height: 25px;
  border: 1px solid #e4dae1;
  margin-top: 2px;
}
.jobstext {
  height: 30px;
  padding: 10px;
  background-color: #eee8ec;
  display: flex;
  align-items: center;
  color: #581845;
}
.nodatastyle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.postyet1 {
  /* margin-left: 570px; */
  /* margin-top: 245px; */
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 10px;
  color: #581845;
  text-align: center;
}
.postyet2 {
  /* margin-left: 531px; */
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
.postyet3 {
  /* margin-left: 509px; */
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
.postyet4 {
  /* margin-left: 596px; */
  text-align: center;
}
.overAll2 {
  /* height: 81vh; */
  margin-top: 3px;
}
.makecenter {
  justify-content: center;
}
.truncater {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  margin-right: 2px;
}
