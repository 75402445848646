.titleFlex {
    /* background-color: #ffb142; */
    height: 54px;
    border-radius: 4px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
  }
  .borderBottom {
    border-bottom: 1px solid var(--color-gary-5, #cccccc);
    height: 42px;
  }
  .singleTile {
    height: 40px;
    background-color: #fce498;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-bottom: 3px;
    border-radius: 4px;
  }
  .borderNone {
    height: 42px;
  }
  .scroll{
    overflow: scroll;
  }
  .makecenter{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
   
}

.background{
  background-color: #fcc51b;
 border-bottom: 1px solid white;
 border-radius: 5px;
}
.heading{
  font-size: 18px;
  /* font-family: 'Open Sans'; */
  color: #424242
}
.subheading{
  font-size: 14px;
  /* font-family: 'Open Sans'; */
  color: #424242
}
