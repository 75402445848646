.cardStyle {
    /* padding: 16px 20px; */
    padding: 10px 20px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }

  .cardStyle:hover{
    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.55);
    transition: all 0.2s ease-out;
  }
  .listFlex {
    /* margin-bottom: 16px; */
    margin-bottom: 10px;
  }
  .valueStyle{
    margin-left: 4px;
  }