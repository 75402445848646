.overAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 25px;
}
.scroll {
  overflow-y: scroll;
  /* padding: 24px; */
  padding-top: 0px;
}
.title {
  /* padding-top: 20px;
  padding-bottom: 8px; */
}
