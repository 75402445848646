.overAll {
  /* padding: 40px; */
  overflow: scroll;
  /* padding-bottom: 110px; */
  padding: 0px 20px 0px 0px;
}
.cardOverAll {
  margin-top: 5px;
  /* padding: 16px 24px; */
}

.skillDiv {
  margin-top: 16px;
}

.round {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: var(--color-gary-5, #cccccc);
  position: absolute;
  right: -25px;
  top: 5px;
}
.step {
  /* position: relative; */
  /* margin: 0 400px; */
  /* margin-bottom: 36px; */
  margin-right: -24px;
  padding: 10px 0 20px 0;
}
.stepOne {
  position: relative;
  left: 0px;
  top: 0px;
}

.stepTwo {
  position: relative;
  right: 10px;
  top: 0px;
}
.stepThree {
  position: relative;
  top: 0px;
  right: 21px;
}
.stepFour {
  position: relative;
  top: 0px;
  right: 32px;
}
.step3Flex {
  position: absolute;
  right: 0;
  top: -20px;
}
.triangle {
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-right: 30px solid white;
  border-bottom: 22px solid transparent;
  position: relative;
  top: 0;
  right: 0;
}

.titleContainer {
  /* padding: 10px; */
  background-color: #eee8ec;
  font-size: 12px;
  margin: none;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jobTitleFlex {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--color-gary-7, #dfdfdf);
  justify-content: space-between;
}

.jobTitle {
  margin-right: 24px;
}
