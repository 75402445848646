.msgText {
  border-bottom: 0.5px solid #581845;
  padding: 5px 2px;
  margin-left: 8px;
  margin-right: 8px;
}

.datePicker {
  border: 1px solid var(--color-gary-5, #cccccc) !important;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 12px;
  padding: 5px;
  position: absolute;
  left: -150px;
}

.cardListStyle {
  padding: 2px;
  margin-bottom: 5px;
  margin-top: 5px;

  width: 49%;
  border-left: 6px solid #581845;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  -moz-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}

.cardListStyle:hover {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  transition: all 0.2s ease-out;
}

.overAll:hover {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  transition: all 0.2s ease-out;
}

.borderRight {
  width: 360px;
  border-right: 0.5px solid var(--color-primary, #581845);
  margin-right: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.title {
  /* overflow: hidden; */
  text-overflow: ellipsis;
  max-width: 200px;
  /* white-space: nowrap; */
  /* line-height: 18px; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.scrollStyle {
  overflow-y: scroll;
  padding: 0 0px 0px 10px;
  padding-top: 1px;
}

.scrollStyleOne {
  height: 391px;
}

.scrollStyleTwo {
  height: 437px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 0px 10px;
  padding-left: 5px;
  align-content: flex-start;
  overflow-y: auto;
}

.noContent {
  align-self: center;
}

.syncBtn {
  background-color: #581845;
  color: white;
  /* padding: 2px 4px; */
  margin-left: 2px;
  position: relative;

  cursor: pointer;
}

.overAll {
  width: auto;
  height: 285px;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}

.line {
  border: 0.5px solid #581845;
}

.cursor {
  cursor: pointer;
}

.scrollStyleTwo::-webkit-scrollbar {
  width: 7px;
  /* Adjust the width as needed */
}

.btnContainer {
  margin-top: 20px;
  justify-content: flex-end;
  padding-top: 20px;
  border-top: 1px solid #d7c7d2;
}