.profile {
  width: 55px;
  height: 55px;
  border-radius: 4px;
  object-fit: cover;
}
.overAll {
  height: 70px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 0px 40px 0px 40px;
}
.menu3Style {
  margin-right: 24px;
  cursor: pointer;
  /* border-bottom: 1px solid #333; */
}
.menu2Style {
  margin-right: 24px;
  cursor: pointer;
}
.menu1Style {
  margin-right: 24px;
  cursor: pointer;
}
.candiProfile {
  height: 30px;
  width: 30px;
  border-radius: 100%;
}

@media screen and (max-width: 850px) {
  .overAll {
    padding: 0 24px !important;
  }
}
.menuStyle {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  padding: 24px;
}
.menuStyleText {
  padding-bottom: 8px;
}
.svgClose {
  position: absolute;
  right: 16px;
  top: 6px;
}
.login {
  margin-right: 8px;
  /* border: 2px solid #333; */
  /* padding: 5px 5px; */
  /* border-radius: 4px; */
}
/* .loginbtn {
  border: 2px solid #333 !important;
} */
/* .loginbtn span {
  color: #333 !important;
  background-color: #fff;
} */
.loginbtn:hover{
  background-color: unset;
}
