.overAll {
  /* padding: 40px; */
  overflow-y: scroll;
  padding: 0px 10px 0px 3px;
}
.modalOverAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 20px;
}
.round {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: var(--color-secondary, #ffc203);
  position: absolute;
  right: -25px;
  top: 4px
}
.step {
  /* position: relative;
  margin: 0 400px;
  margin-bottom: 40px; */
  margin-right: -32px;
  padding:10px 0 20px 0;
}
/* .stepOne {
  position: relative;
  left: -23px;
  top: 22px;
}
.stepTwo {
  position: relative;
  right: 51px;
  top: 22px;
}
.stepThree {
  position: relative;
  top: 46px;
  left: 71px;
}

.stepFour {
  position: relative;
  top: 46px;
  left: 71px;
} */

.step3Flex {
  position: absolute;
  right: 0;
  top: -20px;
}
.stepOne {
  position: relative;
  left: 0px;
  top: 0px;
}

.stepTwo {
  position: relative;
  right: 10px;
  top: 0px;
}
.stepThree {
  position: relative;
  top: 0px;
  right: 21px;
}
.stepFour {
  position: relative;
  top: 0px;
  right: 32px;

}