.iconContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #c3c3c3;
  padding: 5px 5px;
  height: 35px;
}
.icons {
  cursor: pointer;
  padding: 4px;
  margin-left: 5px;
}
.icons:hover {
  background-color: #eee8ec;
  cursor: pointer;
  border-radius: 5px;
}
.iconsDisabled {
  padding: 4px;
  margin-left: 5px;
  cursor: auto;
}
.messageContainer{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: -webkit-fill-available; */
}
.bodyContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
   height: calc(100% - 40px);  
}
.bodyContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;  
}
.filesContainer {
  display: grid;
  /* border-top: 1px solid #c3c3c3; */
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0 10px;
}
.attachfile {
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  /* padding: 10px; */
  margin-top: 10px;
  margin-right: 10px;
  width: 100%;
  display: flex;
}
.svgicon {
  margin-left: 10px;
}
/* .iconsContainer:hover{
  background-color: #eee8ec;
  cursor: pointer;
} */
.fileName {
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bulletpoint ul li{
  list-style: disc !important;
  margin-left: 30px;
}
.bulletpoint ol li{
  list-style:decimal !important;
  margin-left: 30px;
}
.bulletpoint p{
   font-size: 13px !important;
}
.bulletpoint pre{
  font-size: 13px !important;
}
.bulletpoint div.font{
  font-size: 13px !important;
}
 
.bulletpoint1{
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 13px;
}
.overflow{
  overflow-y:hidden;
}
 