/* .cardOverAll {
  padding: 16px;
} */
.applicantTitle {
  border-bottom: 2px solid var(--color-gary-7, #dfdfdf);
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.tableDiv {
  margin-top: 16px;
}
.btnContainer {
  margin-top: 20px;
  /* position: absolute; */
  bottom: 10px;
  right: 0;
  left: 2px;
  /* border-top: 1px solid #dfdfdf; */
  padding: 5px;
  /* margin-left: 9px; */
  background-color: #ffffff;
}
.saveBtn {
  margin: 0 16px;
}
.leftAlign{
  display: flex;
  justify-content: left;
}
.updateBtnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 68px;
  height: 30px;
  /* margin-top: 10px; */
}