.drop_down {
  position: relative;
  margin-right: 10px;
}

.drop_down_header {
  padding: 10px;
  background-color: #ffff !important;
  width: 230px;
  height: 30px;
  border: 1px solid #581845;
  border-radius: 11px;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.drop_down_menus {
  background-color: #fff;
  position: absolute;
  background-color: var(--color-white, #fff);
  border-radius: 12px;
  max-height: 480px;
  overflow-y: scroll;
  width: 100%;
  display: none;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47000000000000003);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.47);
}

.show {
  display: block;
}

.filtername {
  display: flex;
  font-style: normal;
  color: #581845;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

.qualificationRadioStyle {
  margin-bottom: 8px;
}

.mtstyle {
  color: #581845 !important;
  border-top: 1px solid #dee2e6;
  padding: 10px;
  text-transform: none !important;
}

.filtersvg {
  transform: rotate(270deg);
}

.quickfilters {
  width: 99%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.quickfil {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px 2px 10px;
  width: fit-content;
  border: 1px solid #581845;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 13px !important;
}

.stylesvg {
  margin-left: 10px;
  cursor: pointer;
}

.filterContainer{
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    position: sticky;
    background-color: #fff;
  }
  .appFilterContainer {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    position: sticky;
    background-color: #fff;
  }
  .crossIcon {
    all: unset;
    cursor: pointer;
    margin-top: -17.3px; 
    right: 13px;
    position: absolute;
  }