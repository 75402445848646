.overAll {
  padding: 30px;
}
.imageStyle {
  height: 75px;
  width: 75px;
  /* box-sizing: border-box; */
  /* -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36); */
}
.cardOverAll {
  background-color: #f0ebed;
  padding: 30px 100px;
  border-radius: 10px;
  margin-top: 100px;
}
.uploadText {
  list-style-type: disc;
  margin-bottom: 12px;
  margin-top: 12px;
}
.displayNone {
  display: none;
}

.uploadContainer{
  margin-top: 15px;
  padding: 40px;
  border: 2px dashed #c3c3c3;
  border-radius: 2px;
}
/* .btnStyle {
  cursor: pointer;
  position: relative;
  box-sizing: border-box !important;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  padding: 3px 12px 2px 12px;
  width: fit-content;
} */

/* .btnStyle {
  border: 2px solid var(--color-primary, #581845);
  background-color: var(--color-primary, #581845);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btnStyle:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.2);
}

.btnStyle:focus {
  border: 2px solid var(--color-primary, #581845);
  background-color: var(--color-primary, #581845);
  box-shadow: none;
}

.btnStyle:active {
  background-color: var(--color-primary, #581845);
  box-shadow: none;
  transform: translateY(1px);
} */
.footerstyle{
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1); /* Add box shadow to top and bottom with reduced spread radius */
  background-color: white;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  /* background-color: yellow; */
  padding: 5px;
  font-size: 10px;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.pipestyles{
  height: 20px;
  border-left: 1px solid #581845;
  margin-left: 10px;
}