.labelContainer {
  margin-right: 20px;
}
.valueText {
  margin-left: 5px;
  /* min-width: 61%; */
  font-weight: 400;
}
.overAll {
  padding: 8px 16px;
}
.cardStyle {
  margin-bottom: 16px;
  margin-left: 1px;
  margin-top: 1px;
}
