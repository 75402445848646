.overAll {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 8px 8px 16px 24px;
}
.leftBtn {
  margin-right: 8px;
  width: 84px;
}
.rightBtn {
  margin-left: 8px;
  width: 84px;
}
.btnContainer {
  margin-top: 8px;
}
.title {
  padding-right: 16px;
  margin-top: 8px;
}
.titelText {
  padding-left: 8px;
}
