.overAll {
  padding: 25px;
}

.jobDetail {
  padding: 10px 15px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 47%);
  border-radius: 5px;
}

.btnContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.lineHeight {
  line-height: 3;
}

.jobDes {
  margin-bottom: 5px;
}

.statusList {
  margin-right: 8px;
  margin-bottom: 8px;
}

.statusContainer {
  margin-top: 8px;
}

.des ul {
  padding-left: 40px;
}

.listFlex {
  margin-bottom: 10px;
}

.valueStyle {
  margin-left: 4px;
}

@media screen and (max-width: 700px) {
  .btnContainer {
    align-items: center !important;
    margin-bottom: 16px !important;
  }
}


.jobDetail td p {
  margin-bottom: unset !important;
  font-size: 13px;
}
.jobDetail td li {
  margin-bottom: unset !important;
  font-size: 13px;
}

.jobDetail td h1 {
  margin-bottom: unset !important;
  font-size: 38px;
  font-weight: 600;
}

.jobDetail td h2 {
  margin-bottom: unset !important;
  font-size: 32px;
  font-weight: 600;
}

.jobDetail td h3 {
  margin-bottom: unset !important;
  font-size: 26px;
  font-weight: 600;
}

.jobDetail td h4 {
  margin-bottom: unset !important;
  font-size: 20px;
  font-weight: 600;
}

.jobDetail td h5 {
  margin-bottom: unset !important;
  font-size: 16px;
  font-weight: 600;
}
.jobDetail td h6 {
  margin-bottom: unset !important;
  font-size: 14px;
  font-weight: 600;
}
 
.des ul {
  padding-left: 40px;
  list-style-type: disc !important;
}

.des ol {
  padding-left: 40px;
  list-style-type: auto;
  /* font-size: 13px */
}

.des td p {
  margin-bottom: unset !important;
  font-size: 13px;
}
.des td li {
  margin-bottom: unset !important;
  font-size: 13px;
}
.des td h1 {
  margin-bottom: unset !important;
  font-size: 38px;
  font-weight: 600;
}

.des td h2 {
  margin-bottom: unset !important;
  font-size: 32px;
  font-weight: 600;
}

.des td h3 {
  margin-bottom: unset !important;
  font-size: 26px;
  font-weight: 600;
}

.des td h4 {
  margin-bottom: unset !important;
  font-size: 20px;
  font-weight: 600;
}

.des td h5 {
  margin-bottom: unset !important;
  font-size: 16px;
  font-weight: 600;
}
.des td h6 {
  margin-bottom: unset !important;
  font-size: 16px;
  font-weight: 600;
}

.des a {
  font-weight: 600;
}

.jobDetail a {
  font-weight: 600;
}

.rolesandresponsibilitiescard a {
  font-weight: 600;
}

.des u {
  color: initial;
}

.jobDetail u {
  color: initial;
}

.rolesandresponsibilitiescard u {
  color: initial;
}

.rolesandresponsibilitiescard td h6 {
  margin-bottom: unset !important;
}