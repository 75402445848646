.overAll {
  padding: 25px;
  position: relative;
}
.svgEdit {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.commentTextStyle {
  /* padding: 8px 8px 8px 0px; */
  font-size: 13px;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: clip;
  white-space: wrap;
  overflow-wrap: break-word;
}
.commentTextStyle h6 {
  margin-top: 0.5rem;
}
.commentTextStyle p {
  margin: 0;
}
.commentTextStyle ul li {
  margin-left: 20px;
  list-style: disc;
}
.commentTextStyle ol li {
  margin-left: 20px;
}
