.marginTop16 {
  margin-bottom: 10px;
}
.overAll {
  padding: 16px;
  margin: 16px 0;
}

.sizeStyle {
  margin-top: 8px;
  margin-bottom: 4px;
}

.textArea {
  height: 90px;
}

.changeStyle11 {
  margin-bottom: 0px !important;
  cursor: pointer;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px !important;
}

.cardfront{
  position: absolute;
  z-index: 10000;
  padding: 5px 10px 5px;
  transform: translate(15px, 25px);
}
.moreinformation{
  color:#FCC203;
  font-size: 12px;
  margin-left: 8px;
  border-bottom: 1px solid #FCC203;
}
.gray_color{
  color:#666666 ;
}