/* .cardOverAll {
  background-color: var(--color-gary-8, #fafafa);
  padding: 20px 200px 40px;
  border-radius: 4px;
  position: relative;
}
.userInput {
  width: 350px;
  margin-top: 30px;
  margin-bottom: 16px;
}
.userPassword {
  width: 350px;
  position: relative;
}
.btnConatiner {
  margin-top: 24px;
}

.overAll {
  /* overflow: scroll; */

.row {
  display: flex;
  flex-direction: row;
}

.splitrow_1 {
  width: 50%;
  height: 100vh;
  background-color: #f0ebed;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.splitrow_2 {
  width: 50%;
  height: 100%;
}
.center_aligh {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
}
.input {
  padding: 9px;
  border-radius: 9px;
  margin-top: 20px;
  box-shadow: 0px 3px 1px #beadb9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ffffff;
  text-indent: 30px;
  height: 35px;
}

.input:focus {
  border: 1px solid #beadb9;
}

.forget {
  margin-top: 5px;
  float: right;
  font-size: 12px;
}

.login_button {
  width: 100% !important;
  margin-top: 20px;
  /* padding: 5px; */
  /* border-radius: 5px; */
  /* background-color: #581845; */
  /* border: 2px solid #581845; */
}

.login_button:hover {
  box-shadow: none;
}

.footer {
  position: fixed;
  bottom: 10px;
  left: 25%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #581845;
}

.footer1 {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
  color: #581845;
}

.text {
  color: #581845;
}
.text_account {
  color: #581845;
  font-size: 14px !important;
}
.Log_title {
  color: #581845;
  margin-bottom: 4px;
}

.center_aligh,
center {
  color: #581845;
}

.center_aligh,
u,
a {
  color: #581845;
}

.center_aligh,
u,
a:hover {
  color: #581845;
}

.center_aligh_slider {
  width: 30%;
  position: absolute;
  top: 55%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.sliderbutton {
  margin-top: 20px;
  width: 25px;
  height: 8px;
  margin-left: 10px;
  background-color: #581845;
  border: 2px solid #581845;
  border-radius: 20px;
}

.sliderbuttonoff {
  margin-top: 20px;
  width: 8px;
  height: 8px;
  margin-left: 10px;
  background-color: #888888;
  border: 2px solid #888888;
  border-radius: 50px;
}

.passwordicon {
  top: 23px;
  left: 0px;
}

.usericon {
  /* top: 26px;
  left: 0px;
  height: 25px;
   background-color: #D7C7D2; 
  width: 30px; */
  position: absolute;
  left: 10px;
  top: 30px;
}

.Passicon {
  position: absolute;
  left: 8px;
  top: 29px;
}

/* .usericon,span{
    padding-bottom: 2px;
  } */

/* check css */
/* .forget,span{
   color:red;
   font-size: 13px !important;
  padding-right: 10px !important;
  } */

.logo {
  margin-top: 50px;
}
.error_msg {
  margin-top: 4px;
}
.svg {
  vertical-align: 8px;
}

.centered_form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.h1styles{
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 4px;
}