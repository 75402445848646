.cardOverAll {
  padding: 8px 8px 8px 8px;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-top: 8px;
  margin-right: 8px;
  height: 110px;
  width: 100%;
}
.cardwrap {
  display: flex;
  flex-wrap: wrap;
  width: 32%;
  margin-left: -5px;
  margin-right: 19px;
}

.profileFlex {
  width: 35%;
}
.nameFlex {
  padding-right: 8px;
  width: 100%;
  margin-left: -2px;
}

.cardHeight {
  height: 68px;
}

.svgView {
  margin-left: 8px;
  margin-top: -2px;
}

.labelSvg {
  position: absolute;
  top: 43px;
}
.inviteStyle {
  cursor: pointer;
}
.inviteDisable {
  opacity: 0.3;
  pointer-events: none;
}
.popTitle {
  margin-left: 8px;
}
.pointerEvet {
  pointer-events: none;
}
.cardsplit {
  width: 1px;
  border-right: 1px solid #a5889c;
  height: 80px;
  top: 7px;
  position: relative;
}
.splitandinvite {
  position: relative;
}
.invitesplit {
  position: relative;
}

.svgHeartaddtofavourites {
  display: flex;
  align-items: flex-end;
}

.downloadspin {
  cursor: pointer;
  justify-content: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #581845;
  margin-top: 5px;
}
.loderboder {
  --bs-spinner-border-width: 2px;
}
