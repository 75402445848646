.window{
    background-color: #fff;
    padding: 25px 25px 25px 25px;
    overflow: hidden;
    z-index: 2;
    width: 650px;
    height: 390px;
    border-radius: 4px;
}

.share {
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    justify-content: flex-end;
}
.share:active,
.share:focus {
    border: none;
}
.cancel {
    margin-right: 8px;
    background-color: #888888;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    justify-content: flex-end;
  }
.cancel:active,
.cancel:focus {
    background-color: #888888;
    border: none;
}

.row {
    margin-bottom: 10px;
    /* width: 80%; */
  }
.phoneInputs{
    height: 20px;
}
.error{
    font-size: 12px;
    color: red;
}