.cardOverAll {
  padding: 16px;
}
.cardOverAll:hover {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.55);
  transition: all 0.2s ease-out;
}
.defaultText {
  margin-top: 4px;
  margin-bottom: 16px;
}
.checkBox {
  border: 1px solid var(--color-gary-7, #dfdfdf);
  width: fit-content;
  border-radius: 4px;
  height: 60px;
  padding: 0 16px;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  /*pointer-events: none;*/
}
.checkBoxs {
  border: 1px solid var(--color-gary-7, #dfdfdf);
  width: fit-content;
  border-radius: 4px;
  height: 40px;
  padding: 10px 10px 10px 5px;
  align-items: center;
  display: flex;
  margin-top: 16px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 30px;
  /*pointer-events: none;*/
}

.editBtn {
  margin: 0 16px;
}
.textstyleinjobpost {
  color: #333333;
  opacity: 0.5;
  font-weight: 700;
  font-size: 15px;
}
.btnContainer {
  /* position: absolute; */
  bottom: 10px;
  right: 0;
  left: 2px;
  /* margin-left: 4px; */

  /* border-top: 1px solid #dfdfdf; */
  padding: 5px;
  background-color: #ffffff;
}
.updateBtnLoader {
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 68px;
  height: 30px;
  /* margin-top: 10px; */
}
