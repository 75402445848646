.overAll {
  /* background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px;
  position: relative;
  padding-right: 32px;
  width: 600px;
  height: 150px;
  border-radius: 5px; */

  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  padding: 16px;
  position: relative;
  padding-right: 32px;
  width: 600px;
}

.btnCancelStyle {
  margin-right: 8px;
}
