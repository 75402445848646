.wrapCards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.accountcards {
    display: flex;
    /* flex-wrap: wrap; */
    /* width: 32%; */
    padding: 25px;
    margin-right: 15px;
    margin-bottom: 15px;
    height: 180px;
    display: flex;
    justify-content: space-between;
}

.bottomlink {
    margin-top: 15px;
    border-top: 1px solid #C3C3C3;
    padding-top: 15px;
}

.descriptionText {
    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%; */
    font-size: 13px;
}

.ribbon {
    background-color: #EEE8EC;
    height: 40px;
}

.triangle {
    width: 0px;
    height: 0px;
    border-top: 20px solid transparent;
    border-right: 30px solid white;
    border-bottom: 20px solid transparent;
    position: relative;
    top: 0px;
    right: 0px;
}

.iconHolder{
    border-radius: 50%;
    padding-top: 3px;
    padding: 5%;
    background: #eee8ec;
}