.taskList {
  padding: 8px;
  flex-grow: 1;
  transition: background-color ease 0.2s;
  /* margin-top: 40px; */
}

.overAll {
  display: flex;
  flex-direction: column;
  margin-right: 14px;
  width: 20%;
  border-radius: 4px;
  box-sizing: border-box;
  /* border: 1px solid var(--color-gary-7, #dfdfdf); */
  /* min-width: 200px; */
}
.titleStyle {
  padding: 6px 8px 6px 8px;
  position: relative;
}
.dropDownFlex {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  position: absolute;
  right: 0;
  top: 33px;
  z-index: 11;
}
.dropDate {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.dropMatch {
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  cursor: pointer;
  border-radius: 4px;
}
.dropName {
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  cursor: pointer;
  border-radius: 4px;
}
.dropDate:hover {
  background-color: rgb(88 24 69 / 30%);
}
.dropMatch:hover {
  background-color: rgb(88 24 69 / 30%);
}
.dropName:hover {
  background-color: rgb(88 24 69 / 30%);
}
.svgSort {
  cursor: pointer;
}
