.pointer {
  cursor: pointer;
  padding: 0px 7px;
}
.pointerEvent {
  opacity: 0.3;
  
}
.svgExternal{
 font-size:13px;}
 .custom-dropdown {
  position: relative !important;
}
