.bannerStyle {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}
.imgFlex {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}
.overLay{
  position: absolute;
  top:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.innerText {
  position: absolute;
  width: 56%;
  padding-bottom: 70px;
}
.bannerTextStyle {
  margin-top: 40px;
}
.footerStyle {
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.shareButton {
  position: fixed;
  left: 0;
  z-index: 11;
  top: 285px;
}
.nonePage {
  background-color: var(--color-gary-8, #fafafa);
  width: 400px;
  border-radius: 4px;
  padding: 20px;
}
.successFlex {
  background-color: var(--color-gary-8, #fafafa);
  border-radius: 4px;
  padding: 20px 50px;
}
.jobViewRes {
  position: relative;
  top: -70px;
}

@media screen and (min-width: 1060px) {
  .jobViewRes {
    width: 1300px;
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media screen and (max-width: 1060px) {
  .jobViewRes {
    max-width: 1300px;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 700px) {
  .innerText {
    width: 70% !important;
  }
  .centerStyle{
    justify-items: center;
  }
}

.footerstyle{
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1); /* Add box shadow to top and bottom with reduced spread radius */
  background-color: white;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  /* background-color: yellow; */
  padding: 5px;
  font-size: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.pipestyles{
  height: 20px;
  border-left: 1px solid #581845;
  margin-left: 10px;
}