.addoncalcard1{
    width: 48%;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 10px;
    background-color: rgb(250, 248, 250);
    height: 116px;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(0,0,0,.125);
}

.addonnametruncet{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.addoncalcardflex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.bottomlinkContainer{
    border-top: 1px solid;
    padding-top: 10px;
    margin-top: 10px;
}
.addoncarddes{
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(0,0,0,.125);
}

.standardcardplan{
    width: 50%;
    margin-right: 20px;
}

.premiumcardplan{
    width: 50%;
}

.standardcardplancheck{
    width: 50%;
    margin-right: 20px;
    box-shadow: 0 1px 4px 0 #00be4bb0;
    box-shadow: 0px 0px 10px 3px #00BE4B;
    -webkit-box-shadow: 0px 0px 10px 3px #00be4bb0;
}
.premiumcardplancheck{
    width: 50%;
    box-shadow: 0 1px 4px 0 #00be4bb0;
    box-shadow: 0px 0px 10px 3px #00BE4B;
    -webkit-box-shadow: 0px 0px 10px 3px #00be4bb0;
}

.premiumQuantityDrop{
    width: 40px;
}

.premiumQuantityDrop3{
    width: 50px;
}

.premiumQuantityDrop4{
    width: 60px;
}

.billYear{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 5;
    height: 35px;
    cursor: pointer;
}

.billMonth{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 5;
    height: 35px;
    cursor: pointer;
}

.billmonthtext{
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.billyeartext{
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.ribbon {
    background: #e31b1b;
    text-transform: uppercase;
    color: white;
    display: flex;
    /* align-items: center; */
    width: 100px;
    /* -webkit-clip-path: polygon(0 0, 15% 50%, 0 100%, 100% 100%, 100% 0);
    clip-path: polygon(0 0, 15% 50%, 0 100%, 100% 100%, 100% 0); */
    -webkit-clip-path: polygon(0% 0, 0% 0%, 0% 100%, 100% 100%, 85% 50%, 100% 0);
    clip-path: polygon(0% 0, 0% 0%, 0% 100%, 100% 100%, 85% 50%, 100% 0);
    /* justify-content: center; */
    border-radius: 4px;
    padding: 0 0 0 15px;
}

.standmonthbill{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.standannalbill{
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.premiummonthbill{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.premiumannalbill{
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.roundDiv {
    width: 30px;
    height: 30px;
    background-color: #e31b1b ;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(50% 1%, 63% 20%, 86% 15%, 81% 38%, 100% 50%, 81% 63%, 86% 86%, 63% 81%, 50% 100%, 37% 81%, 14% 86%, 19% 63%, 0% 50%, 19% 38%, 14% 15%, 37% 20%);
}

  .checkedAddon{
    border: 1px solid #581845;  
}

.discountlabel{
    position: absolute;
    top: 70px;
    right: 25px;
}

.standorderSum{
    padding: 20px;
    background-color: rgb(238, 232, 236);
    border-radius: 5px;
}
.premorderSum{
    padding: 20px;
    background-color: rgb(238, 232, 236);
    border-radius: 5px;
}