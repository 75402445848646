.overAll {
  padding: 25px;
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
}
.btnContainer {
  margin-top: 16px;
}
.title {
  /* padding-right: 16px;
  margin-top: 8px; */
}
.titleText {
  padding-left: 8px;
}
