.overAll {
  padding: 20px 40px;
  overflow-y: scroll;
}
.footerstyle{
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1); /* Add box shadow to top and bottom with reduced spread radius */
  position: sticky;
  bottom: 0;
  display: flex;
  position: absolute;
  width: 100%;
  /* background-color: yellow; */
  padding: 5px;
  font-size: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.pipestyles{
  height: 20px;
  border-left: 1px solid #581845;
  margin-left: 10px;
}

