.profileDiv {
  position: relative;
  margin-left: 4px;
  margin-right: 8px;
}
.profile {
  height: 42px;
  width: 42px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}
.percentageStyle {
  background-color:#00BE4B;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 1px;
  width: 36px;
  position: absolute;
  top: 38px;
  left: 2.5px;
}
.percentageStyleAvg {
  background-color:#EEAC17;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 1px;
  width: 36px;
  position: absolute;
  top: 38px;
  left: 2.5px;
}
.blur {
  filter: blur(5px);
  cursor: pointer;
}
.percentageStyleError {
  background-color: #f94949;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 1px;
  width: 36px;
  position: absolute;
  top: 38px;
  left: 2.5px;
}

.percentagefont {
  font-size: 11px;
  padding-top: 2px;
}
