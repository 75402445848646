.pageHeader{
    background:  #581845 ;
    height: 90px;
}
.alignitem{
    align-items: center;
}
.scroll{
    overflow: scroll;
}
.contentHolder{
    padding: 0px 100px 0px 100px;
    /* overflow: scroll; */
    @media (max-width: 600px) {
        padding: 0px 15px 0px 15px;
      }
}
.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.heading{
    font-size: 18px;
    /* font-family: 'Open Sans'; */
    color: #424242;
}
.subheading{
    font-size: 14px;
    /* font-family: 'Open Sans'; */
    color: #424242;
}
.calculation{
    font-size: 14px;
    /* font-family: 'Open Sans'; */

}
/* .widthheadet{
    width:330px
} */
.makecenter{
    width:350px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.mobileview{
    display: flex;
    flex-direction: column;
}
.pcview{
    display: flex;
    flex-direction: row;
}
/* .cardmobile{
    width:325px
} */
.marginleftmobile{
    margin: 0px 0px 0px 0px;
}
.marginleftpc{
    margin: 0px 0px 0px 10px;
    flex-wrap: wrap;
}