.taskList {
  padding: 8px;
  flex-grow: 1;
  transition: background-color ease 0.2s;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.overAll {
  display: flex;
  flex-direction: column;
  /* margin-right: 16px; */
  width: 50%;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid var(--color-gary-7, #dfdfdf);
  height: 200px;
  overflow-y: scroll;
  cursor: auto;
}

.inputStyle {
  padding-left: 0;
  padding-right: 0;
}
.rightStyle{
  margin-left: 16px;
}
.leftStyle{
  margin-right: 16px;
}
