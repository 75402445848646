.meetingSummary {
  background-color: white;
  /* max-height: 549px; */
  /* height: 100%; */
  border-radius: 4px;
  width: 700px;
  max-width: 80vw;
}

.meetingSummary p {
  margin: 0;
}

.formTitle {
  /* color: var(--color-primary); */
  /* font-weight: 500; */
  font-size: 14px;
  /* margin-left: 5px; */
  font-weight: bold;
}

.meetingSummary .summary .header {
  font-size: 13px;
}

.meetingSummary .summary {
  border-radius: 5px;
}

.meetingSummary .summary .content {
  padding: 5px 10px 5px 10px;
  background-color: rgba(0, 0, 0, 0.07);
  margin: 5px 0px;
  border-radius: 5px;
  font-size: 13px !important;
}

.summary {
  margin-bottom: 10px;
}

.meetingSummary .notification .header {
  font-size: 17px;
  margin: 5px 0px 10px 0px;
}

.meetingSummary .notification .emailContainer {
  display: flex;
  align-items: center;
  margin-left: 22px;
  flex-wrap: wrap;
}

.emailContainer {
  width: 100%;
}

.emails {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  padding: 10px 10px;
}

.boxView {
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  padding: 5px 10px;
  font-size: 13px !important;
}

.emails > p {
  padding: 2px 6px 0px 2px;
}

.emails > div {
  display: flex;
  flex-wrap: wrap;
}

.meetingSummary .notification .emailContainer p {
  font-size: 16px;
}

.meetingSummary .notification .emailContainer .email {
  background-color: rgba(0, 0, 0, 0.07);
  border: 1px solid gray;
  letter-spacing: 1.5px;
  padding: 1px 10px;
  border-radius: 100px;
  margin: 0px 5px 0px 0px;
  font-size: 13px;
}

.emailContainer .email {
  background-color: rgba(0, 0, 0, 0.07);
  border: 1px solid gray;
  letter-spacing: 1.5px;
  padding: 1px 10px;
  border-radius: 100px;
  margin: 0px 5px 0px 0px;
  font-size: 13px !important;
}
.email {
  margin-bottom: 5px !important;
}

.actionButtonWrapper {
  margin-top: 10px;
  display: flex;
  justify-content: end;
}

.actionButtonWrapper button {
  /* margin-left: 20px; */
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 0.6px;
  outline: none;
  border: none;
  padding: 5px 12px;
}

.actionButtonWrapper button.continueButton {
  background-color: var(--color-primary);
  color: white;
}

.actionButtonWrapper button.continueButton:hover {
  background-color: var(--color-primary-hover);
}

.details {
  /* margin: 10px 0px 10px 0px; */
  padding: 6px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}

.subject {
  width: 100%;
}

.interviewers {
  display: flex;
}

.interviewers p {
  margin-right: 5px;
}

.details > div {
  border-top: 1px solid rgb(49, 49, 49);
  margin-top: 5px;
  padding-top: 10px;
}

.personHeader {
  font-weight: bold;
  font-size: 13px;
}
.cancel {
  margin-right: 8px;
  background-color: #888888;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
}
.cancel:active,
.cancel:focus {
  background-color: #888888;
  border: none;
}
.continueButton {
  border: none;
  border-radius: 5px;
  margin-top: 5px;
}
.continueButton:active,
.continueButton:focus {
  border: none;
}
.actionBtns {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 5px 0px;
}
.editIcon {
  position: relative;
}

.pre {
  font-family: 'Roboto', sans-serif;
  text-wrap: wrap;
  color: #212529;
  font-size: 13px;
  /* font-weight: 400; */
}
