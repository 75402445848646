.dropDownFlex {
  background-color: var(--color-white, #ffffff);
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  position: absolute;
  right: 0;
  top: 33px;
  z-index: 11;
}

.dropDate {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.dropMatch {
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  cursor: pointer;
  border-radius: 4px;
}

.dropName {
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  cursor: pointer;
  border-radius: 4px;
}

.dropDate:hover {
  background-color: rgb(88 24 69 / 30%);
}

.dropMatch:hover {
  background-color: rgb(88 24 69 / 30%);
}

.dropName:hover {
  background-color: rgb(88 24 69 / 30%);
}

.svgSort {
  cursor: pointer;
}

.col {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  /* overflow-x: auto; */
}

.colTitle {
  box-sizing: border-box;
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid var(--color-gary-7, #dfdfdf); */
  border-bottom: 3px solid white;
  /* border-radius: 10px 10px 10px 10px; */
  padding: 6px 8px 6px 8px;
  margin-right: 14px;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--color-white, #ffffff);
  top: -3px;
  /* min-width: 200px; */
}

.filterFlex {
  margin-right: 16px;
}

.dropdown-item {
  padding: 10px !important;
}

.customToggle::after {
  content: none !important;
  /* Hide the default caret icon */
}