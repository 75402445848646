.overAll {
  height: 100px;
  background-color: var(--color-primary, #581845);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 50px;
}
.profile {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
  margin-left: 20px;
  font-size: 40px;
  margin-right: 16px;
  z-index: 11;
}
.phoneFlex {
  padding-top: 10px;
}
.firstlastchar {
  font-size: 44px;
}
.phoneInput {
  border: none !important;
  padding: 0 !important;
  height: fit-content !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 14px !important;
  background-color: transparent !important;
  width: fit-content !important;
}
.specified::placeholder{
  color: #fff !important;
}
.phoneHide {
  pointer-events: none;
  max-width: 155px;
}
.phoneHide div div {
  display: none;
}
