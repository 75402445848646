.overAllPopup {
  overflow: scroll;
  padding: 0 10px 0px 0px;
}

.statusStyle {
  margin-left: 16px;
}

.statusListStyle {
  margin-bottom: 30px;
}
.statusListStyles1{
  margin-bottom: 30px;
}

.vrLine {
  height: 31px;
  border-right: 4px solid var(--color-primary, #581845);
  position: absolute;
  top: -31px;
  left: 13px;
}

.vrLines {
  height: 31px;
  border-right: 4px solid var(--color-primary, #581845);
  position: absolute;
  top: -31px;
  left: 13px;
}

.vrLin {
  height: 31px;
  border-left: 4px solid var(--color-primary, #581845);
  /* position: absolute; */
  margin-top: -30px;
  margin-left: 13px;
}

.svgFlex {
  position: relative;
}

.screenText {
  margin-bottom:15px;
  font-size: 14px;
  color: #333333;
}

.addButton {
  cursor: pointer;
}

.input {
  font-size: 13px;
}

.svgContainer {
  display: flex;
  /* align-items: center; */
  margin-left: 5px;
  top: 16px;
  right: 0;
}

.svgTick {
  margin-right: 4px;
  cursor: pointer;
}

.svgTickMargin {
  margin-right: 4px;
  cursor: pointer;
  z-index: 11;
}

.svgTickDisable {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.5;
}

.tickStyle:hover path {
  fill: var(--color-gary-4, #979797) !important;
}

.svgClose {
  cursor: pointer;
  z-index: 11;
}

.overAll {
  background-color: var(--color-white, #ffffff);
  width: 800px;
  padding: 25px;
  border-radius: 4px;
}

.insertStyles {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid #581845;
}

.cancel {
  margin-right: 8px;
  background-color: #888888;
  border: none;
  border-radius: 5px;
  /* margin-top: 20px; */
}

.cancel:active,
.cancel:focus {
  background-color: #888888;
  border: none;
}

.checkBox {
  margin-top: 2px;
  align-items: flex-start;
  width: 14;
  height: 14;
}

.ratingStar ul li {
  font-size: 20px;
  cursor: default;
}
.drop {
  position: relative;
}

.drop1 {
  height: 17.5%;
  margin-top: 4px;
  position: absolute;
  width: 100%;
  /* Set the width to cover the entire container */
  border-radius: 5px;
  right: 6px;
  box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, 0.36);
  display: flex;
  z-index: 2;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  align-items: center;
  justify-content: center;
}

.crownicons {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  padding-right: 2px;
  padding-bottom: 3px;
  border-radius: 100%;
  box-shadow: 0px 2px 8px 1px #c3c3c3;
}
.notesTextStyle {
  font-size: 14px;
  color: #333333;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: clip;
  white-space: wrap;
  overflow-wrap: break-word;
  text-align: justify;
}
.notesTextStyle ul {
  padding-left: 25px !important;
  list-style:disc;
}
.notesTextStyle u
 {
  color: #333333;
}
.vrLine1{
  height: 40px;
  border-right: 4px solid var(--color-primary, #581845);
  position: absolute;
  top: -40px;
  left: 13px;
} 
.vrLine2{
  height: 47px;
  border-right: 4px solid var(--color-primary, #581845);
  position: absolute;
  top: -47px;
  left: 13px;
}
.vrLine3{
  height: 36px;
  border-right: 4px solid var(--color-primary, #581845);
  position: absolute;
  top: -36px;
  left: 13px;
}