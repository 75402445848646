.common {
  cursor: pointer;
  position: relative;
  /* padding: 6px 12px 5px 12px; */
  box-sizing: border-box !important;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  padding: 3px 12px 3px 12px;
  width: fit-content;
  height: 30px;
}

/* primary button style */
.buttonTypes-primary {
  /* border: 2px solid var(--color-primary, #581845); */
  background-color: var(--color-primary, #581845);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  border-radius: 5px;
}

.buttonTypes-primary:hover {
  /*rewamp off this css */
  /* box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.2); */
    box-shadow:none;
}

.buttonTypes-primary:focus {
  /* border: 2px solid var(--color-primary, #581845); */
  background-color: var(--color-primary, #581845);
  box-shadow: none;
}

.buttonTypes-primary:active {
  background-color: var(--color-primary, #581845);
  box-shadow: none;
  transform: translateY(1px);
}

/* close button */
.buttonTypes-close {
  border: 2px solid var(--color-close, #888888);
  background-color: var(--color-close, #888888);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  border-radius: 5px;
}

.buttonTypes-close:hover {
  /*rewamp off this css */
  /* box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.2); */
    box-shadow:none;
}

.buttonTypes-close:focus {
  border: 2px solid var(--color-close, #888888);
  background-color: var(--color-close, #888888);
  box-shadow: none;
}

.buttonTypes-close:active {
  background-color: var(--color-close, #888888);
  box-shadow: none;
  transform: translateY(1px);
}


.buttonTypes-success {
  background-color: var(--color-success, #34cc65);
  border: 2px solid var(--color-success, #34cc65);
}

/* link button styles */
.buttonTypes-link {
  padding: 0;
  border: none;
  cursor: pointer;
  justify-self: start;
}



/* secondary Button Styles */
.buttonTypes-secondary {
  background-color: var(--color-white, #ffffff);
  border: 2px solid var(--color-primary, #581845);
  /* background-color: #DCD1D9;
  border: 2px solid #DCD1D9; */
}

/* .buttonTypes-secondary:hover {
  background-color: rgb(88 24 69 / 15%);
} */

.buttonTypes-secondary:focus {
  border: 2px solid var(--color-primary, #581845);
  /* background-color: rgb(88 24 69 / 20%); */
}

.buttonTypes-secondary:active {
  border: 2px solid var(--color-primary, #581845);
  /* background-color: rgb(88 24 69 / 25%); */
}

/* tertiary button style */

.buttonTypes-tertiary {
  background-color: var(--color-white, #ffc203);
  border: 2px solid var(--color-primary, #ffc203);
}

.buttonTypes-tertiary:hover {
  opacity: 0.75;
}
.buttonTypes-tertiary:focus {
  background-color: var(--color-white, #ffc203);
  border: 2px solid var(--color-primary, #ffc203);
}
.buttonTypes-tertiary:active {
  background-color: #ccad10;
  border: 2px solid #ccad10;
}




/* disabled styles */
.disabled-primary {
  opacity: 0.3;
  cursor: not-allowed !important;
  pointer-events: none;
}
.disabled-primary:hover {
  background-color: var(--color-white, #581845);
  box-shadow: none;
}

.disabled-secondary {
  opacity: 0.3;
  cursor: not-allowed !important;
  pointer-events: none;
}

.disabled-secondary:hover {
  background-color: var(--color-white, #ffffff);
}

.disabled-tertiary {
  opacity: 0.3;
  background-color: var(--color-white, #ffc203);
  cursor: not-allowed;
  pointer-events: none;
}

.disabled-tertiary:hover {
  background-color: var(--color-white, #ffc203);
  opacity: 0.3;
}

.disabled-link {
  padding: 0;
  border: none;
  cursor: not-allowed;
  opacity: 0.3;
  pointer-events: none;
}


