.overAll {
  padding: 20px 75px;
}
.passwordTextStyle {
  margin-bottom: 20px;
}
.cardOverAll {
  padding: 20px 20px 40px 20px;
}
.btnFlex{
  padding-top: 20px;
}
.model { 
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px 20px 20px 20px; 
  max-height: auto; 
}
.passwordhead{
  font-size: 16px;
  font-weight: 700;
  color: #581845;
}
.cancelbutton{
  margin-right: 20px;
}
.overallbtn{
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
.btnstyleclosesave1{ 
background-color: #888888; 
}