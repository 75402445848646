.overAll {
  background-color: var(--color-white, #ffffff);
  padding: 20px 30px;
  border-radius: 4px;
  position: relative;
}
.title {
  margin-bottom: 40px;
}
.svgClose {
  position: absolute;
  top: 16px;
  right: 16px;
}
.briefStyle {
  padding-top: 20px;
  padding-bottom: 20px;
}
.roleInput {
  margin-bottom: 20px;
}
.textAreaInputStyle {
  height: 80px;
}
.academicCheckBox {
  padding-top: 28px;
}
.textAreaInputStyleOne {
  height: 120px;
}
.textAreaInputStyle {
  height: 30px;
}
.btnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 68px;
  height: 30px;
}
.btnLoader1{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 50px;
  height: 30px;
}