.flexLineHeight {
  line-height: 2;
  display: flex;
  align-items: flex-start;
  color: '#581845';
  font-size: 13px;
}

.aboutCandidateStyle {
  margin-bottom: 8px;
  color: '#333333';
}

.lableWidth {
  min-width: 195px;
  color: '#581845';
  font-size: 13px;
}

.jobPreferenceStyle {
  /* margin-top: 20px;  */
  margin-bottom: 8px;
  color: '#333333';
}

.jobPreferenceStyleproffesional {
  margin-top: 10px;
  margin-bottom: 8px;
  color: '#333333';
}

.jobPreferenceStyle {
  /* margin-top: 10px; */
  margin-bottom: 8px;
  color: '#333333';
}

.skillStyle {
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 13px;
  color: '#581845';
}

.softSkillStyle {
  margin-top: 15px;
  margin-bottom: 8px;
  color: '#333333';
}

.overAll {
  padding: 16px;
  overflow: scroll;
  /* background-color: red; */
}


.myaccountdropdownline {
  width: 13.3px;
  height: 13.3px;
  border: 2px solid #c6b8c2;
  border-radius: 50%;
  position: relative;
  margin-right: 6px;
}

.myaccountdropdownline::after {
  content: "";
  position: absolute;
  top: 120%;
  left: 3.8px;
  height: 10px;
  width: 1px;
  background-color: rgb(238, 232, 236);
  /* Color of the line */
}

.myaccountdropdownlinehr {
  width: 13.3px;
  height: 13.3px;
  border: 2px solid #c6b8c2;
  border-radius: 50%;
  position: relative;
  margin-right: 6px;
}

.myaccountdropdownlinehr::after {
  content: "";
  position: absolute;
  top: 120%;
  left: 3.8px;
  height: 10px;
  width: 1px;
  background-color: rgb(238, 232, 236);
  /* Color of the line */
}

.myaccountdropdownlinehr::before {
  content: "";
  position: absolute;
  bottom: 120%;
  left: 3.8px;
  height: 10px;
  width: 1px;
  background-color: rgb(238, 232, 236);
  /* Color of the line */
}

.borderline {
  border-left: 1px solid rgb(238, 232, 236);
  margin-left: 5.8px;
}

.myaccountdropdowndotinlast {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgb(238, 232, 236);
  position: relative;
  left: 3.25px;
  bottom: 1px;
}