.labelContainer {
  margin-right: 20px;
  /* margin-left: 5px; */
}
.valueText {
  margin-left: 5px;
  font-weight: 400;
}
/* .overAll {
  padding: 8px 16px; 
  position: absolute;
  right: 10px;
} */
.cardStyle {
  margin-right: 20px;
  margin-bottom: 16px;
  margin-left: 1px;
  margin-top: 1px;
}
