.overAll {
  background-color: var(--color-white, #ffffff);
  padding: 25px 25px;
  border-radius: 4px;
}
.gmailText {
  margin-top: 10px;
  margin-bottom: 10px;
}
.inputStyle {
  margin-top: 16px;
  /* margin-bottom: 16px; */
}
.timeStyle {
  margin-top: 5px;
}
.otpResend {
  margin-top: 10px;
  margin-bottom: 5px;
}
.emailverifyBtnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  width: 60px;
  height: 30px;
  margin-top: 16px;
}