.container {
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.18);
  z-index: 99;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  display: grid;
}
