.overAll {
  /* background-color: var(--color-gary-8, #fafafa); */
  padding: 0 16px;
  padding-bottom: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #F0EBED;
}

.textArea {
  height: 100px;
}

.switch {
  margin-left: 40px;
  margin-top: 27px;
}

.selectTag {
  width: 300px;
  position: relative;
}

.questionStyle {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
}

.tagContainer {
  margin-top: 16px;
}

.btnContainer {
  align-items: flex-end;
  margin-top: 8px;
}

.addBtn {
  position: absolute;
  z-index: 11;
  top: 39px;
  right: 0;
  margin-right: 1px;
}

.marginRight {
  padding-right: 16px;
}

.errorMessage {
  /* position: absolute; */
  color: var(--color-error, #f94949);
  /* bottom: -19px; */
  font-size: 12px;
}

.containerOne {
  position: relative;
  padding-top: 8px;
}

.pointerNone {
  pointer-events: none !important;
}

.pointer {
  cursor: pointer;
}

.tab-pane {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto !important;
  flex: 1;
}

.tab-content {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  flex: 1;
}

.updateBtnLoader{
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 12px;
  height: 30px;
}