.createnewlink {
  background-color: #fff;
  padding: 25px 5px;
  overflow: hidden;
  z-index: 2;
  /* width: 680px;
  height: 680px; */
  border-radius: 4px;
}
.close {
  border: none;
  background: none;
  margin-left: 520px;
}
.cancel {
  margin-right: 8px;
  background-color: #888888;
  border: none;
  border-radius: 5px;
}
.cancel:active,
.cancel:focus {
  background-color: #888888;
  border: none;
}
/* .inputtextbox{
  width: 250px;
} */
.location {
  width: 770px;
}
.row {
  margin-bottom: 10px;
  /* width: 80%; */
}
.title {
  /* padding: 0px 25px; */
  margin-bottom: 15px;
  border-bottom: 0.5px solid #581458;
}
.inputheight {
  height: 30px !important;
  /* margin-bottom: 5px; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.with80 {
  width: 90%;
}
.with100 {
  width: 100%;
}
.datePicker:focus,
.datePicker {
  font-size: 13px;
  width: 240px;
  height: 28px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  /* border: 1px solid black; */
  /* cursor: alias; */
}
.dateInput:focus-within,
.dateInput:focus{
   border: 1.2px solid #584818;
  }

.customInput::placeholder {
  font-size: 12px; /* Adjust the font size as desired */
  color: #999; /* Adjust the color as desired */
  /* Add any other custom styles for the placeholder */

}

 
.dateInput {
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50%;
  align-items: center;
  display: flex;
  flex-direction: row;
  
}
.daterangepicker th.month {
cursor: default;
}

.my-datepicker {
  width: 300px; /* Adjust the width as per your requirement */
  height: 400px; /* Adjust the height as per your requirement */
  font-size: 16px; /* Adjust the font size as per your requirement */
}

.borderLine {
  border-top: 1px solid #c3c3c3;
  padding-bottom: 20px;
  /* margin-bottom: 20px; */
}

/* .button1{
  margin-left:420px;
} */
.line {
  margin: 10px 0px;
  border-top: 1px solid #c3c3c3;
}
.line1 {
  border-top: 1px solid #c3c3c3;
}

.daytimesplit {
  overflow: none;
}
.interviewertxt{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* max-width: 140px; */
}
.overflowbtn{
  max-width: 440px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.pointer{
  cursor: pointer;
}
.interviewerText{
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 2px 2px 0px 0px;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 85px; */
  cursor: default;
}
.interviewerText:hover{
  background-color: #58184840;
}
.makeellipces{
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #581458;
  font-size: 13px;
}