.overall {
  z-index: 9999;
}

.toggle {
  /* position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 99999; */
  position: fixed;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(-90deg); /* Rotating -90 degrees */
  width: 100px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
}
.chat_button {
  padding-left: 6px;
  padding-right: 6px;
  width: max-content;
  background-color: var(--color-primary, #581845);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  border-radius: 5px;
  color: white;
  border: 2px solid var(--color-primary, #581845);
  font-weight: bold;
  font-size: 13px;
}

.notification_badge {
  background-color: #ed4857;
  border-radius: 50%;
  padding: 2px 5px;
  color: #ffffff;
  font-size: 10px;
  margin-left: 2px;
}

.chat_bot {
  position: fixed;
  bottom: 90px;

  /* Adjust this value as needed for spacing from the bottom */
  right: 24px;
  width: 370px;
  border-radius: 15px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 5px 5px 0px #666666;
  min-height: 565px;
}

.heading {
  background-color: #581845;
  padding: 15px 20px;
  color: white;
  text-align: left;
}

.chat_bot header h2 {
  color: #ffff;
  font-size: 1rem;
}

.chat_bot .chat_box {
  overflow-y: auto;
  padding: 10px 10px 0px;
}

.fromvalue {
  padding: 12px 16px;
  max-width: 75%;
  background-color: #d9d9d9;
  border-radius: 5px;
  word-wrap: break-word;
  font-size: 12px;
  /* border-radius: 10px 10px 0px 10px; */
}

.tovalue {
  background-color: #EEE8EC;
  border-radius: 5px;
  padding: 12px 16px;
  word-wrap: break-word;
  font-size: 12px;
}

.feedback_tovalue {
  background-color: #EEE8EC;
  max-width: 75%;
  border-radius: 10px 10px 10px 10px;
  /* border-radius: 10px 10px 10px 0px; */
  padding: 12px 16px;
  word-wrap: break-word;
  font-size: 12px;
}

.out {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.feedback_out {
  display: flex;
  justify-content: flex-end;
}

.img_span {
  height: 37px;
  width: 37px;
  color: white;
  align-self: flex-end;
  background-color: #EEE8EC;
  /*note incone re-size */
  text-align: center;
  line-height: 32px;
  border-radius: 100%;
  /* margin: 0px 2px 5px 0px; */
  /* margin: 0px 1px 13px 0px; */
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 5px 20px;
  border-top: 1px solid #c3c3c3;
}

.err_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  /* padding: 1px 20px 20px 20px; */
  padding: 5px 20px;
  border-top: 1px solid #c3c3c3;
  height: 80px;
}

.error {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  /* padding: 5px 20px; */
}

.income {
  display: flex;
  margin-bottom: 10px;
}

.feedback_income {
  display: flex;
}

.footer textarea {
  border: none;
  outline: none;
  resize: none;
  padding: 0px 10px 0px 0px;
}

.footer span {
  align-self: flex-end;
  height: 55px;
  line-height: 55px;
  cursor: pointer;
  position: absolute;
  margin-left: 5px;
}

.load {
  position: absolute;
  bottom: 75px;
  margin-left: 28px;
}

.dot_typing {
  position: relative;
  left: -9999px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #888888;
  color: #888888;
  box-shadow: 9984px 0 0 0 #888888, 9999px 0 0 0 #888888, 10014px 0 0 0 #888888;
  animation: dot_typing 1.5s infinite linear;
}

@keyframes dot_typing {
  0% {
    box-shadow: 9984px 0 0 0 #888888, 9999px 0 0 0 #888888,
      10014px 0 0 0 #888888;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #888888, 9999px 0 0 0 #888888,
      10014px 0 0 0 #888888;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #888888, 9999px 0 0 0 #888888,
      10014px 0 0 0 #888888;
  }

  50% {
    box-shadow: 9984px 0 0 0 #888888, 9999px -10px 0 0 #888888,
      10014px 0 0 0 #888888;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #888888, 9999px 0 0 0 #888888,
      10014px 0 0 0 #888888;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #888888, 9999px 0 0 0 #888888,
      10014px -10px 0 0 #888888;
  }

  100% {
    box-shadow: 9984px 0 0 0 #888888, 9999px 0 0 0 #888888,
      10014px 0 0 0 #888888;
  }
}

.svgopen {
  background-color: #581845;
  border-radius: 100%;
}

.loader {
  background-color: #EEE8EC;
  max-width: 75%;
  border-radius: 5px;
}

.suggestion_Text {
  border: 1px solid #A5889C;
  width: -moz-fit-content;
  width: fit-content;
  /* background-color: #d9d9d9; */
  padding: 5px 10px;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.feed_back {
  padding: 10px 0px 0px 0px;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.helps {
  justify-content: end;
  padding-right: 10px;
  font-size: 13px;
  padding-top: 5px;
}

.helps_thumbs {
  justify-content: end;
  padding-right: 2px;
  font-size: 13px;
  padding-top: 5px;
}
.badge {
  position: absolute;
  left: 25px;
  bottom: 30px;
  background-color: #ed4857;
  color: white;
  padding: 0px 0px 0px 6px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}
.customtextarea {
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 12px;
  border: none !important;
}
.customtextarea:focus {
  outline: none !important;
  border: none !important;
}
.customtextarea1 {
  margin-bottom: 0px;
  margin-top: 10px;
  font-size: 12px;
  /* margin-bottom: 2px; */
  /* border: none !important; */
}

/* .customtextarea1::placeholder {
  padding-top: 10px !important;
} */

.chat {
  font-size: 12px !important;
}
