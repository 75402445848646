.addtemplatePopup {
  width: 800px;
  background: white;
  padding: 25px;
  border-radius: 4px;
}

.titletext {
  border-bottom: 1px solid;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.templatetitleInput {
  width: 50%;
}

.btnContainer {
  margin-top: 20px;
  justify-content: flex-end;
  padding-top: 20px;
  border-top: 1px solid #d7c7d2;
}
