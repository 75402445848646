.boxBorder {
  border: 1px solid #333333;
  border-style: dashed;
  border-radius: 4px;
  padding: 16px;
  height: 200px;
  overflow: overlay;
  margin-top: 10px;


}
.boxBorderNone {
  border: 1px solid #333333;
  border-style: dashed;
  border-radius: 4px;
  padding: 16px;
  background: #dfdfdf;
  height: 200px;
  overflow: overlay;
  margin-top: 10px;

}
@-moz-document url-prefix('') {
  .boxBorder {
    overflow: scroll;
    scrollbar-color: rgba(26, 26, 26, 0.3) white;
    scrollbar-width: thin !important;
  }
}
.btnContainer {
  /* width: 35%; */
  display: flex;
  justify-content: flex-end;
  /* margin-top: 10px; */
}
.btnContainer1{
  /* width: 35%; */
 
  /* margin-top: 10px; */
}
.btnStyle {
  /* max-width: 130px; */
  margin-left: 20px;
  /* margin-right: 16px; */
}
.loaderStyle {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  /* margin-left: 50px; */
  /* display: flex;
  justify-content: flex-end;
  margin-top: 10px; */
}
.uploadStyle {
  width: 100%;
}
.overAlll {
  margin-top: 16px;
}

.listStyle {
  margin-right: 5px;
  border: 1px solid #333333;
  padding: 3px;
  margin-bottom: 8px;
  border-radius: 3px;
}
.svgClose {
  margin-left: 6px;
  cursor: pointer;
}
.labelStyle {
  cursor: pointer;
  margin: 0;
  margin-left: 3px;
}
.labelStyleNone {

  margin: 0;
  margin-left: 3px;
}
.displayNone {
  display: none;
}
.clearStyle {
  width: 76px;
  cursor: pointer;
}
.dragOver {
  width: 100%;
}
.verifymodel1 {
  background-color: white;
  width: 375px;
  height: 300px;
  padding: 25px;
  border-radius: 5px;
}