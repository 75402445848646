.findBtn {
  margin-top: 16px;
  border: 1px solid #581845;
}
.lastActiveStyle {
  margin-right: 16px;
  /* width: 190px; */
  width: 25%;
}
.filterOverAll {
  /* padding-top: 20px; */
  padding-right: 16px;
  position: relative;
  
}
.distanceStyle {
  /* width: 190px; */
  margin-right: 16px;
  width: 25%;
}
.salesStyle {
  /* width: 190px; */
  margin-right: 16px;
  width: 25%;
}
.cityStyle {
  /* width: 190px; */
  position: relative;
  margin-right: 16px;
  width: 25%;
}

.marginBottom22 {
  margin-bottom: 22px;
}
.rowContainer {
  padding-bottom: 10px;
  /* align-items: flex-end; */
  padding-left: 8px;
  justify-content: space-between;
  padding-right: 16px;
}
.btnContainer {
  width: 100%;
  /* margin-right: 20px; */
  /* align-items: center; */

  margin-top: 4px;

}
 
.react-autosuggest__suggestions-container--open{
  font-size: 10px;
}
.react-autosuggest__suggestion{
  color: red;
  font-size: 10px !important;
}
.crossIcon {
  all: unset;
  cursor: pointer;
  /* padding-top: 37px; */
  bottom: 2px;
  right: 4px;
  position: absolute;
}