.marginTop16 {
  margin-bottom: 10px;
}
.overAll {
  padding: 16px;
}
.tagOne {
  margin-right: 8px;
  flex: 6;
  position: relative;
}
.tagTwo {
  margin-left: 8px;
  flex: 6;
  position: relative;
}
.colorPicker {
  position: absolute;
  z-index: 11;
  right: 0;
  top: 55px;
}

.textArea {
  height: 90px;
}
.svgSquare{
  top: -1px !important;
}
