
.align{
  /* margin-left: 20px; */
  align-items: center;
}
.selectTag{
  width: 120px;
  height: 30px;
}
.to{
  margin: 0px 15px;
}
.add{
  border: none;
  background: none;
  margin-left: 10px;
  cursor: pointer;
}
.align{
  /* margin-left: 20px; */
  align-items: center;
}
.selectTag{
  width: 120px;
  height: 30px;
}
.to{
  margin: 0px 15px;
}
.noadd{
  border: none;
  background: none;
  margin-left: 10px;
  /* cursor; */

}
.space{
  margin-left: 5px;
}

.apply {
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 15px;
}
.apply:active,
.apply:focus {
  border: none;
}
.applybtn{
  background: white !important;
  margin-top: 6px;
  margin-left: 3px;
  align-content: center;
}
.copyalign{
  margin-left: 15px;
  margin-top: 5px;
}
.applybackground{
  background-color: white !important;
  /* color: #581845 ; */
  transition: background-color 180.3s ease
}

.errorMsg{
  font-size: 12px;
  color: red;

}