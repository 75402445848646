.actionBtnContainer{
    width: 40px;
    justify-content: space-between;
}
.actionBtnContainer{
    width: 40px;
    justify-content: space-between;
  }
  .tabborder1 {
    border-right: 0.5px solid #555555;
    display: flex;
    color: #666666;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
  }
  
  .tabborder2 {
    display: flex;
    color: #666666;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
  }
  
  .deletepopup{
    padding: 25px;
    background-color: white;
  }
  
  .delBtnContainer{
    justify-content: flex-end;
    margin-top:16px;
  }
  
  .nodataavailicon{
    min-height: 377px;
    min-width: 300px;
    display: flex;
  }
  
   
  
  .postyet1 {
    margin-bottom: 10px;
    color: #581845;
    text-align: center;
  }
  
  .addtemplateBtn{
    cursor: pointer;
  }
  .pointer { 
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  } 
  
  .linetruncate { 
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* word-break: break-all; */
    margin-bottom: unset !important;
  }